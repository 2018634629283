import "../../../src/custom-skill-profile.css";
import MenteeRequestModal from "../../components/trienpont/components/modals/menteerequest";
import MentorProfileModal from "../../components/trienpont/components/modals/mentorprofile";
import * as actionPlanActions from "../../store/actions/actionPlanAction";
import * as categoryActions from "../../store/actions/categoryAction";
import * as jobActions from "../../store/actions/jobAction";
import * as lorActions from "../../store/actions/lorAction";
import * as personActions from "../../store/actions/personAction";
import * as skillProfileActions from "../../store/actions/skillProfileAction";
import * as adminBadgeRequestActions from "../../store/admin/actions/adminBadgeRequestActions";
import * as adminPersonActions from "../../store/admin/actions/adminPersonActions";
import { dismisAlert, generateAlert } from "../../utils/alertUtils";
import { parameterizedString } from "../../utils/stringUtils";
import { contentTranslator } from "../../utils/translatorUtils";
import { AlertDialog } from "../AlertDialog";
import { ContentWrapperLg } from "../common/ContentWrapperLg";
import ContentTranslator from "../translator/ContentTranslator";
import TableProfile from "./TableProfile";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Select from "react-select";
import { Alert, Button, Col, Container, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledTooltip, Input, Table } from "reactstrap";
import { bindActionCreators } from "redux";

const FileDownload = require("js-file-download");

const headerStyle = {
    color: "#2B2B2B",
    fontSize: "32px",
    fontFamily: "IBMPlexSans-Bold",
};

const subHeaderStyle = {
    fontFamily: "IBMPlexSans-Light",
    fontSize: "11px",
};

const ddHeaderStyle = {
    fontsize: "14px",
    fontFamily: "IBMPlexSans-Bold",
};

const buttonStyle = {
    marginLeft: "3px",
};

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

class SfiaSkills extends React.Component {
    static displayName = SfiaSkills.name;

    constructor(props) {
        super(props);
        this.connection = null;
        this.state = {
            profileUpdating: this.props.profileUpdating,
            profileUpdatingSuccess: this.props.profileUpdatingSuccess,
            profileUpdatingError: this.props.profileUpdatingError,
            udpatedSkills: this.props.udpatedSkills,

            showDetail: false,
            showHidden: false,
            showEdit: false,
            proposedUpdateLowerLevelMode: false,
            loading: false,

            selectedJobMatchType: null,
            loadingGap: false,
            highlightGap: false,
            jobMatchTypes: [],
            jobGaps: [],
            profileTypeOptions: [],
            selectedEndorsementStatus: {
                value: "",
                label: "None",
            },
            endorsementStatusOptions: [
                {
                    value: "",
                    label: "None",
                },
                {
                    value: "For Review",
                    label: "For Review",
                },
                {
                    value: "Reviewed",
                    label: "Reviewed",
                },
                {
                    value: "Approved",
                    label: "Approved",
                },
            ],
            selectedprofileType: null,
            profileVersions: [],
            selectedProfileVersion: null,
            willCreateNewVersion: false,
            createNewVersion: false,

            lorLoading: false,
            lors: [],
            lorProfiles: [],

            skillLoading: false,
            categories: this.props.categories,
            extraFrameworkCapabilities: this.props.extraFrameworkCapabilities,
            extraFrameworkLevels: this.props.extraFrameworkLevels,
            skillProfiles: [],
            skillProfilesEdit: [],
            editCount: 0,

            latestUpdatedLorProfiles: null,
            latestUpdatedSkillProfiles: null,

            alerts: [],

            account: null,
            showLoadingPanel: false,
            showMakeLatestDialog: false,
            showApproveEndorsementDialog: false,
            showMentorProfileDialog: false,
            showMenteeRequestDialog: false,

            showRequestApprovalFirstDialog: false,
            requestApprovalComment: null,
            showRequestApprovalSecondDialog: false,
            requestApprovalUpdating: false,

            showPostponedReasonDialog: false,

            badgeRequest: this.props.managedPeople && this.props.managedPeople.personB && this.props.managedPeople.personB.badgeRequest,
            showEditCertify: false,

            showMaxBadgeExceed: false,
            updateBadgeRequest: false,
            showSubmitBadgeConfirmation: false,
            showApproveBadgeConfirmation: false,
            showProposedAction: false,
            processActionProposal: false,
            showAcceptActionProposalConfirmation: false,
            showRejectActionProposalConfirmation: false,
            acceptRejectActionProposalComment: "",
            showSuggestedUpdate: false,
            processUpdateProfileSuggestion: false,
            showAcceptUpdateProfileSuggestionConfirmation: false,
            showRejectUpdateProfileSuggestionConfirmation: false,
            acceptRejectUpdateProfileSuggestionComment: "",
            showFloatingLegend: false,
        };

        this.initData = this.initData.bind(this);
        this.fetchSkillProfiles = this.fetchSkillProfiles.bind(this);
        this.handleEditProfileSkill = this.handleEditProfileSkill.bind(this);
        this.handleEditProfileSkills = this.handleEditProfileSkills.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);

        this.mySkillProfileWrapper = React.createRef();
        this.parameterizedString = parameterizedString.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        const selectedJobMatchType = {
            label: this.contentTranslator(this.props.contentTranslations, "SfiaAll", "MatchNone", "None"),
            value: "None",
        };

        this.setState({ selectedJobMatchType, categories: this.props.categories });

        if (this.props.badgeCertification) {
            if (this.state.badgeRequest) {
                if (!(this.state.badgeRequest && this.state.badgeRequest.requestCertificationEntries && this.state.badgeRequest.requestCertificationEntries.length > 0)) {
                    this.setState({ showEditCertify: true });
                }
            }
        }

        this.initData();

        const params = new URLSearchParams(this.props.location.search);
        const isOpenMentee = params.get("mentee")?.toLowerCase() === "true" ?? false;
        if (isOpenMentee === true) {
            this.handleOpenMenteeRequest();
            this.props.history.replace({
                search: "",
            });
        }
    }

    componentWillUnmount() {
        if (this.connection) {
            this.connection.stop();
        }

        this.setState = () => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.profileUpdating !== this.props.profileUpdating) {
            this.setState({ profileUpdating: this.props.profileUpdating });
        }

        if (prevProps.profileUpdatingSuccess !== this.props.profileUpdatingSuccess || prevProps.profileUpdatingError !== this.props.profileUpdatingError || prevProps.udpatedSkills !== this.props.udpatedSkills) {
            this.setState({
                profileUpdatingSuccess: this.props.profileUpdatingSuccess,
                profileUpdatingError: this.props.profileUpdatingError,
                udpatedSkills: this.props.udpatedSkills,
            });

            if (this.props.profileUpdatingSuccess && this.props.udpatedSkills) {
                const udpatedSkills = this.props.udpatedSkills;
                const { selectedProfileVersion, profileVersions, lorProfiles, skillProfiles } = this.state;

                const lorProfileResponses = udpatedSkills ? udpatedSkills.lorProfileResponses : [];
                const skillProfileResponses = udpatedSkills ? udpatedSkills.skillProfileResponses : [];
                const profileType = localStorage.getItem("profileType");

                let version = null;
                if (lorProfileResponses.length > 0) {
                    version = {
                        version: lorProfileResponses[0].version,
                        sfiaVersion: lorProfileResponses[0].sfiaVersion,
                        createdOn: lorProfileResponses[0].createdOn,
                    };
                } else if (skillProfileResponses.length > 0) {
                    version = {
                        version: skillProfileResponses[0].version,
                        sfiaVersion: skillProfileResponses[0].sfiaVersion,
                        createdOn: skillProfileResponses[0].createdOn,
                    };
                }

                if (version.sfiaVersion != (selectedProfileVersion ? selectedProfileVersion.sfiaVersion : null) || version.version != (selectedProfileVersion ? selectedProfileVersion.version : null)) {
                    this.setState({
                        profileVersions: [
                            {
                                value: `Version ${version.version}`,
                                label: `${profileType == "Self Assessed" || this.props.manageEndorsements || this.props.manageSelfAssessed ? `v${version.version} - ` : ""}${new Intl.DateTimeFormat("en-GB", {
                                    year: "2-digit",
                                    month: "short",
                                    day: "numeric",
                                }).format(new Date(version.createdOn))}${profileType == "Self Assessed" && version.approved ? ` (Approved)` : ""}`,
                                version: version.version,
                                sfiaVersion: version.sfiaVersion,
                                createdOn: version.createdOn,
                            },
                            ...profileVersions,
                        ],
                        selectedProfileVersion: {
                            value: `Version ${version.version}`,
                            label: `${profileType == "Self Assessed" || this.props.manageEndorsements || this.props.manageSelfAssessed ? `v${version.version} - ` : ""}${new Intl.DateTimeFormat("en-GB", {
                                year: "2-digit",
                                month: "short",
                                day: "numeric",
                            }).format(new Date(version.createdOn))}`,
                            version: version.version,
                            sfiaVersion: version.sfiaVersion,
                            createdOn: version.createdOn,
                        },
                        lorProfiles: [...lorProfileResponses],
                        skillProfiles: [...skillProfileResponses],
                        latestUpdatedLorProfiles: [...lorProfileResponses],
                        latestUpdatedSkillProfiles: [...skillProfileResponses],
                    });
                } else {
                    if (lorProfileResponses) {
                        lorProfiles
                            .filter((it) => it.editedNumericVal)
                            .forEach((lorProfile) => {
                                if (lorProfileResponses.find((lorProfileResponse) => lorProfileResponse.id === lorProfile.id)) {
                                    const lorProfileResponse = lorProfileResponses.find((lorProfileResponse) => lorProfileResponse.id === lorProfile.id);
                                    lorProfile.numericVal = lorProfileResponse.numericVal;
                                    lorProfile.stringVal = lorProfileResponse.stringVal;
                                    lorProfile.labelVal = lorProfileResponse.labelVal;

                                    lorProfile.editedNumericVal = null;
                                    lorProfile.editedStringVal = null;
                                    lorProfile.editedLabelVal = null;
                                    lorProfile.edited = null;
                                }
                            });

                        lorProfileResponses
                            .filter(
                                (it) =>
                                    !lorProfiles
                                        .map((lorProfile) => {
                                            return lorProfile.id;
                                        })
                                        .includes(it.id)
                            )
                            .forEach((lorProfile) => {
                                lorProfiles.push(lorProfile);
                            });

                        this.setState({ lorProfiles: [...lorProfiles], latestUpdatedLorProfiles: [...lorProfiles] });
                    }

                    if (skillProfileResponses) {
                        skillProfiles
                            .filter((it) => it.editedNumericVal)
                            .forEach((skillProfile) => {
                                if (skillProfileResponses.find((skillProfileResponse) => skillProfileResponse.id === skillProfile.id || (skillProfileResponse.skillCode === skillProfile.skillCode && skillProfileResponse.level === skillProfile.level))) {
                                    const skillProfileResponse = skillProfileResponses.find((skillProfileResponse) => skillProfileResponse.id === skillProfile.id || (skillProfileResponse.skillCode === skillProfile.skillCode && skillProfileResponse.level === skillProfile.level));

                                    skillProfile.id = skillProfileResponse.id;
                                    skillProfile.numericVal = skillProfileResponse.numericVal;
                                    skillProfile.stringVal = skillProfileResponse.stringVal;
                                    skillProfile.labelVal = skillProfileResponse.labelVal;

                                    skillProfile.editedNumericVal = null;
                                    skillProfile.editedStringVal = null;
                                    skillProfile.editedLabelVal = null;
                                    skillProfile.edited = null;
                                }
                            });

                        skillProfileResponses
                            .filter(
                                (it) =>
                                    !skillProfiles
                                        .map((skillProfile) => {
                                            return skillProfile.id;
                                        })
                                        .includes(it.id)
                            )
                            .forEach((skillProfile) => {
                                skillProfiles.push(skillProfile);
                            });

                        this.setState({ skillProfiles: [...skillProfiles], latestUpdatedSkillProfiles: [...skillProfiles] });
                    }
                }

                this.setState({
                    selectedEndorsementStatus: {
                        value: "",
                        label: "None",
                    },
                });

                this.props.onPerson.clearManagePeopleEndorsedStatus();
                if (this.props.managedPeople) {
                    this.props.onAdminPerson.updatePersonEndorsement({
                        personId: this.props.managedPeople.personB.id,
                        latestStatus: "",
                        endorsedById: null,
                        endorsedBy: null,
                        lastUpdatedById: this.props.managedPeople.personA.id,
                        lastUpdatedBy: `${this.props.managedPeople.personA.firstName} ${this.props.managedPeople.personA.lastName}`,
                        lastUpdated: version.createdOn,
                        lastUpdatedVersion: version.version,
                        lastReviewedById: null,
                        lastReviewedBy: null,
                        lastReviewed: null,
                        lastReviewedVersion: null,
                        lastApprovedById: null,
                        lastApprovedBy: null,
                        lastApproved: null,
                        lastApprovedVersion: null,
                        assessorId: this.props.managedPeople.personB.assessorId,
                        assessorName: this.props.managedPeople.personB.assessorName,
                    });
                }
            }

            if (this.props.profileUpdatingError) {
                this.generateAlert("danger", this.props.profileUpdatingError);
            }

            if (this.props.profileUpdatingSuccess || this.props.profileUpdatingError) {
                setTimeout(() => {
                    this.props.onSkillProfile.clearUpdateMySkillProfile();
                }, alertAutoHideInterval);
            }
        }

        if (prevProps.jobMatchesUpdated !== this.props.jobMatchesUpdated && this.props.jobMatchesUpdated) {
            this.handleChangeSkill({
                label: this.contentTranslator(this.props.contentTranslations, "SfiaAll", "MatchNone", "None"),
                value: "None",
            });
            this.initData();
        }

        if (prevProps.forceOpenEditProfile !== this.props.forceOpenEditProfile) {
            if (this.props.forceOpenEditProfile) {
                this.setState({ showEdit: true, willCreateNewVersion: true });
                this.scroll(this.mySkillProfileWrapper);
            }
        }

        if (prevProps.account !== this.props.account && this.props.account) {
            this.setState({ account: this.props.account });
        }

        if (prevProps.managedPeople !== this.props.managedPeople && this.props.managedPeople) {
            const badgeRequest = this.props.managedPeople && this.props.managedPeople.personB && this.props.managedPeople.personB.badgeRequest;
            this.setState({ badgeRequest });

            if (this.props.badgeCertification) {
                if (badgeRequest) {
                    if (!(badgeRequest && badgeRequest.requestCertificationEntries && badgeRequest.requestCertificationEntries.length > 0)) {
                        this.setState({ showEditCertify: true });
                    }
                }
            }
        }
    }

    initData() {
        const { profileUpdating, endorsementStatusOptions } = this.state;

        if (!profileUpdating) {
            this.setState({ loading: true });

            if (this.props.managedPeople) {
                const endorsementStatus = endorsementStatusOptions.find((it) => it.value == this.props.managedPeople.endorsementStatus);
                this.setState({ selectedEndorsementStatus: endorsementStatus });
            }

            Promise.all([
                this.props.managedPeople ? this.props.onSkillProfile.getManagedPeopleProfileTypes(this.props.managedPeople.personB.email) : this.props.onSkillProfile.getMyProfileTypes(),
                this.props.managedPeople ? this.props.onPerson.getManagedPeopleLatestAccount(this.props.managedPeople.personB.email) : this.props.onPerson.getPersonLatestAccount(),
            ])
                .then(() => {
                    if (!(this.props.skillProfileLoading && this.props.personLoading)) {
                        if (this.props.skillProfileError || this.props.personError) {
                            this.setState({ loading: false });
                        } else {
                            this.setState({ account: this.props.account });
                            let profileType = null;

                            let profileTypes = this.props.profileTypes;

                            if ((this.props.isEndorser || this.props.manageEndorsements) && this.props.managedPeople) {
                                if (!profileTypes.includes("Endorsed")) {
                                    profileTypes.push("Endorsed");
                                }
                            }

                            if (this.props.allowSkipSurvey == true) {
                                if (!profileTypes.includes("Self Assessed")) {
                                    profileTypes.push("Self Assessed");
                                }
                            }

                            if (profileTypes.length > 0) {
                                const lastProfileType = localStorage.getItem("profileType");

                                if (lastProfileType) {
                                    if (profileTypes.includes(lastProfileType)) {
                                        profileType = lastProfileType;
                                    } else {
                                        profileType = profileTypes[0];
                                    }
                                } else {
                                    profileType = profileTypes[0];
                                }

                                if (profileType) {
                                    localStorage.setItem("profileType", profileType);
                                }

                                const profileTypeOptions = [
                                    ...profileTypes.map((it) => {
                                        return {
                                            value: it,
                                            label: this.contentTranslator(this.props.contentTranslations, "SfiaAll", it.replace(" ", ""), it),
                                        };
                                    }),
                                ];

                                this.setState({
                                    profileTypeOptions: [...profileTypeOptions],
                                    selectedprofileType: {
                                        value: profileType,
                                        label: this.contentTranslator(this.props.contentTranslations, "SfiaAll", profileType.replace(" ", ""), profileType),
                                    },
                                });
                            }

                            Promise.resolve(this.props.managedPeople ? this.props.onSkillProfile.getManagedPeopleProfileVersions(this.props.managedPeople.personB.email, profileType) : this.props.onSkillProfile.getMyProfileVersions(profileType)).then(() => {
                                if (!this.props.skillProfileLoading) {
                                    if (this.props.skillProfileError) {
                                        this.setState({ loading: false });
                                    } else {
                                        let selectedProfileVersion = null;
                                        if (this.props.profileVersions.length > 0) {
                                            selectedProfileVersion = {
                                                version: this.props.profileVersions[0].version,
                                                sfiaVersion: this.props.profileVersions[0].sfiaVersion,
                                            };

                                            this.setState({
                                                profileVersions: this.props.profileVersions.map((it) => {
                                                    return {
                                                        value: `Version ${it.version}`,
                                                        label: `${profileType == "Self Assessed" || this.props.manageEndorsements || this.props.manageSelfAssessed ? `v${it.version} - ` : ""}${new Intl.DateTimeFormat("en-GB", {
                                                            year: "2-digit",
                                                            month: "short",
                                                            day: "numeric",
                                                        }).format(new Date(it.createdOn))}${profileType == "Self Assessed" && it.approved ? ` (Approved)` : ""}`,
                                                        version: it.version,
                                                        sfiaVersion: it.sfiaVersion,
                                                        createdOn: it.createdOn,
                                                    };
                                                }),

                                                selectedProfileVersion: {
                                                    value: `Version ${this.props.profileVersions[0].version}`,
                                                    label: `${profileType == "Self Assessed" || this.props.manageEndorsements || this.props.manageSelfAssessed ? `v${this.props.profileVersions[0].version} - ` : ""}${new Intl.DateTimeFormat("en-GB", {
                                                        year: "2-digit",
                                                        month: "short",
                                                        day: "numeric",
                                                    }).format(new Date(this.props.profileVersions[0].createdOn))}`,
                                                    version: this.props.profileVersions[0].version,
                                                    sfiaVersion: this.props.profileVersions[0].sfiaVersion,
                                                    createdOn: this.props.profileVersions[0].createdOn,
                                                },
                                            });

                                            this.props.selectVersion(this.props.profileVersions[0].version);
                                        }

                                        const jobMatchTypes = [
                                            {
                                                label: this.contentTranslator(this.props.contentTranslations, "SfiaAll", "MatchNone", "None"),
                                                value: "None",
                                            },
                                        ];

                                        Promise.resolve(this.props.managedPeople ? this.props.onJob.getManagedPeopleJobMatches(this.props.managedPeople.personB.email) : this.props.onJob.getMyJobMatches()).then(() => {
                                            if (!this.props.jobLoading) {
                                                if (this.props.jobError) {
                                                    this.setState({ loading: false });
                                                } else {
                                                    const jobMatches = this.props.jobMatches;
                                                    const currentJob = jobMatches.find((it) => it.jobType === "Current");
                                                    const futureJob = jobMatches.find((it) => it.jobType === "Future");

                                                    if (currentJob) {
                                                        jobMatchTypes.push({
                                                            value: "Current",
                                                            label: `${this.contentTranslator(this.props.contentTranslations, "SfiaAll", "Current", "Current")} - ${currentJob.jobTitle}`,
                                                            id: currentJob.jobId,
                                                        });
                                                    }

                                                    if (futureJob) {
                                                        jobMatchTypes.push({
                                                            value: "Future",
                                                            label: `${this.contentTranslator(this.props.contentTranslations, "SfiaAll", "Future", "Future")} - ${futureJob.jobTitle}`,
                                                            id: futureJob.jobId,
                                                        });
                                                    }

                                                    this.setState({
                                                        loading: false,
                                                        jobMatchTypes,
                                                    });
                                                    this.fetchSkillProfiles(profileType, selectedProfileVersion);
                                                }
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    }
                })
                .catch((err) => {
                    console.log("catch error", err);
                    this.setState({
                        loading: false,
                    });
                });
        }
    }

    fetchSkillProfiles(profileType, selectedProfileVersion) {
        this.setState({ lorLoading: true, skillLoading: true });

        Promise.all([
            this.props.onLor.getLors(),

            this.props.managedPeople
                ? this.props.onSkillProfile.getManagedPeopleLorProfile(this.props.managedPeople.personB.email, selectedProfileVersion && selectedProfileVersion.sfiaVersion, selectedProfileVersion && selectedProfileVersion.version, profileType)
                : this.props.onSkillProfile.getMyLorProfile(selectedProfileVersion && selectedProfileVersion.sfiaVersion, selectedProfileVersion && selectedProfileVersion.version, profileType),

            this.props.managedPeople
                ? this.props.onSkillProfile.getManagedPeopleSkillProfile(this.props.managedPeople.personB.email, selectedProfileVersion && selectedProfileVersion.sfiaVersion, selectedProfileVersion && selectedProfileVersion.version, profileType)
                : this.props.onSkillProfile.getMySkillProfile(selectedProfileVersion && selectedProfileVersion.sfiaVersion, selectedProfileVersion && selectedProfileVersion.version, profileType),
        ])
            .then(() => {
                if (!this.props.lorLoading && !this.props.lorError && !this.props.lorProfileLoading && !this.props.lorProfileError) {
                    this.setState({
                        lors: this.props.lors,
                        lorProfiles: this.props.lorProfiles,
                    });
                }

                if (!this.props.skillProfileLoading && !this.props.skillProfileError) {
                    const skillProfiles = this.props.skillProfiles;

                    this.setState({
                        skillProfiles,
                    });
                }

                this.setState({
                    lorLoading: this.props.lorLoading || this.props.lorProfileLoading,
                    skillLoading: this.props.categoryLoading || this.props.skillProfileLoading,
                });
            })
            .catch(() => {
                this.setState({
                    lorLoading: false,
                    skillLoading: false,
                });
            });
    }

    handleChangeShowHidden = () => {
        this.setState({
            showHidden: !this.state.showHidden,
        });
    };

    handleChangeShowDetail = () => {
        this.setState({
            showDetail: !this.state.showDetail,
        });
    };

    handleCreateNewVersion = () => {
        this.setState({ willCreateNewVersion: true, createNewVersion: true, lorProfiles: [], skillProfiles: [], showEdit: true });
    };

    handleChangeShowEdit = () => {
        let willCreateNewVersion = false;

        if (!this.state.showEdit) {
            willCreateNewVersion = true;
            this.setState({ showEdit: !this.state.showEdit, willCreateNewVersion, createNewVersion: false, editCount: 0 });
        } else if (this.state.skillProfilesEdit.length > 0 && this.state.editCount > 0) {
            if (window.confirm("You have some updates, are you sure to cancel the updates?")) {
                let { lorProfiles, skillProfiles, createNewVersion } = this.state;

                if (createNewVersion) {
                    lorProfiles = this.props.lorProfiles;
                    skillProfiles = this.props.skillProfiles;
                } else {
                    if (lorProfiles) {
                        lorProfiles.forEach((it) => {
                            it.edited = false;
                            it.editedLabelVal = false;
                            it.editedLevel = null;
                            it.editedNumericVal = null;
                            it.editedStringVal = null;
                        });
                    }

                    if (skillProfiles) {
                        skillProfiles.forEach((it) => {
                            it.edited = false;
                            it.editedLabelVal = null;
                            it.editedNumericVal = null;
                            it.editedStringVal = null;
                        });
                    }
                }

                this.setState({
                    skillProfilesEdit: [],
                    editCount: 0,
                    createNewVersion: false,
                    showEdit: !this.state.showEdit,
                    proposedUpdateLowerLevelMode: false,
                    lorProfiles: lorProfiles.filter((it) => it.id),
                    skillProfiles: skillProfiles.filter((it) => it.id),
                });
            }
        } else {
            this.setState({
                showEdit: !this.state.showEdit,
                proposedUpdateLowerLevelMode: false,
                willCreateNewVersion,
                createNewVersion: false,
                lorProfiles: this.props.lorProfiles
                    ? this.props.lorProfiles && this.props.lorProfiles.length > 0
                        ? this.props.lorProfiles
                        : this.state.latestUpdatedLorProfiles
                        ? this.state.latestUpdatedLorProfiles && this.state.latestUpdatedLorProfiles.length > 0
                            ? this.state.latestUpdatedLorProfiles
                            : this.props.lorProfiles
                        : []
                    : this.state.latestUpdatedLorProfiles
                    ? this.state.latestUpdatedLorProfiles
                    : [],
                skillProfiles: this.props.skillProfiles
                    ? this.props.skillProfiles && this.props.skillProfiles.length > 0
                        ? this.props.skillProfiles
                        : this.state.latestUpdatedSkillProfiles
                        ? this.state.latestUpdatedSkillProfiles && this.state.latestUpdatedSkillProfiles.length > 0
                            ? this.state.latestUpdatedSkillProfiles
                            : this.props.skillProfiles
                        : []
                    : this.state.latestUpdatedSkillProfiles
                    ? this.state.latestUpdatedSkillProfiles
                    : [],
            });
        }
    };

    handleEditProfileSkill(profileEdit) {
        let { skillProfilesEdit, createNewVersion } = this.state;

        let oriProfileEdit = skillProfilesEdit.find(
            (it) => (it.id && it.id === profileEdit.id && it.source == profileEdit.source) || (!it.id && it.source == profileEdit.source && ((it.lorCode && it.lorCode === profileEdit.lorCode) || (it.skillCode && it.skillCode === profileEdit.skillCode && it.level === profileEdit.level)))
        );

        if (oriProfileEdit) {
            oriProfileEdit.numericVal = profileEdit.numericVal;
            oriProfileEdit.editedNumericVal = profileEdit.editedNumericVal;
            oriProfileEdit.editedLabelVal = profileEdit.editedLabelVal;

            const editCount = createNewVersion ? skillProfilesEdit.filter((it) => it.editedNumericVal != 0).length : skillProfilesEdit.filter((it) => it.id || it.editedNumericVal != 0).length;
            this.setState({ skillProfilesEdit: skillProfilesEdit, editCount });
        } else {
            skillProfilesEdit = [...skillProfilesEdit, profileEdit];

            const editCount = createNewVersion ? skillProfilesEdit.filter((it) => it.editedNumericVal != 0).length : skillProfilesEdit.filter((it) => it.id || it.editedNumericVal != 0).length;
            this.setState({ skillProfilesEdit, editCount });
        }
    }

    handleEditProfileSkills(profilesEdit) {
        let { skillProfilesEdit, createNewVersion } = this.state;

        profilesEdit.forEach((profileEdit) => {
            let oriProfileEdit = skillProfilesEdit.find(
                (it) => (it.id && it.id === profileEdit.id && it.source == profileEdit.source) || (!it.id && it.source == profileEdit.source && ((it.lorCode && it.lorCode === profileEdit.lorCode) || (it.skillCode && it.skillCode === profileEdit.skillCode && it.level === profileEdit.level)))
            );

            if (oriProfileEdit) {
                oriProfileEdit.numericVal = profileEdit.numericVal;
                oriProfileEdit.editedNumericVal = profileEdit.editedNumericVal;
                oriProfileEdit.editedLabelVal = profileEdit.editedLabelVal;
            } else {
                skillProfilesEdit = [...skillProfilesEdit, profileEdit];
            }
        });

        const editCount = createNewVersion ? skillProfilesEdit.filter((it) => it.editedNumericVal != 0).length : skillProfilesEdit.filter((it) => it.id || it.editedNumericVal != 0).length;
        this.setState({ skillProfilesEdit: skillProfilesEdit, editCount });
    }

    handlePrintReport = () => {
        const endorsed = this.state.selectedprofileType.value == "Endorsed" ? true : false;
        Promise.resolve(this.props.managedPeople ? this.props.onSkillProfile.printManagedPeopleProfile(this.props.managedPeople.personB.email, endorsed) : this.props.onSkillProfile.printMyProfile(endorsed)).then(() => {
            if (!this.props.loadingPrint && !this.props.skillProfileError) {
                if (this.props.printData) {
                    let firstName = localStorage.getItem("firstName");
                    let lastName = localStorage.getItem("lastName");

                    if (this.props.managedPeople) {
                        firstName = this.props.managedPeople.personB.firstName;
                        lastName = this.props.managedPeople.personB.lastName;
                    }

                    let reportFormat = "PDF";
                    if (this.props.selfAssessedProfileReportFormat) {
                        reportFormat = this.props.selfAssessedProfileReportFormat;
                    }

                    FileDownload(
                        this.props.printData,
                        firstName && lastName ? `${firstName}${lastName}_SkillsTx_${endorsed ? "Endorsed" : "Individual"}_Skills_Profile.${reportFormat == "PDF" ? "pdf" : "docx"}` : `SkillsTx_${endorsed ? "Endorsed" : "Individual"}_Skills_Profile.${reportFormat == "PDF" ? "pdf" : "docx"}`
                    );
                }
            }
        });
    };

    handleExportProfile = () => {
        if (window.confirm(`You are about to export the currently selected version of your skill profile.\nAre you sure?`)) {
            this.setState({ showLoadingPanel: true, loadingPanelHeader: "Export Profile", loadingPanelBody: "Please wait ..." });

            this.props.onPerson.downloadPersonMyProfile().then(() => {
                if (!this.props.loadingExport && !this.props.error) {
                    if (this.props.exportData) {
                        let firstName = localStorage.getItem("firstName");
                        let lastName = localStorage.getItem("lastName");

                        FileDownload(
                            this.props.exportData,
                            firstName && lastName
                                ? `SkillsTX_${firstName} ${lastName}_${this.state.selectedprofileType.value}_v${this.state.selectedProfileVersion.version}_${new Intl.DateTimeFormat("en-GB", {
                                      year: "2-digit",
                                      month: "short",
                                      day: "numeric",
                                  }).format(new Date(this.state.selectedProfileVersion.createdOn))}.json`
                                : "Person Profile.json"
                        );
                    }
                }

                this.setState({ showLoadingPanel: this.props.loadingExport });
            });
        }
    };

    handleEmailReport = () => {
        this.props.onSkillProfile.emailMyProfileReport().then(() => {
            if (!this.props.loadingPrint && !this.props.skillProfileError) {
                this.generateAlert("success", "Your report is being generated and will be emailed to you within a few minutes.");
            }
        });
    };

    handleSaveSkillUpdate = () => {
        let personId = localStorage.getItem("personId");

        if (this.props.managedPeople && (this.props.isEndorser || this.props.manageEndorsements)) {
            personId = this.props.managedPeople.personB.id;
        }

        const { selectedprofileType, selectedProfileVersion, willCreateNewVersion, skillProfilesEdit, lorProfiles, skillProfiles } = this.state;

        if (willCreateNewVersion) {
            lorProfiles.forEach((profile) => {
                if (!skillProfilesEdit.find((it) => it.id == profile.id) || !profile.id) {
                    skillProfilesEdit.push({
                        sfiaVersion: selectedProfileVersion ? selectedProfileVersion.sfiaVersion : null,
                        version: selectedProfileVersion ? selectedProfileVersion.version * 1 + 1 : 1,
                        createdOn: new Date(),
                        updatedOn: new Date(),
                        personId: profile.personId,
                        roleId: profile.roleId,
                        role: profile.role,
                        lor: profile.lor,
                        lorCode: profile.lorCode,
                        level: profile.level * 1,
                        stringVal: profile.stringVal,
                        numericVal: profile.numericVal,
                        labelVal: profile.labelVal,
                        profileType: profile.profileType,
                        isEditable: profile.isEditable,
                        source: "lorProfiles",
                    });
                }
            });

            skillProfiles.forEach((profile) => {
                if (!skillProfilesEdit.find((it) => it.id == profile.id)) {
                    skillProfilesEdit.push({
                        sfiaVersion: selectedProfileVersion ? selectedProfileVersion.sfiaVersion : null,
                        version: selectedProfileVersion ? selectedProfileVersion.version * 1 + 1 : 1,
                        createdOn: new Date(),
                        updatedOn: new Date(),
                        personId: profile.personId,
                        roleId: profile.roleId,
                        role: profile.role,
                        category: profile.category,
                        subCategory: profile.subCategory,
                        skill: profile.skill,
                        skillCode: profile.skillCode,
                        level: profile.level,
                        stringVal: profile.stringVal,
                        numericVal: profile.numericVal,
                        labelVal: profile.labelVal,
                        profileType: profile.profileType,
                        isEditable: profile.isEditable,
                        source: "skillProfiles",
                        isExtraFramework: profile.isExtraFramework,
                        extraFrameworkLevel: profile.extraFrameworkLevel,
                        extraFrameworkLevelId: profile.extraFrameworkLevelId,
                    });
                }
            });

            skillProfilesEdit.forEach((skillProfile) => {
                skillProfile.id = null;
                skillProfile.personId = personId;
                skillProfile.sfiaVersion = selectedProfileVersion ? selectedProfileVersion.sfiaVersion : null;
                skillProfile.version = selectedProfileVersion ? selectedProfileVersion.version * 1 + 1 : 1;
                skillProfile.profileType = selectedprofileType.value;
                skillProfile.createdOn = new Date();
                skillProfile.updatedOn = new Date();
                skillProfile.isEditable = true;

                if (skillProfile.editedStringVal) {
                    skillProfile.stringVal = skillProfile.editedStringVal;
                    skillProfile.numericVal = skillProfile.editedNumericVal;
                    skillProfile.labelVal = skillProfile.editedLabelVal;
                }
            });
        } else {
            skillProfilesEdit.forEach((skillProfile) => {
                if (!skillProfile.id) {
                    skillProfile.personId = personId;
                    skillProfile.sfiaVersion = selectedProfileVersion.sfiaVersion;
                    skillProfile.version = selectedProfileVersion.version;
                    skillProfile.profileType = selectedprofileType.value;
                    skillProfile.createdOn = selectedProfileVersion.createdOn;
                    skillProfile.updatedOn = new Date();
                    skillProfile.isEditable = true;
                } else {
                    skillProfile.updatedOn = new Date();
                }
            });
        }

        (this.props.managedPeople || this.props.isEndorser) && this.props.manageEndorsements
            ? this.props.onSkillProfile.updateManagedPeopleProfile(
                  this.props.managedPeople.personB.email,
                  skillProfilesEdit.filter((it) => it.id || it.editedNumericVal != 0)
              )
            : this.props.onSkillProfile.updateMySkillProfile(skillProfilesEdit.filter((it) => it.id || it.editedNumericVal != 0));

        if (lorProfiles) {
            lorProfiles.forEach((it) => {
                it.edited = false;
            });
        }

        if (skillProfiles) {
            skillProfiles.forEach((it) => {
                it.edited = false;
            });
        }

        this.setState({
            skillProfilesEdit: [],
            showEdit: !this.state.showEdit,
            lorProfiles,
            skillProfiles,
        });
    };

    handleChangeProfile = (selectedprofileType) => {
        this.setState({ selectedprofileType });

        const profileType = selectedprofileType.value;

        localStorage.setItem("profileType", profileType);

        Promise.resolve(this.props.managedPeople ? this.props.onSkillProfile.getManagedPeopleProfileVersions(this.props.managedPeople.personB.email, profileType) : this.props.onSkillProfile.getMyProfileVersions(profileType)).then(() => {
            if (this.props.error) {
                this.setState({ loading: false });
            } else {
                let selectedProfileVersion = null;
                if (this.props.profileVersions.length > 0) {
                    selectedProfileVersion = {
                        version: this.props.profileVersions[0].version,
                        sfiaVersion: this.props.profileVersions[0].sfiaVersion,
                    };

                    this.props.selectVersion(this.props.profileVersions[0].version);

                    this.setState(
                        {
                            profileVersions: this.props.profileVersions.map((it) => {
                                return {
                                    value: `Version ${it.version}`,
                                    label: `${profileType == "Self Assessed" || this.props.manageEndorsements || this.props.manageSelfAssessed ? `v${it.version} - ` : ""}${new Intl.DateTimeFormat("en-GB", {
                                        year: "2-digit",
                                        month: "short",
                                        day: "numeric",
                                    }).format(new Date(it.createdOn))}`,
                                    version: it.version,
                                    sfiaVersion: it.sfiaVersion,
                                    createdOn: it.createdOn,
                                };
                            }),
                            selectedProfileVersion: {
                                value: `Version ${this.props.profileVersions[0].version}`,
                                label: `${profileType == "Self Assessed" || this.props.manageEndorsements || this.props.manageSelfAssessed ? `v${this.props.profileVersions[0].version} - ` : ""}${new Intl.DateTimeFormat("en-GB", {
                                    year: "2-digit",
                                    month: "short",
                                    day: "numeric",
                                }).format(new Date(this.props.profileVersions[0].createdOn))}`,
                                version: this.props.profileVersions[0].version,
                                sfiaVersion: this.props.profileVersions[0].sfiaVersion,
                                createdOn: this.props.profileVersions[0].createdOn,
                            },
                        },
                        () => {
                            this.fetchSkillProfiles(profileType, selectedProfileVersion);
                        }
                    );
                } else {
                    this.props.selectVersion(null);

                    this.setState(
                        {
                            profileVersions: [],
                            selectedProfileVersion: null,
                        },
                        () => {
                            this.fetchSkillProfiles(profileType, null);
                        }
                    );
                }

                this.setState({ loading: false });
            }
        });
    };

    handleChangeSkill = (selectedJobMatchType) => {
        if (selectedJobMatchType && selectedJobMatchType.value != "None") {
            this.setState({ loadingGap: true, highlightGap: selectedJobMatchType.value != "None" });

            Promise.all([this.props.onJob.getJobLorProfiles(selectedJobMatchType.id), this.props.onJob.getJobSkillProfiles(selectedJobMatchType.id), this.props.onJob.getJobSkillAttributes(selectedJobMatchType.id)])
                .then(() => {
                    if (!this.props.jobLoading && !this.props.jobError) {
                        this.setState({
                            jobLorProfiles: this.props.jobLorProfiles,
                            jobSkillProfiles: this.props.jobSkillProfiles.filter((it) => it.stringVal != "N"),
                            jobSkillAttributes: this.props.jobSkillAttributes,
                        });
                    }

                    this.setState({
                        loadingGap: this.props.jobLoading,
                        selectedJobMatchType,
                        highlightGap: selectedJobMatchType.value != "None",
                    });
                })
                .catch(() => {
                    this.setState({
                        loadingGap: false,
                    });
                });
        } else {
            this.setState({
                selectedJobMatchType,
                highlightGap: selectedJobMatchType.value != "None",
                jobLorProfiles: [],
                jobSkillProfiles: [],
            });
        }
    };

    handleChangeVersion = (selectedProfileVersion) => {
        this.setState({ selectedProfileVersion });

        this.props.selectVersion(selectedProfileVersion.version);

        this.fetchSkillProfiles(this.state.selectedprofileType.value, {
            version: selectedProfileVersion.version,
            sfiaVersion: selectedProfileVersion.sfiaVersion,
        });
    };

    handleCopyEndorsedProfile = () => {
        if (window.confirm(`Are you sure?`)) {
            this.setState({ showLoadingPanel: true, loadingPanelHeader: "Copy Endorsed Profile", loadingPanelBody: "Please wait ..." });

            this.props.onSkillProfile.copyEndorsedProfile().then(() => {
                if (!this.props.endorsedProfileCopying) {
                    if (this.props.endorsedProfileCopyingError) {
                        this.generateAlert("danger", this.props.endorsedProfileCopyingError.errData.Message);
                    } else if (this.props.endorsedProfileCopyingSuccess) {
                        this.generateAlert("success", "Endorsed profile has been successfully copied");
                        this.initData();
                    }

                    this.setState({ showLoadingPanel: false });
                }
            });
        }
    };

    handleRequestAccountApproval = () => {
        this.setState({ showRequestApprovalFirstDialog: true });
    };

    handleRequestApprovalCommentChange = (event) => {
        const { value } = event.target;

        this.setState({
            requestApprovalComment: value,
        });
    };

    togleRequestApprovalFirstModal = () => {
        const showRequestApprovalFirstDialog = this.state.showRequestApprovalFirstDialog;
        this.setState({ showRequestApprovalFirstDialog: !showRequestApprovalFirstDialog });
    };

    renderRequestApprovalFirstConfirmation = () => {
        return (
            <Modal isOpen={this.state.showRequestApprovalFirstDialog} toggle={this.togleRequestApprovalFirstModal}>
                <ModalHeader toggle={this.togleRequestApprovalFirstModal}>
                    <ContentTranslator page="SfiaProfile" name="SfiaProfileRequestApprovalDialogTitle" contentText="Comments to your Approver" />
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        <ContentTranslator
                                            page="SfiaProfile"
                                            name="SfiaProfileRequestApprovalDialogLabel"
                                            contentText="By making this approval request, a notification will be sent to the Approver with your comments which you must enter below.<br />We recommend the comments include a brief description of what changes were made."
                                        />
                                    </Label>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={12}>
                                        <Input type="textarea" name="requestApprovalComment" placeholder="Please enter comment" value={this.state.requestApprovalComment ? this.state.requestApprovalComment : ""} onChange={this.handleRequestApprovalCommentChange} />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleFirstConfirmRequestApproval();
                        }}
                        disabled={!(this.state.requestApprovalComment && this.state.requestApprovalComment != "")}
                    >
                        <ContentTranslator page="SfiaAll" name="OK" contentText="OK" />
                    </Button>
                    <Button color="secondary" onClick={this.togleRequestApprovalFirstModal}>
                        <ContentTranslator page="SfiaAll" name="Close" contentText="Close" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleFirstConfirmRequestApproval = () => {
        this.setState({ showRequestApprovalFirstDialog: false, showRequestApprovalSecondDialog: true });
    };

    closeRequestApprovalSecondModal = () => {
        this.setState({ showRequestApprovalFirstDialog: true, showRequestApprovalSecondDialog: false });
    };

    renderRequestApprovalSecondConfirmation = () => {
        return (
            <Modal isOpen={this.state.showRequestApprovalSecondDialog} toggle={!this.state.requestApprovalUpdating && this.closeRequestApprovalSecondModal}>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        <ContentTranslator page="SfiaProfile" name="SfiaProfileConfirmRequestApprovalDialogLabel" contentText="By confirming you will send a notification to the Approver with your comments.<br />Are you sure?" />
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmRequestApproval();
                        }}
                        disabled={this.state.requestApprovalUpdating}
                    >
                        <ContentTranslator page="SfiaAll" name="Confirm" contentText="Confirm" />
                    </Button>
                    <Button color="secondary" onClick={this.closeRequestApprovalSecondModal} disabled={this.state.requestApprovalUpdating}>
                        <ContentTranslator page="SfiaAll" name="Close" contentText="Close" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleConfirmRequestApproval = () => {
        this.setState({ requestApprovalUpdating: true });

        const account = this.state.account;

        this.props.onPerson.personAccountRequestApproval(account.id, this.state.requestApprovalComment).then(() => {
            if (!this.props.personUpdating) {
                if (this.props.personError) {
                    this.generateAlert("danger", this.props.personError.errData.Message);
                } else if (this.props.account) {
                    this.generateAlert("success", "Approval sent");
                    this.setState({ account: this.props.account, showRequestApprovalSecondDialog: false, requestApprovalComment: null });
                }
            }

            this.setState({ requestApprovalUpdating: this.props.personUpdating });
        });
    };

    handleShowPostponedReason = () => {
        this.setState({ showPostponedReasonDialog: true });

        const account = this.state.account;
        if (account && account.isApprovalPostponedShown == false) {
            this.props.onPerson.personAccountShowPostponeComment(account.id);
        }

        account.isApprovalPostponedShown = true;
    };

    togleShowPostponedReason = () => {
        const showPostponedReasonDialog = this.state.showPostponedReasonDialog;
        this.setState({ showPostponedReasonDialog: !showPostponedReasonDialog });
    };

    renderPostponedReasonDialog = () => {
        const { selectedprofileType, account } = this.state;
        const { currAssessment, person } = this.props;
        let showApprovalPostponed = false;

        if (!this.props.managedPeople && selectedprofileType && selectedprofileType.value == "Self Assessed") {
            if (account && currAssessment && currAssessment.enableApprovalRequest && (account.isApprovalRequested || account.isApprovalPostponed)) {
                showApprovalPostponed = account.isApprovalPostponed ? account.isApprovalPostponed : false;
            }
        }

        return (
            <Modal isOpen={this.state.showPostponedReasonDialog && showApprovalPostponed} toggle={this.togleShowPostponedReason}>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        {showApprovalPostponed && (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: `${account.approvalPostponedBy ? account.approvalPostponedBy : "-"} has postponed your approval on ${
                                                        account.approvalPostponedOn
                                                            ? new Intl.DateTimeFormat("en-GB", {
                                                                  year: "2-digit",
                                                                  month: "short",
                                                                  day: "numeric",
                                                              }).format(new Date(account.approvalPostponedOn))
                                                            : ""
                                                    } requesting ${account.approvalPostponedType ? account.approvalPostponedType : ""} with the following comment:<br />${account.approvalPostponedComment ? account.approvalPostponedComment : ""}`,
                                                }}
                                            ></div>
                                        )}
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.togleShowPostponedReason} disabled={this.state.requestApprovalUpdating}>
                        <ContentTranslator page="SfiaAll" name="Close" contentText="Close" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    renderLoadingPanel() {
        if (this.state.showLoadingPanel) {
            return (
                <Modal isOpen={this.state.showLoadingPanel}>
                    <ModalHeader>{this.state.loadingPanelHeader}</ModalHeader>
                    <ModalBody>
                        <Spinner size="md" color="primary" /> {this.state.loadingPanelBody}
                    </ModalBody>
                </Modal>
            );
        } else {
            return "";
        }
    }

    scroll(ref) {
        ref.current.scrollIntoView({ behavior: "smooth" });
    }

    handleShowMakeLatestConfirmation = () => {
        this.setState({ showMakeLatestDialog: true });
    };

    togleMakeLatestProfileModal = () => {
        const showMakeLatestDialog = this.state.showMakeLatestDialog;

        this.setState({ showMakeLatestDialog: !showMakeLatestDialog });
    };

    handleConfirmMakeLatestProfile = () => {
        const { selectedProfileVersion, selectedprofileType } = this.state;
        this.setState({ showMakeLatestDialog: false, showLoadingPanel: true, loadingPanelHeader: "Make Profile Latest", loadingPanelBody: "Please wait ..." });

        this.props.onSkillProfile.copyProfile(selectedProfileVersion.version, selectedprofileType.value).then(() => {
            if (!this.props.copyProfile) {
                if (this.props.copyProfileError) {
                    this.generateAlert("danger", this.props.copyProfileError.errData.Message);
                } else {
                    this.generateAlert("success", "Profile has been successfully made as latest");
                    this.initData();
                }

                this.setState({ showLoadingPanel: false });
            }
        });
    };

    renderMakeLatestProfileConfirmation = () => {
        return (
            <Modal isOpen={this.state.showMakeLatestDialog} toggle={this.togleMakeLatestProfileModal}>
                <ModalHeader toggle={this.showMakeLatestDialog}>Make Latest Profile Confirmation</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>You are about to create a new latest version using the version you are viewing. Are you sure?</Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmMakeLatestProfile();
                        }}
                    >
                        <ContentTranslator page="SfiaAll" name="OK" contentText="OK" />
                    </Button>
                    <Button color="secondary" onClick={this.togleMakeLatestProfileModal}>
                        <ContentTranslator page="SfiaAll" name="Cancel" contentText="Cancel" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleChangeEndorsementStatus = (selectedStatus) => {
        const selectedEndorsementStatus = this.state.selectedEndorsementStatus;

        if (
            selectedStatus.value != selectedEndorsementStatus.value &&
            ((selectedStatus.value != "" && selectedEndorsementStatus.value == "") ||
                (selectedStatus.value == "For Review" && selectedEndorsementStatus.value == "") ||
                (selectedStatus.value == "Reviewed" && selectedEndorsementStatus.value != "Approved") ||
                (selectedStatus.value == "Approved" && selectedEndorsementStatus.value != "Approved"))
        ) {
            if (selectedStatus.value == "Approved") {
                this.setState({ showApproveEndorsementDialog: true });
            } else {
                this.setState({ updateEndorsedStatus: true, showLoadingPanel: true, loadingPanelHeader: "Update Endorsed Status", loadingPanelBody: "Please wait ..." });

                this.props.onSkillProfile.updateEndorsedStatus(this.props.managedPeople.personB.email, selectedStatus.value).then(() => {
                    if (!this.props.updateEndorsedStatus) {
                        if (this.props.updateEndorsedStatusError) {
                            this.generateAlert("danger", this.props.updateEndorsedStatusError.errData.Message);
                        } else {
                            this.props.onAdminPerson.updatePersonEndorsement(this.props.updatedPersonEndorsement);
                            this.props.onPerson.updateManagePeopleEndorsedStatus(selectedStatus.value);
                            this.generateAlert("success", "Update Endorsed status successful");
                        }
                    }

                    this.setState({ showLoadingPanel: this.props.updateEndorsedStatus, updateEndorsedStatus: this.props.updateEndorsedStatus });
                    this.setState({ selectedEndorsementStatus: selectedStatus });
                });
            }
        }
    };

    togleApproveEndorsementModal = () => {
        const showApproveEndorsementDialog = this.state.showApproveEndorsementDialog;

        this.setState({ showApproveEndorsementDialog: !showApproveEndorsementDialog });
    };

    handleConfirmApproveEndorsement = () => {
        this.setState({ showApproveEndorsementDialog: false, updateEndorsedStatus: true, showLoadingPanel: true, loadingPanelHeader: "Approved Endorsement", loadingPanelBody: "Please wait ..." });

        this.props.onSkillProfile.updateEndorsedStatus(this.props.managedPeople.personB.email, "Approved").then(() => {
            if (!this.props.updateEndorsedStatus) {
                if (this.props.updateEndorsedStatusError) {
                    this.generateAlert("danger", this.props.updateEndorsedStatusError.errData.Message);
                    this.setState({ showApproveEndorsementDialog: true });
                } else {
                    this.props.onAdminPerson.updatePersonEndorsement(this.props.updatedPersonEndorsement);
                    this.props.onPerson.updateManagePeopleEndorsedStatus("Approved");
                    this.props.endorsedProfileApproved();
                    this.generateAlert("success", "Approved Endorsement successful");
                }
            }

            this.setState({ showLoadingPanel: this.props.updateEndorsedStatus, updateEndorsedStatus: this.props.updateEndorsedStatus });
            this.setState({ selectedEndorsementStatus: { value: "Approved", label: "Approved" } });
        });
    };

    handleOpenMenteeRequest = () => {
        this.setState({ showMenteeRequestDialog: new Date() });
    };

    handleOpenMentorProfile = () => {
        this.setState({ showMentorProfileDialog: new Date() });
    };

    handleCloseMentorProfile = (val) => {
        if (!this.props.managedPeople && this.state.selectedprofileType && this.state.selectedprofileType.value == "Self Assessed") {
            if (
                this.state.account &&
                this.props.currAssessment &&
                this.props.currAssessment.enableFindMentor &&
                val && //isMentor
                this.state.account.canBeMentor
            ) {
                this.setState({
                    //showBeMentor: true,
                    account: {
                        ...this.state.account,
                        isMentor: val,
                    },
                });
            }
        }
        // this.setState({
        //     account: { ...this.state.account, isMentor: val },
        //     });
    };

    renderMenteeRequest = () => {
        return <MenteeRequestModal showDialog={this.state.showMenteeRequestDialog} contentTranslations={this.props.contentTranslations} contentTranslator={this.contentTranslator} parameterizedString={this.parameterizedString}></MenteeRequestModal>;
    };

    renderMentorProfile = () => {
        return <MentorProfileModal showDialog={this.state.showMentorProfileDialog} contentTranslations={this.props.contentTranslations} contentTranslator={this.contentTranslator} data={this.state.account} onClose={this.handleCloseMentorProfile}></MentorProfileModal>;
    };

    renderApproveEndorsementConfirmation = () => {
        return (
            <Modal isOpen={this.state.showApproveEndorsementDialog} toggle={!this.state.updateEndorsedStatus && this.togleApproveEndorsementModal}>
                <ModalHeader toggle={!this.state.updateEndorsedStatus && this.togleApproveEndorsementModal}>Approved Endorsement Confirmation</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        You are about to Approve this Endorsed Profile, are you sure?
                                        <br />
                                        When approved the user will be notified and able to view it and it CANNOT be unapproved.
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmApproveEndorsement();
                        }}
                    >
                        OK
                    </Button>
                    <Button color="secondary" onClick={this.togleApproveEndorsementModal} disabled={this.state.updateEndorsedStatus}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleTogleEditBadgeRequest = () => {
        const showEditCertify = this.state.showEditCertify;
        this.setState({ showEditCertify: !showEditCertify });
    };

    badgeRequestUpdated = (badgeRequest) => {
        this.setState({ badgeRequest });

        const maxNumberOfBadgeRequestSkill = this.props.maxNumberOfBadgeRequestSkill;
        let requestCertificationEntries = badgeRequest && badgeRequest.requestCertificationEntries ? [...badgeRequest.requestCertificationEntries] : [];
        let numberBadgeRequested = 0;
        requestCertificationEntries.forEach((requestCertificationEntry) => {
            let stringVal = requestCertificationEntry.storedStringVal ? requestCertificationEntry.storedStringVal : requestCertificationEntry.editedStringVal ? requestCertificationEntry.editedStringVal : requestCertificationEntry.stringVal ? requestCertificationEntry.stringVal : "N";

            if (stringVal != "N") {
                numberBadgeRequested += 1;
            }
        });

        if (numberBadgeRequested >= maxNumberOfBadgeRequestSkill) {
            this.setState({ showMaxBadgeExceed: true });
        }
    };

    togleMaxBadgeExceedConfirmation = () => {
        const showMaxBadgeExceed = this.state.showMaxBadgeExceed;
        this.setState({ showMaxBadgeExceed: !showMaxBadgeExceed });
    };

    renderMaxBadgeExceedConfirmation = () => {
        return (
            <Modal isOpen={this.state.showMaxBadgeExceed} toggle={!this.state.updateBadgeRequest && this.togleMaxBadgeExceedConfirmation}>
                <ModalHeader toggle={!this.state.updateBadgeRequest && this.togleMaxBadgeExceedConfirmation}>Maximum badge request reached</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        You have selected the maximum 6 Skills at Levels. If you wish to select others you must remove one or more.
                                        <br />
                                        Save or Cancel to continue to Edit
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleSaveBadgeRequest();
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleApproveBadgeRequest();
                        }}
                    >
                        Save and Approve
                    </Button>
                    <Button color="secondary" onClick={this.togleMaxBadgeExceedConfirmation} disabled={this.state.updateBadgeRequest}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleSaveBadgeRequest = () => {
        const badgeRequest = this.state.badgeRequest;
        this.setState({ showMaxBadgeExceed: false, updateBadgeRequest: true });
        this.setState({ showLoadingPanel: true, loadingPanelHeader: "Save badge request update", loadingPanelBody: "Please wait ..." });

        badgeRequest.status = "Assessed";

        this.props.onAdminBadgeRequest.updateBadgeRequestCertificationEntries(badgeRequest.id, badgeRequest).then(() => {
            if (!this.props.badgeRequestUpdating) {
                if (this.props.badgeRequestError) {
                    this.setState({ showMaxBadgeExceed: true });
                    this.generateAlert("danger", this.props.badgeRequestError.errData.Message);
                } else {
                    badgeRequest.requestCertificationEntries = this.props.updatedBadgeRequest.requestCertificationEntries ? [...this.props.updatedBadgeRequest.requestCertificationEntries] : [];
                    this.setState({ showEditCertify: false, badgeRequest });
                    this.generateAlert("success", "Badge request certification entries updated");
                }
            }

            this.setState({ updateBadgeRequest: this.props.badgeRequestUpdating, showLoadingPanel: this.props.badgeRequestUpdating });
        });
    };

    handleApproveBadgeRequest = () => {
        this.setState({ showApproveBadgeConfirmation: true });
    };

    togleSubmitBadgesConfirmation = () => {
        const showSubmitBadgeConfirmation = this.state.showSubmitBadgeConfirmation;
        this.setState({ showSubmitBadgeConfirmation: !showSubmitBadgeConfirmation });
    };

    renderSubmitBadgesConfirmation = () => {
        return (
            <Modal isOpen={this.state.showSubmitBadgeConfirmation} toggle={!this.state.updateBadgeRequest && this.togleSubmitBadgesConfirmation}>
                <ModalHeader toggle={!this.state.updateBadgeRequest && this.togleSubmitBadgesConfirmation}>Submit badges confirmation</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        By clicking CONFIRM you are confirming you are a current SFIA Accredited Assessor authorized to issue these badges.
                                        <br />
                                        ARE YOU SURE?
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleSubmitBadgeRequest();
                        }}
                    >
                        Confirm
                    </Button>
                    <Button color="secondary" onClick={this.togleSubmitBadgesConfirmation} disabled={this.state.updateBadgeRequest}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleSubmitBadgeRequest = () => {
        const badgeRequest = this.state.badgeRequest;
        this.setState({ showSubmitBadgeConfirmation: false, updateBadgeRequest: true });
        this.setState({ showLoadingPanel: true, loadingPanelHeader: "Submit for badges", loadingPanelBody: "Please wait ..." });

        this.props.onAdminBadgeRequest.submitBadgeRequestCertification(badgeRequest.id).then(() => {
            if (!this.props.badgeRequestUpdating) {
                if (this.props.badgeRequestError) {
                    this.setState({ showSubmitBadgeConfirmation: true });
                    this.generateAlert("danger", this.props.badgeRequestError.errData.Message);
                } else {
                    badgeRequest.assessedDate = this.props.updatedBadgeRequest.assessedDate;
                    badgeRequest.certificationSubmitted = this.props.updatedBadgeRequest.certificationSubmitted;

                    this.setState({ badgeRequest });
                    this.generateAlert("success", "Certification badges submitted.");
                }
            }

            this.setState({ updateBadgeRequest: this.props.badgeRequestUpdating, showLoadingPanel: this.props.badgeRequestUpdating });
        });
    };

    closeWindow = () => {
        window.close();
    };

    togleApproveBadgesConfirmation = () => {
        const showApproveBadgeConfirmation = this.state.showApproveBadgeConfirmation;
        this.setState({ showApproveBadgeConfirmation: !showApproveBadgeConfirmation });
    };

    renderApproveBadgesConfirmation = () => {
        return (
            <Modal isOpen={this.state.showApproveBadgeConfirmation} toggle={!this.state.updateBadgeRequest && this.togleApproveBadgesConfirmation}>
                <ModalHeader toggle={!this.state.updateBadgeRequest && this.togleApproveBadgesConfirmation}>Approve badges confirmation</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        By clicking APPROVE you are confirming you are a current SFIA Accredited Assessor approve these badges request.
                                        <br />
                                        <br />
                                        ARE YOU SURE?
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmApproveBadgeRequest();
                        }}
                    >
                        Approve
                    </Button>
                    <Button color="secondary" onClick={this.togleApproveBadgesConfirmation} disabled={this.state.updateBadgeRequest}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleConfirmApproveBadgeRequest = () => {
        const badgeRequest = this.state.badgeRequest;
        this.setState({ showMaxBadgeExceed: false, updateBadgeRequest: true, showApproveBadgeConfirmation: false });
        this.setState({ showLoadingPanel: true, loadingPanelHeader: "Approve badge request update", loadingPanelBody: "Please wait ..." });

        badgeRequest.status = "Approved";

        this.props.onAdminBadgeRequest.updateBadgeRequestCertificationEntries(badgeRequest.id, badgeRequest).then(() => {
            if (!this.props.badgeRequestUpdating) {
                if (this.props.badgeRequestError) {
                    this.setState({ showMaxBadgeExceed: true });
                    this.generateAlert("danger", this.props.badgeRequestError.errData.Message);
                } else {
                    badgeRequest.requestCertificationEntries = this.props.updatedBadgeRequest.requestCertificationEntries ? [...this.props.updatedBadgeRequest.requestCertificationEntries] : [];
                    this.setState({ showEditCertify: false, badgeRequest });
                    this.generateAlert("success", "Badge request certification entries approved");
                }
            }

            this.setState({ updateBadgeRequest: this.props.badgeRequestUpdating, showLoadingPanel: this.props.badgeRequestUpdating });
        });
    };

    handleViewProposedAction = () => {
        let { lorProfiles, skillProfiles, selectedJobMatchType, selectedProfileVersion, profileVersions } = this.state;

        if (selectedJobMatchType && selectedJobMatchType.value != "None") {
            this.handleChangeSkill({
                label: this.contentTranslator(this.props.contentTranslations, "SfiaAll", "MatchNone", "None"),
                value: "None",
            });
        }

        let latestVersion = null;

        if (profileVersions && profileVersions.length > 0) {
            latestVersion = profileVersions[0];
        }

        if (latestVersion && selectedProfileVersion && latestVersion.version != selectedProfileVersion.version) {
            this.handleChangeVersion(latestVersion);
        }

        this.setState({ showProposedAction: true, showDetail: false, showEdit: false, skillProfilesEdit: [], editCount: 0, createNewVersion: false, lorProfiles: lorProfiles.filter((it) => it.id), skillProfiles: skillProfiles.filter((it) => it.id) });
    };

    handleCancelViewProposedAction = () => {
        this.setState({ showProposedAction: false });
    };

    handleActionProposalCommentChange = (event) => {
        const { value } = event.target;

        this.setState({
            acceptRejectActionProposalComment: value,
        });
    };

    handleAcceptActionProposal = () => {
        this.setState({ showAcceptActionProposalConfirmation: true, acceptRejectActionProposalComment: "" });
    };

    togleAcceptActionProposal = () => {
        const showAcceptActionProposalConfirmation = this.state.showAcceptActionProposalConfirmation;
        this.setState({ showAcceptActionProposalConfirmation: !showAcceptActionProposalConfirmation });
    };

    renderAcceptActionProposalConfirmation = () => {
        return (
            <Modal isOpen={this.state.showAcceptActionProposalConfirmation} toggle={!this.state.processActionProposal && this.state.showProposedAction && this.togleAcceptActionProposal}>
                <ModalHeader toggle={!this.state.processActionProposal && this.state.showProposedAction && this.togleAcceptActionProposal}>Accept proposal confirmation</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        You are about to <b>accept</b> the proposed changes. This will:
                                        <br />
                                        <ul>
                                            <li>Create a new version of your profile</li>
                                            <li>If it was approved the approval will be revoked</li>
                                            <li>Add evidence to your evidence page to mirror the completed action</li>
                                            <li>Send a notification to the proposer with the comments below</li>
                                        </ul>
                                    </Label>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={12}>
                                        <Input type="textarea" name="acceptRejectActionProposalComment" placeholder="Please enter comment" value={this.state.acceptRejectActionProposalComment ? this.state.acceptRejectActionProposalComment : ""} onChange={this.handleActionProposalCommentChange} />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmAcceptActionProposal();
                        }}
                        disabled={this.state.processActionProposal || !(this.state.acceptRejectActionProposalComment && this.state.acceptRejectActionProposalComment != "")}
                    >
                        Accept
                    </Button>
                    <Button color="secondary" onClick={this.togleAcceptActionProposal} disabled={this.state.processActionProposal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleConfirmAcceptActionProposal = () => {
        const acceptRejectActionProposalComment = this.state.acceptRejectActionProposalComment;
        const timezoneOffset = (new Date().getTimezoneOffset() * -1) / 60;

        this.setState({ showAcceptActionProposalConfirmation: false, processActionProposal: true });
        this.setState({ showLoadingPanel: true, loadingPanelHeader: "Accept update profile and evidence from action proposal", loadingPanelBody: "Please wait ..." });
        this.props.onActionPlan.acceptUpdateProfileActionProposal(this.props.account.updateProfileEvidenceProposedActionId, acceptRejectActionProposalComment, timezoneOffset).then(() => {
            if (!this.props.actionPlanUpdating) {
                if (this.props.actionPlanUpdatingError) {
                    this.generateAlert("danger", this.props.actionPlanUpdatingError.errData.Message);
                    this.setState({ showAcceptActionProposalConfirmation: true });
                } else {
                    this.generateAlert("success", "Update profile and evidence from action proposal accepted.");
                    this.setState({ showProposedAction: false });
                    this.initData();
                }
            }
            this.setState({ processActionProposal: this.props.actionPlanUpdating, showLoadingPanel: this.props.actionPlanUpdating });
        });
    };

    handleRejectActionProposal = () => {
        this.setState({ showRejectActionProposalConfirmation: true, acceptRejectActionProposalComment: "" });
    };

    togleRejectActionProposal = () => {
        const showRejectActionProposalConfirmation = this.state.showRejectActionProposalConfirmation;
        this.setState({ showRejectActionProposalConfirmation: !showRejectActionProposalConfirmation });
    };

    renderRejectActionProposalConfirmation = () => {
        return (
            <Modal isOpen={this.state.showRejectActionProposalConfirmation} toggle={!this.state.processActionProposal && this.state.showProposedAction && this.togleRejectActionProposal}>
                <ModalHeader toggle={!this.state.processActionProposal && this.state.showProposedAction && this.togleRejectActionProposal}>Reject proposal confirmation</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        You are about to <b>reject</b> the proposed changes. This will:
                                        <br />
                                        <ul>
                                            <li>Keep your existing profile unchanged</li>
                                            <li>Remove the proposed changes</li>
                                            <li>Send a notification to the proposer with the comments below</li>
                                        </ul>
                                    </Label>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={12}>
                                        <Input type="textarea" name="acceptRejectActionProposalComment" placeholder="Please enter comment" value={this.state.acceptRejectActionProposalComment ? this.state.acceptRejectActionProposalComment : ""} onChange={this.handleActionProposalCommentChange} />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmRejectActionProposal();
                        }}
                        disabled={this.state.processActionProposal || !(this.state.acceptRejectActionProposalComment && this.state.acceptRejectActionProposalComment != "")}
                    >
                        Reject
                    </Button>
                    <Button color="secondary" onClick={this.togleRejectActionProposal} disabled={this.state.processActionProposal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleConfirmRejectActionProposal = () => {
        const acceptRejectActionProposalComment = this.state.acceptRejectActionProposalComment;
        const timezoneOffset = (new Date().getTimezoneOffset() * -1) / 60;

        this.setState({ showRejectActionProposalConfirmation: false, processActionProposal: true });
        this.setState({ showLoadingPanel: true, loadingPanelHeader: "Reject update profile and evidence from action proposal", loadingPanelBody: "Please wait ..." });
        this.props.onActionPlan.rejectUpdateProfileActionProposal(this.props.account.updateProfileEvidenceProposedActionId, acceptRejectActionProposalComment, timezoneOffset).then(() => {
            if (!this.props.actionPlanUpdating) {
                if (this.props.actionPlanUpdatingError) {
                    this.generateAlert("danger", this.props.actionPlanUpdatingError.errData.Message);
                    this.setState({ showRejectActionProposalConfirmation: true });
                } else {
                    this.generateAlert("success", "Update profile and evidence from action proposal rejected.");
                    this.setState({ showProposedAction: false });
                    this.initData();
                }
            }
            this.setState({ processActionProposal: this.props.actionPlanUpdating, showLoadingPanel: this.props.actionPlanUpdating });
        });
    };

    handleViewSuggestedUpdate = () => {
        let { lorProfiles, skillProfiles, selectedJobMatchType, selectedProfileVersion, profileVersions } = this.state;

        if (selectedJobMatchType && selectedJobMatchType.value != "None") {
            this.handleChangeSkill({
                label: this.contentTranslator(this.props.contentTranslations, "SfiaAll", "MatchNone", "None"),
                value: "None",
            });
        }

        let latestVersion = null;

        if (profileVersions && profileVersions.length > 0) {
            latestVersion = profileVersions[0];
        }

        if (latestVersion && selectedProfileVersion && latestVersion.version != selectedProfileVersion.version) {
            this.handleChangeVersion(latestVersion);
        }

        this.setState({ showSuggestedUpdate: true, showDetail: false, showEdit: false, skillProfilesEdit: [], editCount: 0, createNewVersion: false, lorProfiles: lorProfiles.filter((it) => it.id), skillProfiles: skillProfiles.filter((it) => it.id) });
    };

    handleCancelSuggestedUpdate = () => {
        this.setState({ showSuggestedUpdate: false });
    };

    handleUpdateProfileSuggestionCommentChange = (event) => {
        const { value } = event.target;

        this.setState({
            acceptRejectUpdateProfileSuggestionComment: value,
        });
    };

    handleAcceptUpdateProfileSuggestion = () => {
        this.setState({ showAcceptUpdateProfileSuggestionConfirmation: true, acceptRejectUpdateProfileSuggestionComment: "" });
    };

    togleAcceptUpdateProfileSuggestion = () => {
        const showAcceptUpdateProfileSuggestionConfirmation = this.state.showAcceptUpdateProfileSuggestionConfirmation;
        this.setState({ showAcceptUpdateProfileSuggestionConfirmation: !showAcceptUpdateProfileSuggestionConfirmation });
    };

    renderAcceptUpdateProfileSuggestionConfirmation = () => {
        return (
            <Modal isOpen={this.state.showAcceptUpdateProfileSuggestionConfirmation} toggle={!this.state.processUpdateProfileSuggestion && this.state.showSuggestedUpdate && this.togleAcceptUpdateProfileSuggestion}>
                <ModalHeader toggle={!this.state.processUpdateProfileSuggestion && this.state.showSuggestedUpdate && this.togleAcceptUpdateProfileSuggestion}>Accept suggestion confirmation</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        You are about to <b>accept</b> the suggested changes. This will:
                                        <br />
                                        <ul>
                                            <li>Create a new version of your profile</li>
                                            <li>If it was approved the approval will be revoked</li>
                                            <li>Send a notification to the proposer with the comments below</li>
                                        </ul>
                                    </Label>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={12}>
                                        <Input type="textarea" name="acceptRejectUpdateProfileSuggestionComment" placeholder="Please enter comment" value={this.state.acceptRejectUpdateProfileSuggestionComment ? this.state.acceptRejectUpdateProfileSuggestionComment : ""} onChange={this.handleUpdateProfileSuggestionCommentChange} />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmAcceptUpdateProfileSuggestion();
                        }}
                        disabled={this.state.processUpdateProfileSuggestion || !(this.state.acceptRejectUpdateProfileSuggestionComment && this.state.acceptRejectUpdateProfileSuggestionComment != "")}
                    >
                        Accept
                    </Button>
                    <Button color="secondary" onClick={this.togleAcceptUpdateProfileSuggestion} disabled={this.state.processUpdateProfileSuggestion}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleConfirmAcceptUpdateProfileSuggestion = () => {
        const acceptRejectUpdateProfileSuggestionComment = this.state.acceptRejectUpdateProfileSuggestionComment;
        const timezoneOffset = (new Date().getTimezoneOffset() * -1) / 60;

        this.setState({ showAcceptUpdateProfileSuggestionConfirmation: false, processUpdateProfileSuggestion: true });
        this.setState({ showLoadingPanel: true, loadingPanelHeader: "Accept update profile suggestion", loadingPanelBody: "Please wait ..." });
        this.props.onSkillProfile.acceptUpdateProfileSuggestion(this.props.account.updateProfileSuggestionId, acceptRejectUpdateProfileSuggestionComment, timezoneOffset).then(() => {
            if (!this.props.updating) {
                if (this.props.error) {
                    this.generateAlert("danger", this.props.error.errData.Message);
                    this.setState({ showAcceptUpdateProfileSuggestionConfirmation: true });
                } else {
                    this.generateAlert("success", "Update profile suggestion accepted.");
                    this.setState({ showSuggestedUpdate: false });
                    this.initData();
                }
            }
            this.setState({ processUpdateProfileSuggestion: this.props.updating, showLoadingPanel: this.props.updating });
        });
    };

    handleRejectUpdateProfileSuggestion = () => {
        this.setState({ showRejectUpdateProfileSuggestionConfirmation: true, acceptRejectUpdateProfileSuggestionComment: "" });
    };

    togleRejectUpdateProfileSuggestion = () => {
        const showRejectUpdateProfileSuggestionConfirmation = this.state.showRejectUpdateProfileSuggestionConfirmation;
        this.setState({ showRejectUpdateProfileSuggestionConfirmation: !showRejectUpdateProfileSuggestionConfirmation });
    };

    renderRejectUpdateProfileSuggestionConfirmation = () => {
        return (
            <Modal isOpen={this.state.showRejectUpdateProfileSuggestionConfirmation} toggle={!this.state.processUpdateProfileSuggestion && this.state.showSuggestedUpdate && this.togleRejectUpdateProfileSuggestion}>
                <ModalHeader toggle={!this.state.processUpdateProfileSuggestion && this.state.showSuggestedUpdate && this.togleRejectUpdateProfileSuggestion}>Reject proposal confirmation</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        You are about to <b>reject</b> the suggested changes. This will:
                                        <br />
                                        <ul>
                                            <li>Keep your existing profile unchanged</li>
                                            <li>Remove the suggested changes</li>
                                            <li>Send a notification to the proposer with the comments below</li>
                                        </ul>
                                    </Label>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={12}>
                                        <Input type="textarea" name="acceptRejectUpdateProfileSuggestionComment" placeholder="Please enter comment" value={this.state.acceptRejectUpdateProfileSuggestionComment ? this.state.acceptRejectUpdateProfileSuggestionComment : ""} onChange={this.handleUpdateProfileSuggestionCommentChange} />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmRejectUpdateProfileSuggestion();
                        }}
                        disabled={this.state.processUpdateProfileSuggestion || !(this.state.acceptRejectUpdateProfileSuggestionComment && this.state.acceptRejectUpdateProfileSuggestionComment != "")}
                    >
                        Reject
                    </Button>
                    <Button color="secondary" onClick={this.togleRejectUpdateProfileSuggestion} disabled={this.state.processUpdateProfileSuggestion}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleConfirmRejectUpdateProfileSuggestion = () => {
        const acceptRejectUpdateProfileSuggestionComment = this.state.acceptRejectUpdateProfileSuggestionComment;
        const timezoneOffset = (new Date().getTimezoneOffset() * -1) / 60;

        this.setState({ showRejectUpdateProfileSuggestionConfirmation: false, processUpdateProfileSuggestion: true });
        this.setState({ showLoadingPanel: true, loadingPanelHeader: "Reject update profile suggestion", loadingPanelBody: "Please wait ..." });
        this.props.onSkillProfile.rejectUpdateProfileSuggestion(this.props.account.updateProfileSuggestionId, acceptRejectUpdateProfileSuggestionComment, timezoneOffset).then(() => {
            if (!this.props.updating) {
                if (this.props.error) {
                    this.generateAlert("danger", this.props.error.errData.Message);
                    this.setState({ showRejectUpdateProfileSuggestionConfirmation: true });
                } else {
                    this.generateAlert("success", "Update profile suggestion rejected.");
                    this.setState({ showSuggestedUpdate: false });
                    this.initData();
                }
            }
            this.setState({ processUpdateProfileSuggestion: this.props.updating, showLoadingPanel: this.props.updating });
        });
    };

    updateFloatingLegendStatus = (showFloatingLegend) => {
        this.setState({ showFloatingLegend });
    };

    handleSuggestEditLowerLevelProfile = (editedProfiles) => {
        let willCreateNewVersion = false;

        if (!this.state.showEdit) {
            willCreateNewVersion = true;
            this.setState({ showEdit: !this.state.showEdit, willCreateNewVersion, createNewVersion: false, editCount: 0 });
        }

        this.handleEditProfileSkills(editedProfiles);
    };

    handleManagerSuggestEditLowerLevelProfile = (editedProfiles) => {
        if (!this.state.proposedUpdateLowerLevelMode) {
            this.setState({ proposedUpdateLowerLevelMode: true, editCount: 0 });
        } else {
            this.setState({ proposedUpdateLowerLevelMode: true });
        }

        this.handleEditProfileSkills(editedProfiles);
    };

    handleCancelProposeUpdateLowerLevel = () => {
        if (this.state.skillProfilesEdit.length > 0 && this.state.editCount > 0) {
            let { lorProfiles, skillProfiles } = this.state;

            if (window.confirm("You have some updates, are you sure to cancel the updates?")) {
                this.setState({
                    skillProfilesEdit: [],
                    editCount: 0,
                    createNewVersion: false,
                    proposedUpdateLowerLevelMode: false,
                    lorProfiles: lorProfiles.filter((it) => it.id),
                    skillProfiles: skillProfiles.filter((it) => it.id),
                });
            }
        } else {
            this.setState({
                proposedUpdateLowerLevelMode: false,
                lorProfiles: this.props.lorProfiles
                    ? this.props.lorProfiles && this.props.lorProfiles.length > 0
                        ? this.props.lorProfiles
                        : this.state.latestUpdatedLorProfiles
                        ? this.state.latestUpdatedLorProfiles && this.state.latestUpdatedLorProfiles.length > 0
                            ? this.state.latestUpdatedLorProfiles
                            : this.props.lorProfiles
                        : []
                    : this.state.latestUpdatedLorProfiles
                    ? this.state.latestUpdatedLorProfiles
                    : [],
                skillProfiles: this.props.skillProfiles
                    ? this.props.skillProfiles && this.props.skillProfiles.length > 0
                        ? this.props.skillProfiles
                        : this.state.latestUpdatedSkillProfiles
                        ? this.state.latestUpdatedSkillProfiles && this.state.latestUpdatedSkillProfiles.length > 0
                            ? this.state.latestUpdatedSkillProfiles
                            : this.props.skillProfiles
                        : []
                    : this.state.latestUpdatedSkillProfiles
                    ? this.state.latestUpdatedSkillProfiles
                    : [],
            });
        }
    };

    handleProposeUpdateProfileSuggestion = () => {
        this.setState({ showProposeUpdateProfileSuggestionConfirmation: true, acceptRejectUpdateProfileSuggestionComment: "" });
    };

    togleProposeUpdateProfileSuggestion = () => {
        const showProposeUpdateProfileSuggestionConfirmation = this.state.showProposeUpdateProfileSuggestionConfirmation;
        this.setState({ showProposeUpdateProfileSuggestionConfirmation: !showProposeUpdateProfileSuggestionConfirmation });
    };

    renderProposeUpdateProfileSuggestionConfirmation = () => {
        return (
            <Modal isOpen={this.state.showProposeUpdateProfileSuggestionConfirmation} toggle={!this.state.processUpdateProfileSuggestion && this.state.showSuggestedUpdate && this.togleProposeUpdateProfileSuggestion}>
                <ModalHeader toggle={!this.state.processUpdateProfileSuggestion && this.state.showSuggestedUpdate && this.togleProposeUpdateProfileSuggestion}>Propose suggestion confirmation</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>You are about to propose changes and notify the user. Please provide a comment</Label>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={12}>
                                        <Input
                                            type="textarea"
                                            name="acceptRejectUpdateProfileSuggestionComment"
                                            placeholder="Please enter comment"
                                            value={this.state.acceptRejectUpdateProfileSuggestionComment ? this.state.acceptRejectUpdateProfileSuggestionComment : ""}
                                            onChange={this.handleUpdateProfileSuggestionCommentChange}
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmProposeUpdateProfileSuggestion();
                        }}
                        disabled={this.state.processUpdateProfileSuggestion || !(this.state.acceptRejectUpdateProfileSuggestionComment && this.state.acceptRejectUpdateProfileSuggestionComment != "")}
                    >
                        Ok
                    </Button>
                    <Button color="secondary" onClick={this.togleProposeUpdateProfileSuggestion} disabled={this.state.processUpdateProfileSuggestion}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleConfirmProposeUpdateProfileSuggestion = () => {
        const { skillProfilesEdit } = this.state;

        if (this.props.managedPeople) {
            const skills = skillProfilesEdit.map((skill) => {
                return {
                    category: skill.category,
                    subCategory: skill.subCategory,
                    skill: skill.skill,
                    skillCode: skill.skillCode,
                    level: skill.level,
                    stringVal: skill.editedStringVal,
                    numericVal: skill.editedNumericVal,
                    labelVal: skill.editedLabelVal,
                    isExtraFramework: skill.isExtraFramework,
                    extraFrameworkLevel: skill.extraFrameworkLevel,
                    extraFrameworkLevelId: skill.extraFrameworkLevelId,
                };
            });

            this.setState({ showProposeUpdateProfileSuggestionConfirmation: false, processUpdateProfileSuggestion: true, showLoadingPanel: true, loadingPanelHeader: "Submit update profile suggestion", loadingPanelBody: "Please wait ..." });

            let { lorProfiles, skillProfiles } = this.state;

            this.props.onSkillProfile.submitUpdateProfileSuggestion(this.props.managedPeople.personB.email, this.state.acceptRejectUpdateProfileSuggestionComment, skills).then(() => {
                if (!this.props.updating) {
                    if (this.props.error) {
                        this.generateAlert("danger", this.props.error.errData.Message);
                        this.setState({ showProposeUpdateProfileSuggestionConfirmation: true });
                    } else {
                        this.generateAlert("success", "Update profile suggestion submitted");

                        this.setState({
                            skillProfilesEdit: [],
                            editCount: 0,
                            createNewVersion: false,
                            proposedUpdateLowerLevelMode: false,
                            lorProfiles: lorProfiles.filter((it) => it.id),
                            skillProfiles: skillProfiles.filter((it) => it.id),
                        });

                        this.initData();
                    }
                }

                this.setState({ processUpdateProfileSuggestion: this.props.updating, showLoadingPanel: this.props.updating });
            });
        }
    };

    handleSubmitProposeUpdateLowerLevel = () => {
        const { skillProfilesEdit } = this.state;

        if (this.props.managedPeople) {
            const skills = skillProfilesEdit.map((skill) => {
                return {
                    category: skill.category,
                    subCategory: skill.subCategory,
                    skill: skill.skill,
                    skillCode: skill.skillCode,
                    level: skill.level,
                    stringVal: skill.editedStringVal,
                    numericVal: skill.editedNumericVal,
                    labelVal: skill.editedLabelVal,
                    isExtraFramework: skill.isExtraFramework,
                    extraFrameworkLevel: skill.extraFrameworkLevel,
                    extraFrameworkLevelId: skill.extraFrameworkLevelId,
                };
            });

            this.setState({ showLoadingPanel: true, loadingPanelHeader: "Submit update profile suggestion", loadingPanelBody: "Please wait ..." });

            let { lorProfiles, skillProfiles } = this.state;

            this.props.onSkillProfile.submitUpdateProfileSuggestion(this.props.managedPeople.personB.email, skills).then(() => {
                if (!this.props.updating) {
                    if (this.props.error) {
                        this.generateAlert("danger", this.props.error.errData.Message);
                    } else {
                        this.generateAlert("success", "Update profile suggestion submitted");

                        this.setState({
                            skillProfilesEdit: [],
                            editCount: 0,
                            createNewVersion: false,
                            proposedUpdateLowerLevelMode: false,
                            lorProfiles: lorProfiles.filter((it) => it.id),
                            skillProfiles: skillProfiles.filter((it) => it.id),
                        });

                        this.initData();
                    }
                }

                this.setState({ showLoadingPanel: this.props.updating });
            });
        }
    };

    render() {
        const manageEndorsementOperation = localStorage.getItem("manageEndorsementOperation");
        const { skillProfilesEdit, editCount, selectedprofileType, selectedProfileVersion, profileVersions, account, createNewVersion, lors, selectedEndorsementStatus, endorsementStatusOptions, badgeRequest, showEditCertify, updateBadgeRequest, showProposedAction, showSuggestedUpdate } = this.state;
        const { person } = this.props;

        const currAssessment = this.props.currAssessment;

        let allowRequestApproval = false;
        let showRequestApprovalButton = false;
        let showApprovalPending = false;
        let showApprovalPostponed = false;
        let showReRequestApproval = false;
        let showCopyEndorsedButton = false;
        let showMakeLatestButton = false;
        let showMakeSelfAssessedButton = false;
        let showMakeNewVersionButton = false;
        let showEndorsementStatus = false;
        let showBeMentor = false;
        let showMenteeRequest = false;

        if (!this.props.managedPeople && this.state.profileTypeOptions.find((it) => it.value == "Endorsed") && selectedprofileType && selectedprofileType.value == "Self Assessed") {
            showCopyEndorsedButton = true;
        }

        let latestVersion = null;

        if (profileVersions && profileVersions.length > 0) {
            latestVersion = profileVersions[0];
        }

        if (!this.props.managedPeople && selectedprofileType && selectedprofileType.value == "Self Assessed" && latestVersion && latestVersion.version != selectedProfileVersion.version) {
            showMakeLatestButton = true;
        }

        if (!this.props.managedPeople && selectedprofileType && selectedprofileType.value == "Endorsed" && latestVersion && latestVersion.version == selectedProfileVersion.version && this.state.profileTypeOptions.length == 1) {
            showMakeSelfAssessedButton = true;
        }

        if (!this.props.managedPeople && selectedprofileType && selectedprofileType.value == "Self Assessed" && latestVersion && latestVersion.version == selectedProfileVersion.version) {
            showMakeNewVersionButton = true;
        }

        if (
            manageEndorsementOperation &&
            manageEndorsementOperation != "HIDE" &&
            this.props.managedPeople &&
            this.props.managedPeople.endorsementStatus != "Approved" &&
            selectedprofileType &&
            selectedprofileType.value == "Endorsed" &&
            latestVersion &&
            latestVersion.version == selectedProfileVersion.version
        ) {
            showEndorsementStatus = true;
        }

        if (!this.props.managedPeople && selectedprofileType && selectedprofileType.value == "Self Assessed") {
            if (
                account &&
                currAssessment &&
                currAssessment.enableApprovalRequest &&
                (currAssessment.adminSelfAssessedApproverFound ||
                    (currAssessment.allowApprovals == 1 && person && person.upRelationships && person.upRelationships.find((it) => it.relationshipType == "Line Manager")) ||
                    (currAssessment.allowApprovals == 2 && person && person.upRelationships && person.upRelationships.find((it) => it.relationshipType == "Line Manager" || it.relationshipType == "Team Leader")) ||
                    (currAssessment.allowApprovals == 3 && person && person.upRelationships && person.upRelationships.find((it) => it.relationshipType == "Mentor" && it.status == "Accepted")) ||
                    (currAssessment.allowApprovals == 4 && person && person.upRelationships && person.upRelationships.find((it) => it.relationshipType == "Line Manager" || (it.relationshipType == "Mentor" && it.status == "Accepted"))) ||
                    (currAssessment.allowApprovals == 5 && person && person.upRelationships && person.upRelationships.find((it) => it.relationshipType == "Line Manager" || it.relationshipType == "Team Leader" || (it.relationshipType == "Mentor" && it.status == "Accepted"))))
            ) {
                allowRequestApproval = true;
            }

            if (
                account &&
                currAssessment &&
                currAssessment.enableApprovalRequest &&
                !account.isApprovalRequested &&
                !account.isApprovalPostponed &&
                (currAssessment.adminSelfAssessedApproverFound ||
                    (currAssessment.allowApprovals == 1 && person && person.upRelationships && person.upRelationships.find((it) => it.relationshipType == "Line Manager")) ||
                    (currAssessment.allowApprovals == 2 && person && person.upRelationships && person.upRelationships.find((it) => it.relationshipType == "Line Manager" || it.relationshipType == "Team Leader")) ||
                    (currAssessment.allowApprovals == 3 && person && person.upRelationships && person.upRelationships.find((it) => it.relationshipType == "Mentor" && it.status == "Accepted")) ||
                    (currAssessment.allowApprovals == 4 && person && person.upRelationships && person.upRelationships.find((it) => it.relationshipType == "Line Manager" || (it.relationshipType == "Mentor" && it.status == "Accepted"))) ||
                    (currAssessment.allowApprovals == 5 && person && person.upRelationships && person.upRelationships.find((it) => it.relationshipType == "Line Manager" || it.relationshipType == "Team Leader" || (it.relationshipType == "Mentor" && it.status == "Accepted"))))
            ) {
                if (!account.updateProfileEvidenceProposed) {
                    showRequestApprovalButton = true;
                }
            } else {
                if (account && currAssessment && currAssessment.enableApprovalRequest && account.approvals && account.approvals.length > 0 && account.approvals.filter((it) => !it.isFramework && it.approvedVersion == account.latestProfileVersion).length > 0) {
                    showApprovalPending = false;
                } else if (account && currAssessment && currAssessment.enableApprovalRequest) {
                    if (account.isApprovalRequested || account.isApprovalPostponed) {
                        showApprovalPending = true;
                    }

                    showApprovalPostponed = account.isApprovalPostponed ? account.isApprovalPostponed : false;
                    showReRequestApproval = account.isApprovalPostponedShown && account.isApprovalPostponedShown == false ? false : account.isApprovalPostponedShown && account.isApprovalPostponedShown == true ? true : false;
                }
            }
        }

        if (!this.props.managedPeople && selectedprofileType && selectedprofileType.value == "Self Assessed") {
            if (
                account &&
                currAssessment &&
                currAssessment.enableFindMentor &&
                //!account.isMentor &&
                account.canBeMentor
            ) {
                showBeMentor = true;
            }
            if (account && currAssessment && currAssessment.enableFindMentor && account.isMentor) {
                showMenteeRequest = true;
            }
        }

        let enableSave = false;
        if (skillProfilesEdit.length != 0 && editCount > 0) {
            if (!createNewVersion) {
                enableSave = true;
            } else {
                let lorProfilesAdded = lors
                    .map((lor) => {
                        const lorSkillProfileEdit = skillProfilesEdit.find((it) => it.source == "lorProfiles" && it.lorCode == lor.lorCode);
                        return { lorCode: lor.lorCode, numericVal: lorSkillProfileEdit ? lorSkillProfileEdit.numericVal : null };
                    })
                    .filter((it) => it.numericVal);

                enableSave = lorProfilesAdded.length == lors.length && skillProfilesEdit.filter((it) => it.source == "skillProfiles" && it.editedNumericVal != 0).length > 0;
            }
        }

        let showApproveEndorsement = false;
        if (manageEndorsementOperation == "APPROVE" && this.props.managedPeople && this.props.managedPeople.isEndorser) {
            showApproveEndorsement = true;
        }

        return (
            <React.Fragment>
                {this.state.profileUpdating && (
                    <Alert color="warning">
                        <ContentTranslator page="SfiaProfile" name="SkillProfileUpdateInProgressMessage" contentText="Update skill profiles still in progress" />
                    </Alert>
                )}
                {!this.state.profileUpdating && this.state.profileUpdatingSuccess && (
                    <div style={{ padding: "0px 0px 20px 20px", marginTop: "-20px", marginRight: "-20px" }}>
                        <AlertDialog className="alert-full" color="success" message={this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "EditSkillSuccessMessage", "Your skill profiles has been successfully updated")}></AlertDialog>
                    </div>
                )}
                {!this.state.profileUpdating && !this.state.profileUpdatingSuccess && this.state.profileUpdatingError && (
                    <div style={{ padding: "0px 0px 20px 20px", marginTop: "-20px", marginRight: "-20px" }}>
                        <AlertDialog className="alert-full" color="danger" message={this.state.profileUpdatingError} sticky={true}></AlertDialog>
                    </div>
                )}
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.renderLoadingPanel()}
                {this.renderRequestApprovalFirstConfirmation()}
                {this.renderRequestApprovalSecondConfirmation()}
                {this.renderPostponedReasonDialog()}
                {this.renderMakeLatestProfileConfirmation()}
                {this.renderApproveEndorsementConfirmation()}
                {this.renderMaxBadgeExceedConfirmation()}
                {this.renderSubmitBadgesConfirmation()}
                {this.renderApproveBadgesConfirmation()}
                {this.renderMentorProfile()}
                {this.renderMenteeRequest()}
                {this.renderAcceptActionProposalConfirmation()}
                {this.renderRejectActionProposalConfirmation()}
                {this.renderAcceptUpdateProfileSuggestionConfirmation()}
                {this.renderRejectUpdateProfileSuggestionConfirmation()}
                {this.renderProposeUpdateProfileSuggestionConfirmation()}
                <ContentWrapperLg>
                    <Row>
                        <Col sm="6">
                            <p ref={this.mySkillProfileWrapper} className="mb-0" style={headerStyle}>
                                {!this.props.badgeCertification ? <ContentTranslator page="SfiaProfile" name="SkillProfileTitle" contentText="Skills Profile & Gaps" /> : "Select or edit skills for issuing badges"}
                            </p>
                            <p style={subHeaderStyle}>
                                <ContentTranslator page="SfiaProfile" name="SkillProfileTitleNote" contentText="Hover over each colour block to display the skill description." />
                            </p>
                        </Col>
                        <Col sm="2"></Col>
                        <Col sm="4">
                            {!this.props.managedPeople && this.props.account && this.props.account.updateProfileEvidenceProposed && this.state.selectedprofileType && this.state.selectedprofileType.value == "Self Assessed" ? (
                                <React.Fragment>
                                    {!showProposedAction && (
                                        <button className="btn btn-light btnPropose-Style" onClick={this.handleViewProposedAction} style={buttonStyle}>
                                            {this.parameterizedString(
                                                this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonHaveActionProposalNotification", "{s1} has proposed changes to your profile on {s2}. Click to view."),
                                                this.props.account.updateProfileEvidenceProposedBy,
                                                new Intl.DateTimeFormat("en-GB", {
                                                    year: "numeric",
                                                    month: "long",
                                                    day: "numeric",
                                                }).format(new Date(this.props.account.updateProfileEvidenceProposedOn))
                                            )}
                                        </button>
                                    )}
                                    {showProposedAction && (
                                        <button className="btn btn-light btnPropose-Style" style={buttonStyle}>
                                            {this.parameterizedString(
                                                this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileHaveActionProposalNotification", "Now showing proposed changes by {s1} on {s2}."),
                                                this.props.account.updateProfileEvidenceProposedBy,
                                                new Intl.DateTimeFormat("en-GB", {
                                                    year: "numeric",
                                                    month: "long",
                                                    day: "numeric",
                                                }).format(new Date(this.props.account.updateProfileEvidenceProposedOn))
                                            )}
                                        </button>
                                    )}
                                </React.Fragment>
                            ) : (
                                !this.props.managedPeople &&
                                this.props.account &&
                                this.props.account.updateProfileSuggested &&
                                this.state.selectedprofileType &&
                                this.state.selectedprofileType.value == "Self Assessed" && (
                                    <React.Fragment>
                                        {!showSuggestedUpdate && (
                                            <button className="btn btn-light btnPropose-Style" onClick={this.handleViewSuggestedUpdate} style={buttonStyle}>
                                                {this.parameterizedString(
                                                    this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonHaveUpdateProfileSuggestionNotification", "{s1} has suggested changes to your profile on {s2}. Click to view."),
                                                    this.props.account.updateProfileSuggestedBy,
                                                    new Intl.DateTimeFormat("en-GB", {
                                                        year: "numeric",
                                                        month: "long",
                                                        day: "numeric",
                                                    }).format(new Date(this.props.account.updateProfileSuggestedOn))
                                                )}
                                            </button>
                                        )}
                                        {showSuggestedUpdate && (
                                            <button className="btn btn-light btnPropose-Style" style={buttonStyle}>
                                                {this.parameterizedString(
                                                    this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileHaveUpdateProfileSuggestionNotification", "Now showing suggested changes by {s1} on {s2}."),
                                                    this.props.account.updateProfileSuggestedBy,
                                                    new Intl.DateTimeFormat("en-GB", {
                                                        year: "numeric",
                                                        month: "long",
                                                        day: "numeric",
                                                    }).format(new Date(this.props.account.updateProfileSuggestedOn))
                                                )}
                                            </button>
                                        )}
                                    </React.Fragment>
                                )
                            )}
                        </Col>
                    </Row>
                    {!this.props.badgeCertification && (
                        <Container className="p-0">
                            <Row className="justify-content-between">
                                <Col sm="12">
                                    <Container>
                                        <Row sm="12">
                                            <Col sm="4" className="mt-2">
                                                <p className="mb-1" style={ddHeaderStyle}>
                                                    <ContentTranslator page="SfiaProfile" name="SkillProfileChooseProfileType" contentText="Choose Profile Type" />
                                                </p>
                                                <Select
                                                    value={this.state.selectedprofileType}
                                                    onChange={this.handleChangeProfile}
                                                    options={this.state.profileTypeOptions}
                                                    isDisabled={this.state.showEdit || this.state.showProposedAction || this.state.proposedUpdateLowerLevelMode || this.state.showSuggestedUpdate}
                                                />
                                            </Col>
                                            <Col sm="4" className="mt-2">
                                                <p className="mb-1" style={ddHeaderStyle}>
                                                    <ContentTranslator page="SfiaProfile" name="SkillProfileChooseRequirementType" contentText="Choose Requirement Type" />
                                                </p>
                                                <Select value={this.state.selectedJobMatchType} onChange={this.handleChangeSkill} options={this.state.jobMatchTypes} isDisabled={this.state.showProposedAction || this.state.proposedUpdateLowerLevelMode || this.state.showSuggestedUpdate} />
                                            </Col>
                                            <Col sm="4" className="mt-2">
                                                <p className="mb-1" style={ddHeaderStyle}>
                                                    <ContentTranslator page="SfiaProfile" name="SkillProfileChooseVersion" contentText="Version" />
                                                </p>
                                                <Select
                                                    value={this.state.selectedProfileVersion}
                                                    onChange={this.handleChangeVersion}
                                                    options={this.state.profileVersions}
                                                    isDisabled={this.state.showEdit || this.state.showProposedAction || this.state.proposedUpdateLowerLevelMode || this.state.showSuggestedUpdate}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                                {!this.state.loading && (
                                    <Col
                                        sm="12"
                                        style={{
                                            paddingTop: "20px",
                                            textAlign: "right",
                                        }}
                                    >
                                        <table align="right">
                                            {!showProposedAction && !showSuggestedUpdate && (
                                                <tr valign="top">
                                                    {skillProfilesEdit.length > 0 && editCount > 0 && (
                                                        <td valign="bottom">
                                                            <div style={{ display: "inline-block", paddingBottom: "10px", paddingRight: "10px" }}>
                                                                <span className="label-warning">
                                                                    {this.state.proposedUpdateLowerLevelMode
                                                                        ? this.parameterizedString(this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileSuggestedSkillsCount", "You have {s1} pending Proposed skill changes."), editCount)
                                                                        : this.parameterizedString(this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileUpdatedSkillsCount", "You have {s1} pending skill changes."), editCount)}
                                                                </span>
                                                            </div>
                                                        </td>
                                                    )}
                                                    {showMenteeRequest && (
                                                        <td valign="bottom">
                                                            <button id="btnMenteeRequest" className="btn btn-light btmMentor-Style" onClick={this.handleOpenMenteeRequest} style={buttonStyle}>
                                                                <ContentTranslator page="SfiaProfile" name="SkillProfileButtonMenteeRequest" contentText="Mentee Requests" />
                                                            </button>
                                                            <UncontrolledTooltip target={`btnMenteeRequest`} placement="bottom">
                                                                <ContentTranslator page="SfiaProfileTooltip" name="SkillProfileBecomeMentorTooltip" contentText="Mentee Requests" />
                                                            </UncontrolledTooltip>
                                                        </td>
                                                    )}
                                                    {showBeMentor && (
                                                        <td valign="bottom">
                                                            <button id="btnBecomeMentor" className="btn btn-light btmMentor-Style" onClick={this.handleOpenMentorProfile} style={{ marginLeft: "3px", minWidth: "80px" }}>
                                                                {this.state.account.isMentor ? (
                                                                    <ContentTranslator page="SfiaProfile" name="SkillProfileButtonUpteMentorProfile" contentText={"Update Mentor Profile"} />
                                                                ) : (
                                                                    <ContentTranslator page="SfiaProfile" name="SkillProfileButtonBecomeMentor" contentText={"Become a Mentor"} />
                                                                )}
                                                            </button>
                                                            <UncontrolledTooltip target={`btnBecomeMentor`} placement="bottom">
                                                                {this.state.account.isMentor ? (
                                                                    <ContentTranslator page="SfiaProfileTooltip" name="SkillProfileButtonUpteMentorProfileTooltip" contentText={"Update Mentor Profile"} />
                                                                ) : (
                                                                    <ContentTranslator page="SfiaProfileTooltip" name="SkillProfileBecomeMentorTooltip" contentText={"Become a Mentor"} />
                                                                )}
                                                            </UncontrolledTooltip>
                                                        </td>
                                                    )}
                                                    {showEndorsementStatus && (
                                                        <td valign="bottom">
                                                            <div style={{ display: "inline-block", width: "200px", textAlign: "left" }}>
                                                                <p className="mb-1" style={ddHeaderStyle}>
                                                                    <ContentTranslator page="SfiaProfile" name="SkillProfileEndorsedStatus" contentText="Endorsed Status" />
                                                                </p>
                                                                <Select
                                                                    value={selectedEndorsementStatus}
                                                                    onChange={this.handleChangeEndorsementStatus}
                                                                    options={showApproveEndorsement == true ? endorsementStatusOptions : endorsementStatusOptions.filter((it) => it.value != "Approved")}
                                                                    isDisabled={this.state.showEdit || this.state.updateEndorsedStatus}
                                                                />
                                                            </div>
                                                        </td>
                                                    )}
                                                    {this.props.managedPeople != true && !this.state.showEdit && showRequestApprovalButton && (
                                                        <td valign="bottom">
                                                            <button id="btnRequestApproval" className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleRequestAccountApproval} disabled={this.state.showEdit}>
                                                                <ContentTranslator page="SfiaProfile" name="SkillProfileButtonRequestApproval" contentText="Request Approval" />
                                                            </button>
                                                            <UncontrolledTooltip target={`btnRequestApproval`} placement="bottom">
                                                                <ContentTranslator page="SfiaProfileTooltip" name="SkillProfileButtonRequestApprovalTooltip" contentText="Request the approval of your self-assessed Profile" />
                                                            </UncontrolledTooltip>
                                                        </td>
                                                    )}
                                                    {this.props.managedPeople != true && !this.state.showEdit && showApprovalPending && !showApprovalPostponed && (
                                                        <td valign="bottom">
                                                            <button className="btn btn-warning label-btn-warning" style={{ marginLeft: "10px", cursor: "default" }}>
                                                                <ContentTranslator page="SfiaProfile" name="SkillProfileApprovalPendingNotification" contentText="Approval Pending" />
                                                            </button>
                                                        </td>
                                                    )}
                                                    {this.props.managedPeople != true && !this.state.showEdit && showApprovalPending && showApprovalPostponed && showReRequestApproval && allowRequestApproval && (
                                                        <td valign="bottom">
                                                            <button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleRequestAccountApproval} disabled={this.state.showEdit}>
                                                                <ContentTranslator page="SfiaProfile" name="SkillProfileButtonReRequestApproval" contentText="Re-request Approval" />
                                                            </button>
                                                        </td>
                                                    )}
                                                    {this.props.managedPeople != true && !this.state.showEdit && showApprovalPending && showApprovalPostponed && (
                                                        <td valign="bottom">
                                                            <button className="btn btn-danger label-btn-postponed" style={{ marginLeft: "10px" }} onClick={this.handleShowPostponedReason}>
                                                                <ContentTranslator page="SfiaProfile" name="SkillProfileApprovalPostponedNotification" contentText="Approval postponed<br />Click for reasons" />
                                                            </button>
                                                        </td>
                                                    )}
                                                    {this.props.managedPeople != true && !this.state.showEdit && showCopyEndorsedButton && (
                                                        <td valign="bottom">
                                                            <button id="btnCopyEndorsed" className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleCopyEndorsedProfile} disabled={this.state.showEdit}>
                                                                <ContentTranslator page="SfiaProfile" name="SkillProfileButtonReplaceWithEndorsed" contentText="Replace with Endorsed" />
                                                            </button>
                                                            <UncontrolledTooltip target={`btnCopyEndorsed`} placement="bottom">
                                                                <ContentTranslator page="SfiaProfile" name="SkillProfileButtonReplaceWithEndorsedTooltip" contentText="This will copy your endorsed skill profile to create a new version of your self-assessed profile" />
                                                            </UncontrolledTooltip>
                                                        </td>
                                                    )}
                                                    {this.props.managedPeople != true && !this.state.showEdit && showMakeNewVersionButton && (
                                                        <td valign="bottom">
                                                            <button id="btnCreateNew" className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleCreateNewVersion} disabled={this.state.showEdit}>
                                                                <ContentTranslator page="SfiaProfile" name="SkillProfileButtonCreateNewVersion" contentText="Create New Version" />
                                                            </button>
                                                            <UncontrolledTooltip target={`btnCreateNew`} placement="bottom">
                                                                <ContentTranslator page="SfiaProfileTooltip" name="SkillProfileButtonCreateNewVersionTooltip" contentText="Create a new version of your self-assessed profile from scratch" />
                                                            </UncontrolledTooltip>
                                                        </td>
                                                    )}
                                                    {this.props.managedPeople != true && !this.state.showEdit && showMakeLatestButton && (
                                                        <td valign="bottom">
                                                            <button id="btnMakeLatest" className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleShowMakeLatestConfirmation} disabled={this.state.showEdit}>
                                                                <ContentTranslator page="SfiaProfile" name="SkillProfileButtonMakeLatest" contentText="Make Latest" />
                                                            </button>
                                                            <UncontrolledTooltip target={`btnMakeLatest`} placement="bottom">
                                                                <ContentTranslator page="SfiaProfileTooltip" name="SkillProfileButtonMakeLatestTooltip" contentText="Make this version as latest version" />
                                                            </UncontrolledTooltip>
                                                        </td>
                                                    )}
                                                    {this.props.managedPeople != true && !this.state.showEdit && showMakeSelfAssessedButton && (
                                                        <td valign="bottom">
                                                            <button id="btnMakeSelfAssessed" className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleCopyEndorsedProfile} disabled={this.state.showEdit}>
                                                                <ContentTranslator page="SfiaProfile" name="SkillProfileButtonMakeSelfAssessed" contentText="Make Self Assessed" />
                                                            </button>
                                                            <UncontrolledTooltip target={`btnMakeSelfAssessed`} placement="bottom">
                                                                <ContentTranslator page="SfiaProfileTooltip" name="SkillProfileButtonMakeSelfAssessedTooltip" contentText="This will copy your endorsed skill profile to create your self-assessed profile" />
                                                            </UncontrolledTooltip>
                                                        </td>
                                                    )}
                                                    {this.props.containerConfig && this.props.containerConfig.allowShowHiddenSkills && (
                                                        <td valign="bottom">
                                                            <button id="btnShowHidden" className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleChangeShowHidden}>
                                                                {this.state.showHidden
                                                                    ? this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonHideHidden", "Hide Hidden Skills")
                                                                    : this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonShowHidden", "Show Hidden Skills")}
                                                            </button>
                                                        </td>
                                                    )}
                                                    <td valign="bottom">
                                                        <button id="btnShowDetail" className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleChangeShowDetail} disabled={this.state.showEdit}>
                                                            {this.state.showDetail || this.state.showEdit
                                                                ? this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonHideDetail", "Hide Detail")
                                                                : this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonShowDetail", "Show Detail")}
                                                        </button>
                                                        <UncontrolledTooltip target={`btnShowDetail`} placement="bottom">
                                                            {this.state.showDetail || this.state.showEdit
                                                                ? this.props.managedPeople
                                                                    ? this.contentTranslator(this.props.contentTranslations, "SfiaProfileTooltip", "SkillProfileButtonHideDetailManagedTooltip", "Compress profile to show selected skills only")
                                                                    : this.contentTranslator(this.props.contentTranslations, "SfiaProfileTooltip", "SkillProfileButtonHideDetailTooltip", "Compress your profile to show your selected skills only")
                                                                : this.props.managedPeople
                                                                ? this.contentTranslator(this.props.contentTranslations, "SfiaProfileTooltip", "SkillProfileButtonShowDetailManagedTooltip", "Expand profile to show complete framework")
                                                                : this.contentTranslator(this.props.contentTranslations, "SfiaProfileTooltip", "SkillProfileButtonShowDetailTooltip", "Expand your profile to show the full framework")}
                                                        </UncontrolledTooltip>
                                                    </td>
                                                    {!this.props.managedPeople && this.state.selectedprofileType && this.state.selectedprofileType.value == "Self Assessed" && !this.state.selectedProfileVersion && (
                                                        <td valign="bottom">
                                                            {this.state.showEdit ? (
                                                                <button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleChangeShowEdit}>
                                                                    {this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonCancel", "Cancel")}
                                                                </button>
                                                            ) : (
                                                                <button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleCreateNewVersion}>
                                                                    {this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonCreateSelfAssessedProfile", "Create Profile")}
                                                                </button>
                                                            )}
                                                        </td>
                                                    )}
                                                    {this.props.managedPeople && this.state.selectedprofileType && this.state.selectedprofileType.value == "Endorsed" && (this.props.isEndorser || this.props.manageEndorsements) && !this.state.selectedProfileVersion && (
                                                        <td valign="bottom">
                                                            {this.state.showEdit ? (
                                                                <button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleChangeShowEdit}>
                                                                    {this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonCancel", "Cancel")}
                                                                </button>
                                                            ) : (
                                                                <button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleCreateNewVersion}>
                                                                    {this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonCreateEndorsedProfile", "Create Endorsed Profile")}
                                                                </button>
                                                            )}
                                                        </td>
                                                    )}
                                                    {this.state.showEdit && (
                                                        <td valign="bottom">
                                                            <button className="btn btn-light btnSecondary-Style" disabled={!enableSave} style={buttonStyle} onClick={this.handleSaveSkillUpdate}>
                                                                <ContentTranslator page="SfiaProfile" name="SkillProfileButtonSave" contentText="Save" />
                                                            </button>
                                                        </td>
                                                    )}
                                                    {this.state.selectedprofileType &&
                                                        ((!this.props.managedPeople && this.state.selectedprofileType.value == "Self Assessed") ||
                                                            (this.props.managedPeople && this.state.selectedprofileType.value == "Endorsed" && (!showApproveEndorsement || this.props.managedPeople.endorsementStatus == "Approved") && (this.props.isEndorser || this.props.manageEndorsements))) &&
                                                        this.state.selectedProfileVersion &&
                                                        this.state.selectedProfileVersion.sfiaVersion == this.state.profileVersions[0].sfiaVersion &&
                                                        this.state.selectedProfileVersion.version == this.state.profileVersions[0].version && (
                                                            <td valign="bottom">
                                                                <button id="btnEditProfile" className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleChangeShowEdit} disabled={this.state.profileUpdating}>
                                                                    {this.state.showEdit
                                                                        ? this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonCancel", "Cancel")
                                                                        : this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonEditProfile", "Edit Profile")}
                                                                </button>
                                                                {!this.state.showEdit && (
                                                                    <UncontrolledTooltip target={`btnEditProfile`} placement="bottom">
                                                                        {this.contentTranslator(this.props.contentTranslations, "SfiaProfileTooltip", "SkillProfileButtonEditProfileTooltip", "Open your profile in EDIT mode so you can make changes")}
                                                                    </UncontrolledTooltip>
                                                                )}
                                                            </td>
                                                        )}
                                                    {this.state.proposedUpdateLowerLevelMode && (
                                                        <React.Fragment>
                                                            <td valign="bottom">
                                                                <button className="btn btn-light btnSecondary-Style" disabled={!enableSave} style={buttonStyle} onClick={this.handleProposeUpdateProfileSuggestion}>
                                                                    <ContentTranslator page="SfiaProfile" name="SkillProfileButtonPropose" contentText="Propose" />
                                                                </button>
                                                                <button id="btnEditProfile" className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleCancelProposeUpdateLowerLevel} disabled={this.state.profileUpdating}>
                                                                    <ContentTranslator page="SfiaProfile" name="SkillProfileButtonCancel" contentText="Cancel" />
                                                                </button>
                                                            </td>
                                                        </React.Fragment>
                                                    )}
                                                    {currAssessment &&
                                                        currAssessment.allowUserExportSelfAssessedProfile &&
                                                        !this.props.managedPeople &&
                                                        this.state.selectedprofileType &&
                                                        this.state.selectedprofileType.value == "Self Assessed" &&
                                                        this.state.selectedProfileVersion &&
                                                        this.state.selectedProfileVersion.sfiaVersion == this.state.profileVersions[0].sfiaVersion &&
                                                        this.state.selectedProfileVersion.version == this.state.profileVersions[0].version && (
                                                            <td valign="bottom">
                                                                <button id="btnExportProfile" className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleExportProfile} disabled={this.props.loadingExport}>
                                                                    {!this.props.loadingExport ? this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonExportProfile", "Export Profile") : <Spinner type="grow" size="sm" color="primary" />}
                                                                </button>
                                                            </td>
                                                        )}
                                                    <td valign="bottom">
                                                        <button id="btnPrintProfile" className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handlePrintReport} disabled={this.props.loadingPrint}>
                                                            {!this.props.loadingPrint ? this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonPrint", "Print") : <Spinner type="grow" size="sm" color="primary" />}
                                                        </button>
                                                        <UncontrolledTooltip target={`btnPrintProfile`} placement="bottom">
                                                            {this.props.managedPeople
                                                                ? this.contentTranslator(this.props.contentTranslations, "SfiaProfileTooltip", "SkillProfileButtonPrintManagedTooltip", "Generate and download a report of your profile")
                                                                : this.contentTranslator(this.props.contentTranslations, "SfiaProfileTooltip", "SkillProfileButtonPrintTooltip", "Generate and download a report of your profile")}
                                                        </UncontrolledTooltip>
                                                    </td>
                                                </tr>
                                            )}
                                            {showProposedAction && (
                                                <tr valign="top">
                                                    <td valign="bottom">
                                                        <button id="btnAcceptProposeAction" className="btn btn-light btnSuccess-Style" style={buttonStyle} onClick={this.handleAcceptActionProposal}>
                                                            {this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonAcceptProposal", "Accept")}
                                                        </button>
                                                        <UncontrolledTooltip target={`btnAcceptProposeAction`} placement="bottom">
                                                            {this.contentTranslator(this.props.contentTranslations, "SfiaProfileTooltip", "SkillProfileButtonAcceptProposalTooltip", "Click here to Accept the changes and send a notification to person who proposed the changes")}
                                                        </UncontrolledTooltip>
                                                    </td>
                                                    <td valign="bottom">
                                                        <button id="btnRejectProposeAction" className="btn btn-light btnDanger-Style" style={buttonStyle} onClick={this.handleRejectActionProposal}>
                                                            {this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonRejectProposal", "Reject")}
                                                        </button>
                                                        <UncontrolledTooltip target={`btnRejectProposeAction`} placement="bottom">
                                                            {this.contentTranslator(this.props.contentTranslations, "SfiaProfileTooltip", "SkillProfileButtonRejectProposalTooltip", "Click here to Reject the changes and send a notification to person who proposed the changes")}
                                                        </UncontrolledTooltip>
                                                    </td>
                                                    <td valign="bottom">
                                                        <button id="btnCancelViewPropose" className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleCancelViewProposedAction}>
                                                            {this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonProposalCancel", "Cancel")}
                                                        </button>
                                                        <UncontrolledTooltip target={`btnCancelViewPropose`} placement="bottom">
                                                            {this.contentTranslator(this.props.contentTranslations, "SfiaProfileTooltip", "SkillProfileButtonProposalCancelTooltip", "Click here to cancel and return to your profile WITHOUT accepting or rejecting the changes.")}
                                                        </UncontrolledTooltip>
                                                    </td>
                                                </tr>
                                            )}
                                            {showSuggestedUpdate && (
                                                <tr valign="top">
                                                    <td valign="bottom">
                                                        <button id="btnAcceptSuggestion" className="btn btn-light btnSuccess-Style" style={buttonStyle} onClick={this.handleAcceptUpdateProfileSuggestion}>
                                                            {this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonAcceptSuggestion", "Accept")}
                                                        </button>
                                                        <UncontrolledTooltip target={`btnAcceptSuggestion`} placement="bottom">
                                                            {this.contentTranslator(this.props.contentTranslations, "SfiaProfileTooltip", "SkillProfileButtonAcceptSuggestionTooltip", "Click here to Accept the changes")}
                                                        </UncontrolledTooltip>
                                                    </td>
                                                    <td valign="bottom">
                                                        <button id="btnRejectSuggestion" className="btn btn-light btnDanger-Style" style={buttonStyle} onClick={this.handleRejectUpdateProfileSuggestion}>
                                                            {this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonRejectSuggestion", "Reject")}
                                                        </button>
                                                        <UncontrolledTooltip target={`btnRejectSuggestion`} placement="bottom">
                                                            {this.contentTranslator(this.props.contentTranslations, "SfiaProfileTooltip", "SkillProfileButtonRejectSuggestionTooltip", "Click here to Reject the changes")}
                                                        </UncontrolledTooltip>
                                                    </td>
                                                    <td valign="bottom">
                                                        <button id="btnCancelViewSuggestion" className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleCancelSuggestedUpdate}>
                                                            {this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonSuggestionCancel", "Cancel")}
                                                        </button>
                                                        <UncontrolledTooltip target={`btnCancelViewSuggestion`} placement="bottom">
                                                            {this.contentTranslator(this.props.contentTranslations, "SfiaProfileTooltip", "SkillProfileButtonSuggestionCancelTooltip", "Click here to cancel and return to your profile WITHOUT accepting or rejecting the changes.")}
                                                        </UncontrolledTooltip>
                                                    </td>
                                                </tr>
                                            )}
                                        </table>
                                    </Col>
                                )}
                            </Row>
                        </Container>
                    )}
                    {this.props.badgeCertification && (
                        <Container className="p-0">
                            <Row className="justify-content-between">
                                {!this.state.loading && (
                                    <Col
                                        sm="12"
                                        style={{
                                            paddingTop: "20px",
                                            textAlign: "right",
                                        }}
                                    >
                                        <table align="right">
                                            <tr valign="top">
                                                {!showEditCertify && badgeRequest && badgeRequest.status != "Approved" && !badgeRequest.certificationSubmitted && (
                                                    <td valign="bottom">
                                                        <button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleTogleEditBadgeRequest} disabled={false}>
                                                            {this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonEditCertify", "Edit")}
                                                        </button>
                                                    </td>
                                                )}
                                                {showEditCertify && (
                                                    <React.Fragment>
                                                        <td valign="bottom">
                                                            <button
                                                                className="btn btn-light btnSecondary-Style"
                                                                style={buttonStyle}
                                                                onClick={() => this.handleSaveBadgeRequest()}
                                                                disabled={updateBadgeRequest || !(badgeRequest && badgeRequest.requestCertificationEntries && badgeRequest.requestCertificationEntries.filter((it) => it.edited).length > 0)}
                                                            >
                                                                <ContentTranslator page="SfiaProfile" name="SkillProfileButtonSave" contentText="Save" />
                                                            </button>
                                                        </td>
                                                        {badgeRequest && badgeRequest.requestCertificationEntries && badgeRequest.requestCertificationEntries.filter((it) => it.id).length > 0 && (
                                                            <td valign="bottom">
                                                                <button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleTogleEditBadgeRequest} disabled={updateBadgeRequest}>
                                                                    {this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonCancel", "Cancel")}
                                                                </button>
                                                            </td>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                                {badgeRequest && badgeRequest.status != "Approved" && !badgeRequest.certificationSubmitted && badgeRequest.requestCertificationEntries && badgeRequest.requestCertificationEntries.filter((it) => it.id).length > 0 && (
                                                    <td valign="bottom">
                                                        <button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleApproveBadgeRequest} disabled={updateBadgeRequest || showEditCertify}>
                                                            <ContentTranslator page="SfiaProfile" name="SkillProfileButtonApproveCertify" contentText="Approve" />
                                                        </button>
                                                    </td>
                                                )}
                                                {/* {badgeRequest && !badgeRequest.certificationSubmitted && badgeRequest.requestCertificationEntries && badgeRequest.requestCertificationEntries.filter((it) => it.id).length > 0 && (
                                                    <td valign="bottom">
                                                        <button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.togleSubmitBadgesConfirmation} disabled={updateBadgeRequest || showEditCertify}>
                                                            <ContentTranslator page="SfiaProfile" name="SkillProfileButtonSubmitCertify" contentText="Submit for Badges" />
                                                        </button>
                                                    </td>
                                                )} */}
                                                {badgeRequest && badgeRequest.certificationSubmitted && (
                                                    <td valign="bottom">
                                                        <button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.closeWindow}>
                                                            <ContentTranslator page="SfiaProfile" name="SkillProfileButtonCloseCertify" contentText="Close" />
                                                        </button>
                                                    </td>
                                                )}
                                                <td valign="bottom">
                                                    <button className="btn btn-light btnSecondary-Style" style={buttonStyle} onClick={this.handleChangeShowDetail} disabled={this.state.showEdit}>
                                                        {this.state.showDetail || this.state.showEdit
                                                            ? this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonCertifyShowLess", "Show Less")
                                                            : this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SkillProfileButtonCertifyShowAll", "Show All")}
                                                    </button>
                                                </td>
                                            </tr>
                                        </table>
                                    </Col>
                                )}
                            </Row>
                        </Container>
                    )}
                    {this.state.loading ? (
                        <div style={{ textAlign: "center", paddingTop: "10px" }}>
                            <Spinner animation="border" role="status" style={{ marginLeft: "20px" }}></Spinner>
                        </div>
                    ) : (
                        <TableProfile
                            loading={this.state.loading}
                            showHidden={this.state.showHidden}
                            showDetail={this.state.showDetail}
                            showEdit={this.state.showEdit}
                            showProposedAction={this.state.showProposedAction}
                            showSuggestedUpdate={this.state.showSuggestedUpdate}
                            lorLoading={this.state.lorLoading}
                            lors={this.state.lors}
                            selectedJobMatchType={this.state.selectedJobMatchType}
                            selectedprofileType={this.state.selectedprofileType}
                            loadingGap={this.state.loadingGap}
                            highlightGap={this.state.highlightGap}
                            jobLorProfiles={this.state.jobLorProfiles}
                            jobSkillProfiles={this.state.jobSkillProfiles}
                            jobSkillAttributes={this.state.jobSkillAttributes}
                            lorProfiles={this.state.lorProfiles}
                            handleEditProfileSkill={this.handleEditProfileSkill}
                            handleEditProfileSkills={this.handleEditProfileSkills}
                            skillLoading={this.state.skillLoading}
                            categories={this.state.categories}
                            extraFrameworkCapabilities={this.state.extraFrameworkCapabilities}
                            extraFrameworkLevels={this.state.extraFrameworkLevels}
                            skillProfiles={this.state.skillProfiles}
                            badgeCertification={this.props.badgeCertification}
                            badgeRequest={this.state.badgeRequest}
                            maxNumberOfBadgeRequestSkill={this.props.maxNumberOfBadgeRequestSkill}
                            badgeRequestUpdated={this.badgeRequestUpdated}
                            showEditCertify={this.state.showEditCertify}
                            updateFloatingLegendStatus={this.updateFloatingLegendStatus}
                            handleSuggestEditLowerLevelProfile={this.handleSuggestEditLowerLevelProfile}
                            handleManagerSuggestEditLowerLevelProfile={this.handleManagerSuggestEditLowerLevelProfile}
                        ></TableProfile>
                    )}
                </ContentWrapperLg>
                <ContentWrapperLg className="contentWrapperLg-Style-Sticky fixed-top" style={{ display: this.state.showFloatingLegend ? "block" : "none" }}>
                    <Container className="p-0" style={{ fontSize: "11px", background: "#FFFFFF" }}>
                        <Table>
                            <tbody>
                                <tr>
                                    <td className="pl-0 pb-1" style={{ border: "0px", fontFamily: "IBMPlexSans-Bold" }} colSpan="5">
                                        <ContentTranslator page="SfiaProfile" name="SkillProfileLegendKey" contentText="Skills Profile Key" />
                                    </td>
                                    <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                        <span id="profileLegendNotSelectedFloat">
                                            <ContentTranslator page="SfiaProfile" name="SkillProfileLegendNotSelected" contentText="Not Selected" />
                                        </span>
                                        <UncontrolledTooltip target={`profileLegendNotSelectedFloat`} placement="bottom">
                                            <ContentTranslator page="SfiaProfile" name="SkillProfileLegendNotSelectedHover" contentText="This indicates no capability was selected for this skill" />
                                        </UncontrolledTooltip>
                                    </td>
                                    <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                        <span id="profileLegendKnowledgeFloat">
                                            <ContentTranslator page="SfiaProfile" name="SkillProfileLegendKnowledge" contentText="Knowledge" />
                                        </span>
                                        <UncontrolledTooltip target={`profileLegendKnowledgeFloat`} placement="bottom">
                                            <ContentTranslator
                                                page="SfiaProfile"
                                                name="SkillProfileKeyKnowledgeHover"
                                                contentText="This indicates relevant KNOWLEDGE to explain how this would be applied in a working environment but have not yet had the opportunity to develop full proficiency or competency. Or it can indicate previous experience of applying this skill, but it is no longer current."
                                            />
                                        </UncontrolledTooltip>
                                    </td>
                                    <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                        <span id="profileLegendProficientFloat">
                                            <ContentTranslator page="SfiaProfile" name="SkillProfileLegendProficient" contentText="Proficient" />
                                        </span>
                                        <UncontrolledTooltip target={`profileLegendProficientFloat`} placement="bottom">
                                            <ContentTranslator
                                                page="SfiaProfile"
                                                name="SkillProfileKeyProficientHover"
                                                contentText="This indicates that 50% to 85% of the activities described have been recently and regularly performed, with consistently successful results. However, there is still room for improvement."
                                            />
                                        </UncontrolledTooltip>
                                    </td>
                                    <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                        <span id="profileLegendCompetentFloat">
                                            <ContentTranslator page="SfiaProfile" name="SkillProfileLegendCompetent" contentText="Competent" />
                                        </span>
                                        <UncontrolledTooltip target={`profileLegendCompetentFloat`} placement="bottom">
                                            <ContentTranslator
                                                page="SfiaProfile"
                                                name="SkillProfileKeyCompetentHover"
                                                contentText="This indicates significant professional experience of performing at least 85% of the activities described and is considered a current and fully developed COMPETENCY with no further development required."
                                            />
                                        </UncontrolledTooltip>
                                    </td>
                                    {this.state.selectedJobMatchType && this.state.selectedJobMatchType.value != "None" && (
                                        <React.Fragment>
                                            <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                                <ContentTranslator page="SfiaAll" name="JobRequired" contentText="Required" />
                                            </td>
                                            <td className="pl-0 pb-1 td-fixwidth" style={{ border: "0px" }}>
                                                <ContentTranslator page="SfiaAll" name="JobDesirable" contentText="Desirable" />
                                            </td>
                                        </React.Fragment>
                                    )}
                                </tr>
                                <tr>
                                    <td colSpan="5" style={{ background: "#F5F5F5", border: "0px" }}></td>
                                    <td id="tdProfileLegendNotSelected" style={{ background: "#E6E6E6", border: "0px" }}></td>
                                    <UncontrolledTooltip target={`tdProfileLegendNotSelected`} placement="bottom">
                                        <ContentTranslator page="SfiaProfile" name="SkillProfileLegendNotSelectedHover" contentText="This indicates no capability was selected for this skill" />
                                    </UncontrolledTooltip>
                                    <td id="tdProfileLegendKnowledge" className="col-legend-previous border-0"></td>
                                    <UncontrolledTooltip target={`tdProfileLegendKnowledge`} placement="bottom">
                                        <ContentTranslator
                                            page="SfiaProfile"
                                            name="SkillProfileKeyKnowledgeHover"
                                            contentText="This indicates relevant KNOWLEDGE to explain how this would be applied in a working environment but have not yet had the opportunity to develop full proficiency or competency. Or it can indicate previous experience of applying this skill, but it is no longer current."
                                        />
                                    </UncontrolledTooltip>
                                    <td id="tdProfileLegendProficient" className="col-legend-largely border-0"></td>
                                    <UncontrolledTooltip target={`tdProfileLegendProficient`} placement="bottom">
                                        <ContentTranslator
                                            page="SfiaProfile"
                                            name="SkillProfileKeyProficientHover"
                                            contentText="This indicates that 50% to 85% of the activities described have been recently and regularly performed, with consistently successful results. However, there is still room for improvement."
                                        />
                                    </UncontrolledTooltip>
                                    <td id="tdProfileLegendCompetent" style={{ background: "#AAACAC", border: "0px" }}></td>
                                    <UncontrolledTooltip target={`tdProfileLegendCompetent`} placement="bottom">
                                        <ContentTranslator
                                            page="SfiaProfile"
                                            name="SkillProfileKeyCompetentHover"
                                            contentText="This indicates significant professional experience of performing at least 85% of the activities described and is considered a current and fully developed COMPETENCY with no further development required."
                                        />
                                    </UncontrolledTooltip>
                                    {this.state.selectedJobMatchType && this.state.selectedJobMatchType.value == "Current" && (
                                        <React.Fragment>
                                            <td style={{ background: "#488ac7", border: "0px" }}></td>
                                            <td className="col-legend-desirable-current border-0"></td>
                                        </React.Fragment>
                                    )}
                                    {this.state.selectedJobMatchType && this.state.selectedJobMatchType.value == "Future" && (
                                        <React.Fragment>
                                            <td style={{ background: "#6cbb3c", border: "0px" }}></td>
                                            <td className="col-legend-desirable-future border-0"></td>
                                        </React.Fragment>
                                    )}
                                </tr>
                            </tbody>
                        </Table>
                    </Container>
                </ContentWrapperLg>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    let showApprovedProfileOnly = localStorage.getItem("showApprovedProfileOnly");
    let profileType = localStorage.getItem("profileType");

    return {
        jobMatchTypes: state.job.jobMatchTypes,

        profileTypes: state.skillProfile.profileTypes,
        profileVersions: state.skillProfile.profileVersions ? (showApprovedProfileOnly && showApprovedProfileOnly == "TRUE" && profileType == "Self Assessed" ? state.skillProfile.profileVersions.filter((it) => it.approved == true) : state.skillProfile.profileVersions) : null,
        lorProfileLoading: state.skillProfile.loading,
        lorProfileError: state.skillProfile.error,
        lorProfiles: state.skillProfile.lorProfiles,
        lorLoading: state.lor.loading,
        lorError: state.lor.error,
        lors: state.lor.lors,

        skillProfileLoading: state.skillProfile.loading,
        skillProfileError: state.skillProfile.error,
        skillProfiles: state.skillProfile.skillProfiles,

        loadingPrint: state.skillProfile.loadingPrint,
        printData: state.skillProfile.printData,

        categoryLoading: state.category.loading,
        categoryError: state.category.error,
        extraFrameworkCapabilities: state.category.extraFrameworkCapabilities,
        extraFrameworkLevels: state.category.extraFrameworkLevels,
        categories: state.category.categories,

        profileUpdating: state.skillProfile.profileUpdating,
        profileUpdatingSuccess: state.skillProfile.profileUpdatingSuccess,
        profileUpdatingError: state.skillProfile.profileUpdatingError,
        udpatedSkills: state.skillProfile.udpatedSkills,

        updating: state.skillProfile.updating,
        error: state.skillProfile.error,

        updateEndorsedStatus: state.skillProfile.updateEndorsedStatus,
        updateEndorsedStatusError: state.skillProfile.updateEndorsedStatusError,
        updatedPersonEndorsement: state.skillProfile.updatedPersonEndorsement,

        endorsedProfileCopying: state.skillProfile.endorsedProfileCopying,
        endorsedProfileCopyingSuccess: state.skillProfile.endorsedProfileCopyingSuccess,
        endorsedProfileCopyingError: state.skillProfile.endorsedProfileCopyingError,

        copyProfile: state.skillProfile.copyProfile,
        copyProfileError: state.skillProfile.copyProfileError,

        jobGaps: state.job.jobGaps,
        jobLoading: state.job.jobMatchesLoading,
        jobError: state.job.error,
        jobMatches: state.job.jobMatches,
        jobLorProfiles: state.job.jobLorProfiles,
        jobSkillProfiles: state.job.jobSkillProfiles,
        jobSkillAttributes: state.job.jobSkillAttributes,
        jobMatchesUpdated: state.job.jobMatchesUpdated,

        personLoading: state.person.loading,
        personError: state.person.error,
        personUpdating: state.person.updating,
        account: state.person.account,
        person: state.person.person,
        loadingExport: state.person.loadingExport,
        exportData: state.person.exportData,

        managedPeople: state.auth.managedPeople,
        contentTranslations: state.content.contentTranslations,
        isEndorser: state.auth.isEndorser,
        manageEndorsements: state.auth.manageEndorsements,
        manageSelfAssessed: state.auth.manageSelfAssessed,
        manageSelfProfiles: state.auth.manageSelfProfiles,
        jobProfileReportFormat: state.auth.jobProfileReportFormat,
        selfAssessedProfileReportFormat: state.auth.selfAssessedProfileReportFormat,
        allowSkipSurvey: state.auth.allowSkipSurvey,

        currAssessment: state.config.currAssessment,
        badgeRequestError: state.adminBadgeRequest.error,
        badgeRequestUpdating: state.adminBadgeRequest.isUpdating,
        updatedBadgeRequest: state.adminBadgeRequest.updatedBadgeRequest,

        actionPlanUpdating: state.actionPlan.actionPlanUpdating,
        actionPlanUpdatingError: state.actionPlan.actionPlanUpdatingError,

        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSkillProfile: bindActionCreators(skillProfileActions, dispatch),
        onLor: bindActionCreators(lorActions, dispatch),
        onCategory: bindActionCreators(categoryActions, dispatch),
        onJob: bindActionCreators(jobActions, dispatch),
        onPerson: bindActionCreators(personActions, dispatch),
        onAdminPerson: bindActionCreators(adminPersonActions, dispatch),
        onAdminBadgeRequest: bindActionCreators(adminBadgeRequestActions, dispatch),
        onActionPlan: bindActionCreators(actionPlanActions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SfiaSkills));
