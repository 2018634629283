import * as evidenceActions from "../../store/actions/evidenceAction";
import changeInput from "../../utils/changeInput";
import { parameterizedString } from "../../utils/stringUtils";
import { contentTranslator } from "../../utils/translatorUtils";
import { ContentWrapperLg } from "../common/ContentWrapperLg";
import ContentTranslator from "../translator/ContentTranslator";
import { faArrowDown, faArrowUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactTextareaAutosize from "react-textarea-autosize";
import Combobox from "react-widgets/Combobox";
import { Button, Col, Container, CustomInput, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const forbiddenExtensions = ["asp", "aspx", "axd", "asx", "asmx", "ashx", "shtml", "mhtml", "xhtml", "mht", "scr", "lnk", "msi", "msp", "ps1", "reg", "vb", "vbs", "hta", "ws", "dll", "exe"];

class EvidenceList extends React.Component {
    static displayName = EvidenceList.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showEditEvidence: false,
            submitInProgress: false,
            editedEvidence: null,
            form: {
                id: {
                    validation: {
                        required: true,
                    },
                    value: null,
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                label: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                evidenceTypeId: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                evidenceType: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                completedOn: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                expiryOn: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                supplier: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                link: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                text: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                notes: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            selectedSkills: [],
            selectedLors: [],
            evidenceOptions: [],
            evidenceOption: "Upload File",
            fileName: null,
            fileData: null,
            selectedSortOption: "label",
            selectedSortOptionDirection: "asc",
            optionSorts: [
                {
                    label: "Label",
                    field: "label",
                    selected: true,
                },
                {
                    label: "Type",
                    field: "evidenceType",
                },
                {
                    label: "Expiry Date",
                    field: "expiryOn",
                },
            ],
        };

        this.contentTranslator = contentTranslator.bind(this);
        this.parameterizedString = parameterizedString.bind(this);
    }

    componentDidMount() {
        const evidenceOptions = [
            { value: "Upload File", label: this.contentTranslator(this.props.contentTranslations, "SfiaEvidence", "SfiaEvidenceUploadFile", "Upload File") },
            { value: "Specify Link", label: this.contentTranslator(this.props.contentTranslations, "SfiaEvidence", "SfiaEvidenceSpecifyLink", "Specify Link") },
            { value: "Description in the Notes", label: this.contentTranslator(this.props.contentTranslations, "SfiaEvidence", "SfiaEvidenceDescriptionNotes", "Description in the Notes") },
        ];

        this.setState({ evidenceOptions });
    }

    handleChangeSortOption = (event) => {
        const { name, value } = event.target;
        const { optionSorts } = this.state;
        optionSorts.forEach((option) => {
            if (option.field == value) {
                option.selected = true;
            } else {
                option.selected = false;
            }
        });

        this.setState({
            selectedSortOption: value,
            optionSorts,
        });
    };

    handleChangeSortDirection = () => {
        const { selectedSortOptionDirection } = this.state;

        this.setState({
            selectedSortOptionDirection: selectedSortOptionDirection == "asc" ? "desc" : "asc",
        });
    };

    sortActions = (actions) => {
        const { selectedSortOption, selectedSortOptionDirection } = this.state;

        if (selectedSortOption == "expiryOn") {
            if (selectedSortOptionDirection == "asc") {
                actions = actions.sort((a, b) => new Date(a.expiryOn) - new Date(b.expiryOn));
            } else {
                actions = actions.sort((b, a) => new Date(a.expiryOn) - new Date(b.expiryOn));
            }
        } else {
            actions = _.orderBy(actions, selectedSortOption, selectedSortOptionDirection);
        }

        return actions;
    };

    renderEvidenceList = () => {
        let isAdmin = false;

        const authMode = this.props.authMode;
        let authModes = authMode.split(",").map((item) => item.trim());
        if (authModes.filter((it) => it != "Admin").length == 0) {
            isAdmin = true;
        }

        let evidences = this.props.evidences;
        evidences = this.sortActions(evidences);

        const optionSorts = [
            {
                label: this.contentTranslator(this.props.contentTranslations, "SfiaEvidence", "SfiaEvidenceSortByLabel", "Label"),
                field: "label",
                selected: true,
            },
            {
                label: this.contentTranslator(this.props.contentTranslations, "SfiaEvidence", "SfiaEvidenceSortByType", "Type"),
                field: "evidenceType",
            },
            {
                label: this.contentTranslator(this.props.contentTranslations, "SfiaEvidence", "SfiaEvidenceSortByCreatedDate", "Created Date"),
                field: "createdOn",
            },
            {
                label: this.contentTranslator(this.props.contentTranslations, "SfiaEvidence", "SfiaEvidenceSortByExpiryDate", "Expiry Date"),
                field: "expiryOn",
            },
        ];

        return (
            <React.Fragment>
                {evidences && evidences.length > 0 ? (
                    <React.Fragment>
                        <Container>
                            <Row style={{ padding: "10px 0px" }}>
                                <Col xs="12" md="6"></Col>
                                <Col xs="12" md="6">
                                    <Row style={{ background: "#FFFFFF", padding: "10px", borderRadius: "10px" }}>
                                        <Col xs="3">
                                            <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceSortBy" contentText="Sort by :" />
                                        </Col>
                                        <Col xs="7">
                                            <Input type="select" onChange={this.handleChangeSortOption} value={this.state.selectedSortOption}>
                                                {optionSorts.map((optionSort, index) => (
                                                    <option key={index} value={`${optionSort.field}`}>
                                                        {optionSort.label}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col>
                                        <Col xs="2">
                                            <Button onClick={() => this.handleChangeSortDirection()} className="btn btn-primary">
                                                <FontAwesomeIcon icon={this.state.selectedSortOptionDirection == "asc" ? faArrowDown : faArrowUp} />
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="6"></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                        {evidences.map((evidence, index) => {
                            const skillLevels = [
                                ...evidence.lorLevels.map((lorLevel) => {
                                    return lorLevel.lorCode + lorLevel.level;
                                }),
                                ...evidence.skillLevels.map((skillLevel) => {
                                    return skillLevel.skillCode + skillLevel.level;
                                }),
                            ];

                            return (
                                <ContentWrapperLg key={index}>
                                    <Container style={{ fontSize: "13px", fontFamily: "IBMPlexSans" }}>
                                        <Row>
                                            <Col className="p-0" style={{ color: "#000000" }}>
                                                <Row className="align-items-center">
                                                    <Col md="5">
                                                        {evidence.fileName ? (
                                                            <React.Fragment>
                                                                {this.props.managedPeople ? (
                                                                    <Link target="_blank" to={`Manage/Download?id=${evidence.id}&filename=${evidence.fileName}&email=${this.props.managedPeople.personB.email}`}>
                                                                        {evidence.label}
                                                                    </Link>
                                                                ) : (
                                                                    <Link target="_blank" to={`Download?id=${evidence.id}&filename=${evidence.fileName}`}>
                                                                        {evidence.label}
                                                                    </Link>
                                                                )}
                                                            </React.Fragment>
                                                        ) : evidence.link ? (
                                                            <Link target="_blank" to={{ pathname: evidence.link.includes("http") ? evidence.link : `//${evidence.link}` }}>
                                                                {evidence.label}
                                                            </Link>
                                                        ) : (
                                                            evidence.label
                                                        )}
                                                        {skillLevels.length > 0 && <br />}
                                                        {skillLevels.map((it, index) => {
                                                            return (index > 0 ? ", " : "") + it;
                                                        })}
                                                    </Col>
                                                    <Col md="2-5">
                                                        <span style={{ color: "#000000" }}>{evidence.evidenceType}</span>
                                                    </Col>
                                                    <Col md="2-5">
                                                        {evidence.createdOn && (
                                                            <React.Fragment>
                                                                <span style={{ color: "#777777" }}>
                                                                    <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceCreated" contentText="Created" />:{" "}
                                                                </span>
                                                                <span style={{ color: "#000000" }}>
                                                                    {new Intl.DateTimeFormat("en-GB", {
                                                                        year: "2-digit",
                                                                        month: "short",
                                                                        day: "numeric",
                                                                    }).format(new Date(evidence.createdOn))}
                                                                </span>
                                                                <br />
                                                            </React.Fragment>
                                                        )}
                                                        {evidence.completedOn && (
                                                            <React.Fragment>
                                                                <span style={{ color: "#777777" }}>
                                                                    <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceCompleted" contentText="Completed" />:{" "}
                                                                </span>
                                                                <span style={{ color: "#000000" }}>
                                                                    {new Intl.DateTimeFormat("en-GB", {
                                                                        year: "2-digit",
                                                                        month: "short",
                                                                        day: "numeric",
                                                                    }).format(new Date(evidence.completedOn))}
                                                                </span>
                                                                <br />
                                                            </React.Fragment>
                                                        )}
                                                        {evidence.expiryOn && (
                                                            <React.Fragment>
                                                                <span style={{ color: "#777777" }}>
                                                                    <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceExpiry" contentText="Expiry" />:{" "}
                                                                </span>
                                                                <span style={{ color: "#000000" }}>
                                                                    {new Intl.DateTimeFormat("en-GB", {
                                                                        year: "2-digit",
                                                                        month: "short",
                                                                        day: "numeric",
                                                                    }).format(new Date(evidence.expiryOn))}
                                                                </span>
                                                            </React.Fragment>
                                                        )}
                                                    </Col>
                                                    <Col md="2">
                                                        <button style={{ width: "81px", height: "32px", marginLeft: "10px" }} className="btn btn-light btnSecondary-Style" onClick={() => this.openEditEvidenceModal(evidence)} disabled={this.props.loadingEdit}>
                                                            {evidence.isDigitalBadge || evidence.isNonEditable || (evidence.isEditableToCreatorOnly && evidence.createdById != this.props.personId) ? (
                                                                <ContentTranslator page="SfiaAll" name="Show" contentText="Show" />
                                                            ) : (
                                                                <ContentTranslator page="SfiaAll" name="Edit" contentText="Edit" />
                                                            )}
                                                        </button>
                                                    </Col>
                                                </Row>
                                                {evidence.evidenceType == "Text" && (!evidence.notes || (evidence.notes && evidence.notes.length == 0)) && (
                                                    <Row className="align-items-center">
                                                        <Col md="10">
                                                            <br />
                                                            {evidence.text}
                                                        </Col>
                                                        <Col md="2"></Col>
                                                    </Row>
                                                )}
                                                {evidence.notes && evidence.notes.length > 0 && (
                                                    <Row className="align-items-center">
                                                        <Col md="10">
                                                            <br />
                                                            {evidence.notes}
                                                        </Col>
                                                        <Col md="2"></Col>
                                                    </Row>
                                                )}
                                                {evidence.isEditableToCreatorOnly && (
                                                    <Row className="align-items-center">
                                                        <Col md="10">
                                                            <br />
                                                            {`Evidence created by ${evidence.createdBy} on ${new Intl.DateTimeFormat("en-GB", {
                                                                year: "2-digit",
                                                                month: "short",
                                                                day: "numeric",
                                                            }).format(new Date(evidence.createdOn))}`}
                                                        </Col>
                                                        <Col md="2"></Col>
                                                    </Row>
                                                )}
                                            </Col>
                                        </Row>
                                    </Container>
                                </ContentWrapperLg>
                            );
                        })}
                    </React.Fragment>
                ) : (
                    <ContentWrapperLg>
                        <Container style={{ fontSize: "13px", fontFamily: "IBMPlexSans" }}>
                            <Row>
                                <Col className="p-0" style={{ color: "#000000" }}>
                                    <Row className="align-items-center">
                                        <Col md="12">
                                            <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceEmptyMessage" contentText="No evidence added" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </ContentWrapperLg>
                )}
            </React.Fragment>
        );
    };

    openEditEvidenceModal = (evidence) => {
        this.setState({ showEditEvidence: true, editedEvidence: evidence });

        let form = this.state.form;

        form.id.value = evidence.id;
        form.id.valid = true;
        form.id.isValidFormat = true;

        form.label.value = evidence.label;
        form.label.valid = true;
        form.label.isValidFormat = true;

        form.evidenceTypeId.value = evidence.evidenceTypeId;
        form.evidenceTypeId.valid = true;
        form.evidenceTypeId.isValidFormat = true;

        form.evidenceType.value = evidence.evidenceType;
        form.evidenceType.valid = true;
        form.evidenceType.isValidFormat = true;

        form.supplier.value = evidence.supplier;
        form.supplier.valid = true;
        form.supplier.isValidFormat = true;

        form.completedOn.value = evidence.completedOn;
        form.completedOn.valid = true;
        form.completedOn.isValidFormat = true;

        form.expiryOn.value = evidence.expiryOn;
        form.expiryOn.valid = true;
        form.expiryOn.isValidFormat = true;

        form.link.value = evidence.link;
        form.link.valid = true;
        form.link.isValidFormat = true;

        form.text.value = evidence.text;
        form.text.valid = true;
        form.text.isValidFormat = true;

        form.notes.value = evidence.evidenceType && evidence.evidenceType == "Text" && (!evidence.notes || (evidence.notes && evidence.notes.length == 0)) ? evidence.text : evidence.notes;
        form.notes.valid = true;
        form.notes.isValidFormat = true;

        const fileData = null;
        const fileName = evidence.fileName;

        let evidenceOption = "Upload File";

        if (fileName && fileName != "") {
            evidenceOption = "Upload File";
        } else if (evidence.link && evidence.link != "") {
            evidenceOption = "Specify Link";
        } else if (evidence.notes && evidence.notes != "") {
            evidenceOption = "Description in the Notes";
        } else if (evidence.evidenceType == "Text") {
            evidenceOption = "Description in the Notes";
        } else {
            evidenceOption = "Specify Link";
        }

        const selectedLors = evidence.lorLevels.map((lorLevel) => {
            return {
                id: lorLevel.id,
                lorLevelId: lorLevel.lorLevelId,
                lor: lorLevel.lor,
                lorCode: lorLevel.lorCode,
                level: lorLevel.level,
            };
        });

        const selectedSkills = evidence.skillLevels.map((skillLevel) => {
            return {
                id: skillLevel.id,
                skillLevelId: skillLevel.skillLevelId,
                skill: skillLevel.skill,
                skillCode: skillLevel.skillCode,
                level: skillLevel.level,
            };
        });

        this.setState({ form, isFormValid: true, selectedLors, selectedSkills, fileName, fileData, evidenceOption });
    };

    togleEditEvidenceModal = () => {
        const showEditEvidence = this.state.showEditEvidence;
        this.setState({ showEditEvidence: !showEditEvidence });
    };

    handleSubmitDelete = () => {
        const { editedEvidence } = this.state;

        if (window.confirm(`Are you sure?`)) {
            const deletedId = editedEvidence.id;
            const personEmail = this.props.managedPeople && this.props.managedPeople.personB.email ? this.props.managedPeople.personB.email : null;

            this.setState({ submitInProgress: true });
            Promise.resolve(this.props.managedPeople ? this.props.onEvidence.deleteManagedPeopleEvidence(deletedId, personEmail) : this.props.onEvidence.deleteEvidence(deletedId)).then(() => {
                if (!this.props.updateInProgress) {
                    if (this.props.error) {
                        this.props.generateAlert("danger", this.props.error.errData.Message);
                    } else {
                        this.setState({ showEditEvidence: false });
                        this.props.handleEvidenceDeleted(deletedId);
                    }
                }
                this.setState({ submitInProgress: this.props.updateInProgress });
            });
        }
    };

    handleChangeEvidenceOption = (evidenceOption) => {
        this.setState({ evidenceOption });

        let updateState = changeInput(this.state.form, "label", this.state.form.label.value);

        updateState.form.link.valid = true;
        updateState.form.link.isValidFormat = true;

        updateState.form.notes.valid = true;
        updateState.form.notes.isValidFormat = true;

        if (evidenceOption == "Specify Link") {
            updateState = changeInput(updateState.form, "link", updateState.form.link.value);
        } else if (evidenceOption == "Description in the Notes") {
            updateState = changeInput(updateState.form, "notes", updateState.form.notes.value);
        }

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handleChange = (event) => {
        const { name, value } = event.target;

        let updateState = changeInput(this.state.form, name, value);

        if (name == "evidenceTypeId") {
            const evidenceType = this.props.evidenceTypes.find((it) => it.id == value);
            if (evidenceType) {
                updateState = changeInput(updateState.form, "evidenceType", evidenceType.name);

                if (this.state.evidenceOption == "Specify Link") {
                    if (updateState.form.link.value == "" || updateState.form.link.value == null) {
                        updateState.form.link.valid = false;
                        updateState.form.link.isValidFormat = false;

                        updateState.valid = false;
                    }
                } else if (this.state.evidenceOption == "Description in the Notes") {
                    if (updateState.form.notes.value == "" || updateState.form.notes.value == null) {
                        updateState.form.notes.valid = false;
                        updateState.form.notes.isValidFormat = false;

                        updateState.valid = false;
                    }
                } else if (this.state.fileData != "") {
                    updateState.valid = true;
                }
            }
        } else if (name == "notes") {
            if (this.state.evidenceOption == "Description in the Notes") {
                if (updateState.form.notes.value == "" || updateState.form.notes.value == null) {
                    updateState.form.notes.valid = false;
                    updateState.form.notes.isValidFormat = false;

                    updateState.valid = false;
                }
            }
        } else if (name == "link") {
            if (this.state.evidenceOption == "Specify Link") {
                if (updateState.form.link.value == "" || updateState.form.link.value == null) {
                    updateState.form.link.valid = false;
                    updateState.form.link.isValidFormat = false;

                    updateState.valid = false;
                }
            }
        }

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handleChangeDate(name, value) {
        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    }

    handleSkillCheckedChange = (skill, skillCode, level) => {
        let selectedSkills = this.state.selectedSkills;
        const { categories } = this.props;

        const selectedSkill = {
            skillLevelId: null,
            skill,
            skillCode,
            level,
        };

        if (selectedSkills.find((it) => it.skill == skill && it.skillCode == skillCode && it.level == level)) {
            const unSelectedSkill = selectedSkills.find((it) => it.skill == skill && it.skillCode == skillCode && it.level == level);
            // const index = selectedSkills.indexOf(unSelectedSkill);
            // if (index !== -1) {
            //     selectedSkills.splice(index, 1);
            // }

            if (unSelectedSkill.removed) {
                unSelectedSkill.removed = false;
            } else {
                unSelectedSkill.removed = true;
            }

            this.setState({ selectedSkills });
        } else {
            const category = categories.find((it) => it.subCategories.some((subCategory) => subCategory.skills.some((it) => it.name == skill && it.skillsCode == skillCode)));
            const categorySubCategory = category && category.subCategories.find((it) => it.skills.some((it) => it.name == skill && it.skillsCode == skillCode));
            const categorySkill = categorySubCategory && categorySubCategory.skills.find((it) => it.name == skill && it.skillsCode == skillCode);
            const skillLevel = categorySkill && categorySkill.levels.find((it) => it.level == level);

            if (skillLevel) {
                selectedSkill.skillLevelId = skillLevel.id;
            }

            this.setState({ selectedSkills: [...selectedSkills, selectedSkill] });
        }
    };

    handlelorCheckedChange = (lor, lorCode, level) => {
        let selectedLors = this.state.selectedLors;
        const { lors } = this.props;

        const selectedLor = {
            lorLevelId: null,
            lor,
            lorCode,
            level,
        };

        if (selectedLors.find((it) => it.lor == lor && it.lorCode == lorCode && it.level == level)) {
            const unSelectedLor = selectedLors.find((it) => it.lor == lor && it.lorCode == lorCode && it.level == level);
            // const index = selectedLors.indexOf(unSelectedLor);
            // if (index !== -1) {
            //     selectedLors.splice(index, 1);
            // }

            if (unSelectedLor.removed) {
                unSelectedLor.removed = false;
            } else {
                unSelectedLor.removed = true;
            }

            this.setState({ selectedLors });
        } else {
            const lorLevel = lors.find((it) => it.name == lor && it.lorCode == lorCode).levels.find((it) => it.levelNumber == level);

            if (lorLevel) {
                selectedLor.lorLevelId = lorLevel.id;
            }

            this.setState({ selectedLors: [...selectedLors, selectedLor] });
        }
    };

    handleSubmitEdit = () => {
        const { form, evidenceOption, selectedSkills, selectedLors, editedEvidence } = this.state;

        let fileName = null;

        if (this.state.fileData) {
            fileName = this.state.fileName;
        } else if (evidenceOption == "Specify Link" || evidenceOption == "Description in the Notes") {
            fileName = null;
        } else {
            fileName = editedEvidence.fileName;
        }

        const evidence = {
            personEmail: this.props.managedPeople && this.props.managedPeople.personB.email,
            evidenceTypeId: form.evidenceTypeId.value,
            evidenceType: form.evidenceType.value,
            label: form.label.value,
            supplier: form.supplier.value,
            completedOn: form.completedOn.value,
            expiryOn: form.expiryOn.value,
            link: evidenceOption == "Specify Link" ? form.link.value : null,
            fileName: fileName,
            fileData: this.state.fileData,
            lorLevels: selectedLors.filter((it) => !it.removed),
            skillLevels: selectedSkills.filter((it) => !it.removed),
            text: form.text.value,
            notes: form.notes.value,
        };

        this.setState({ submitInProgress: true });
        Promise.resolve(this.props.managedPeople ? this.props.onEvidence.updateManagedPeopleEvidence(form.id.value, evidence) : this.props.onEvidence.updateEvidence(form.id.value, evidence)).then(() => {
            if (!this.props.updateInProgress) {
                if (this.props.error) {
                    this.props.generateAlert("danger", this.props.error.errData.Message);
                } else {
                    this.setState({ showEditEvidence: false });
                    this.props.handleEvidenceUpdated(this.props.updatedEvidence);
                }
            }
            this.setState({ submitInProgress: this.props.updateInProgress });
        });
    };

    setEvidenceFile = (evidenceFile) => {
        const extension = evidenceFile.name.split(".").pop().toLowerCase();
        toBase64(evidenceFile).then((result) => {
            const fileData = result.split(",")[1];
            this.setState({ fileName: evidenceFile.name, fileData, fileExtension: extension });
        });

        this.handleChange({
            target: {
                name: "evidenceTypeId",
                value: this.state.form.evidenceTypeId.value,
            },
        });
    };

    renderEditEvidenceDialog() {
        let isAdmin = false;

        const authMode = this.props.authMode;
        let authModes = authMode.split(",").map((item) => item.trim());
        if (authModes.filter((it) => it != "Admin").length == 0) {
            isAdmin = true;
        }

        const { form, selectedSkills, selectedLors, editedEvidence } = this.state;
        const { evidences, skillProfiles, lorProfiles, lors, categories, showHiddenSkills } = this.props;

        const availableSkillCodes = categories
            ? categories.flatMap((category, index) => {
                  return category.subCategories.flatMap((subCategory, index) => {
                      return subCategory.skills
                          .filter((it) => !it.isHidden)
                          .flatMap((skill, index) => {
                              return skill.skillsCode;
                          });
                  });
              })
            : [];

        let evidencesSkills = evidences.map((evidence) => {
            return {
                id: evidence.id,
                skills: [
                    ...evidence.lorLevels.map((lor) => {
                        return lor.lorCode + lor.level;
                    }),
                    ...evidence.skillLevels.map((lor) => {
                        return lor.skillCode + lor.level;
                    }),
                ],
            };
        });

        const showOnly = editedEvidence && (editedEvidence.isDigitalBadge || editedEvidence.isNonEditable || (editedEvidence.isEditableToCreatorOnly && editedEvidence.createdById != this.props.personId)) ? true : false;

        return (
            <Modal isOpen={this.state.showEditEvidence} toggle={this.togleEditEvidenceModal} size="lg">
                <ModalHeader toggle={this.togleEditEvidenceModal}>
                    <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceEditTitle" contentText="Edit Evidence" />
                </ModalHeader>
                <ModalBody>
                    {this.state.submitInProgress ? (
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <Spinner size="lg" animation="border" role="status"></Spinner>
                        </div>
                    ) : (
                        <Form>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceLabel" contentText="Label" />
                                </Label>
                                <Col sm={9}>
                                    <Input type="text" name="label" id={"label"} value={form.label.value} invalid={form.label.touched && !form.label.valid} readOnly={showOnly} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceType" contentText="Type" />
                                </Label>
                                <Col sm={9}>
                                    <Combobox
                                        onSelect={(value) => {
                                            this.handleChange({
                                                target: {
                                                    name: "evidenceTypeId",
                                                    value: value.id,
                                                },
                                            });
                                        }}
                                        data={this.props.evidenceTypes}
                                        textField="name"
                                        dataKey="name"
                                        value={this.props.evidenceTypes.find((it) => it.id == form.evidenceTypeId.value)}
                                        defaultValue={this.props.evidenceTypes.find((it) => it.id == form.evidenceTypeId.value)}
                                        style={{ fontSize: "14px", fontFamily: "IBMPlexSans" }}
                                        readOnly={showOnly}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceSupplier" contentText="Supplier" />
                                </Label>
                                <Col sm={9}>
                                    <Input type="text" name="supplier" id={"supplier"} value={form.supplier.value} readOnly={showOnly} onChange={this.handleChange} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceCompletedOn" contentText="Completed On" />
                                </Label>
                                <Col sm={9}>
                                    <DatePicker selected={form.completedOn.value ? new Date(form.completedOn.value) : null} dateFormat="dd/MM/yyyy" onChange={(value) => this.handleChangeDate("completedOn", value)} className="form-control" disabled={showOnly} isClearable={!showOnly} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceExpiresOn" contentText="Expires On" />
                                </Label>
                                <Col sm={9}>
                                    <DatePicker selected={form.expiryOn.value ? new Date(form.expiryOn.value) : null} dateFormat="dd/MM/yyyy" onChange={(value) => this.handleChangeDate("expiryOn", value)} className="form-control" disabled={showOnly} isClearable={!showOnly} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceEvidence" contentText="Evidence" />
                                </Label>
                                <Col sm={9}>
                                    <Combobox
                                        data={this.state.evidenceOptions}
                                        onSelect={(value) => {
                                            this.handleChangeEvidenceOption(value.value);
                                        }}
                                        onChange={() => {}}
                                        textField="label"
                                        dataKey="value"
                                        defaultValue={this.state.evidenceOption}
                                        style={{ fontSize: "14px", fontFamily: "IBMPlexSans" }}
                                        readOnly={showOnly}
                                    />
                                </Col>
                            </FormGroup>
                            {this.state.evidenceOption == "Specify Link" && (
                                <FormGroup row>
                                    <Label sm={3} style={{ textAlign: "right" }}>
                                        <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceLink" contentText="Link" />
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" name="link" id={"link"} value={form.link.value} invalid={form.link.touched && !form.link.valid} onChange={this.handleChange} readOnly={showOnly} />
                                    </Col>
                                </FormGroup>
                            )}
                            <FormGroup row style={{ display: this.state.evidenceOption == "Upload File" ? "" : "none" }}>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceFile" contentText="Evidence File" />
                                </Label>
                                <Col sm={9}>
                                    {!this.state.fileData && this.state.fileName && this.state.fileName != "" && (
                                        <Label className="col-form-label" style={{ wordWrap: "break-word", width: "100%" }}>
                                            {this.state.fileName}
                                        </Label>
                                    )}
                                    <Input type="file" name="file" id="EvidenceFile" onChange={(e) => this.setEvidenceFile(e.target.files[0])} readOnly={showOnly} />
                                    <UncontrolledTooltip target={`EvidenceFile`} placement="bottom" autohide={false}>
                                        <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceFileTooltip" contentText="Choose the Evidence file" />
                                    </UncontrolledTooltip>
                                    {this.state.fileData && this.state.fileExtension && forbiddenExtensions.includes(this.state.fileExtension) && <span style={{ color: "#ff0000", fontSize: "12px" }}>For security this file type is not allowed</span>}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceNotes" contentText="Notes" />
                                </Label>
                                <Col sm={9}>
                                    <ReactTextareaAutosize
                                        minRows={5}
                                        style={{ width: "100%", padding: "10px", border: form.notes.touched && !form.notes.valid ? "1px solid #dc3545" : "1px solid #ced4da" }}
                                        aria-label="maximum height"
                                        value={form.notes.value ? form.notes.value : ""}
                                        onChange={this.handleChange}
                                        disabled={this.state.isUpdating}
                                        name="notes"
                                        id="notes"
                                        readOnly={showOnly}
                                        invalid={form.notes.touched && !form.notes.valid}
                                    />
                                    {this.state.evidenceOption == "Description in the Notes" && form.notes.touched && form.notes.value && (form.notes.value.length < 30 || form.notes.value.length > 5000) && <span style={{ color: "#ff0000", fontSize: "12px" }}>Min 30, max 5000 characters</span>}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceApplicableGAs" contentText="Applicable GAs" />
                                </Label>
                                <Col sm={9}>
                                    {lorProfiles &&
                                        lorProfiles
                                            .filter((lorProfile) => (!showOnly ? lorProfile : !!selectedLors.find((it) => !it.removed && it.lorCode == lorProfile.lorCode && it.level == lorProfile.level)))
                                            .sort((a, b) => `${a.skillCode}${a.level}` > `${b.skillCode}${b.level}`)
                                            .map((lorProfile, index) => {
                                                const lor = lors.find((it) => it.lorCode == lorProfile.lorCode);
                                                const lorLevel = lor ? lor.levels.find((x) => x.levelNumber == lorProfile.level) : null;

                                                return (
                                                    <React.Fragment key={index}>
                                                        <div style={{ float: "left" }} id={`divChkLor${lorProfile.id}${lorProfile.lorCode}${lorProfile.level}`}>
                                                            <CustomInput
                                                                type="checkbox"
                                                                className="custom-200"
                                                                name={`chkLor${lorProfile.id}${lorProfile.lorCode}${lorProfile.level}`}
                                                                id={`chkLor${lorProfile.id}${lorProfile.lorCode}${lorProfile.level}`}
                                                                checked={!!selectedLors.find((it) => !it.removed && it.lorCode == lorProfile.lorCode && it.level == lorProfile.level)}
                                                                label={
                                                                    <div style={{ paddingTop: "0px", marginTop: "-4px" }}>
                                                                        {lorProfile.lorCode}
                                                                        {lorProfile.level} - {this.contentTranslator(this.props.contentTranslations, "Lors", lorProfile.lor, lorProfile.lor)} {lorProfile.level}
                                                                    </div>
                                                                }
                                                                onChange={() => this.handlelorCheckedChange(lorProfile.lor, lorProfile.lorCode, lorProfile.level)}
                                                                disabled={showOnly}
                                                                inline
                                                            />
                                                        </div>
                                                        <UncontrolledTooltip target={`divChkLor${lorProfile.id}${lorProfile.lorCode}${lorProfile.level}`} placement="bottom">
                                                            {lorLevel && <ContentTranslator page="LorLevels" name={`${lor.name} ${lorLevel.levelName} Description`} contentText={lorLevel.description} />}
                                                        </UncontrolledTooltip>
                                                    </React.Fragment>
                                                );
                                            })}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceApplicableSkills" contentText="Applicable Skills" />
                                </Label>
                                <Col sm={9}></Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={1}></Col>
                                <Col sm={11}>
                                    {skillProfiles &&
                                        skillProfiles
                                            .filter((skillProfile) =>
                                                !showOnly ? skillProfile && (showHiddenSkills || (!showHiddenSkills && availableSkillCodes.includes(skillProfile.skillCode))) : !!selectedSkills.find((it) => !it.removed && it.skillCode == skillProfile.skillCode && it.level == skillProfile.level)
                                            )
                                            .sort((a, b) => `${a.skillCode}${a.level}` > `${b.skillCode}${b.level}`)
                                            .map((skillProfile, index) => {
                                                const category = categories && categories.find((it) => it.subCategories.some((subCategory) => subCategory.skills.some((it) => it.name == skillProfile.skill && it.skillsCode == skillProfile.skillCode)));
                                                const subCategory = category ? category.subCategories.find((it) => it.skills.some((it) => it.name == skillProfile.skill && it.skillsCode == skillProfile.skillCode)) : null;
                                                const skill = subCategory ? subCategory.skills.find((it) => it.name == skillProfile.skill && it.skillsCode == skillProfile.skillCode) : null;
                                                const skillLevel = skill ? skill.levels.find((it) => it.level == skillProfile.level) : null;

                                                let skillLevelDescription = skillLevel ? skillLevel.description : "";
                                                skillLevelDescription = this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${skillProfile.skillCode} ${skillProfile.level} Description`, skillLevelDescription);

                                                return (
                                                    <React.Fragment key={index}>
                                                        <div style={{ float: "left" }} id={`divChkSkill${skillProfile.id}${skillProfile.skillCode}${skillProfile.level}`}>
                                                            <CustomInput
                                                                type="checkbox"
                                                                className="custom-300"
                                                                name={`chkSkill${skillProfile.id}${skillProfile.skillCode}${skillProfile.level}`}
                                                                id={`chkSkill${skillProfile.id}${skillProfile.skillCode}${skillProfile.level}`}
                                                                checked={!!selectedSkills.find((it) => !it.removed && it.skillCode == skillProfile.skillCode && it.level == skillProfile.level)}
                                                                label={
                                                                    <div style={{ paddingTop: "0px", marginTop: "-4px" }}>
                                                                        {skillProfile.skillCode}
                                                                        {skillProfile.level} - {this.contentTranslator(this.props.contentTranslations, "Skills", skillProfile.skill, skillProfile.skill)} {skillProfile.level}
                                                                    </div>
                                                                }
                                                                onChange={() => this.handleSkillCheckedChange(skillProfile.skill, skillProfile.skillCode, skillProfile.level)}
                                                                disabled={showOnly}
                                                                inline
                                                            />
                                                        </div>
                                                        <UncontrolledTooltip
                                                            target={`divChkSkill${skillProfile.id}${skillProfile.skillCode}${skillProfile.level}`}
                                                            placement="bottom"
                                                            dangerouslySetInnerHTML={{
                                                                __html: skillLevelDescription,
                                                            }}
                                                        ></UncontrolledTooltip>
                                                    </React.Fragment>
                                                );
                                            })}
                                </Col>
                            </FormGroup>
                            {!showOnly && (
                                <FormGroup row>
                                    <Col sm={3}></Col>
                                    <Col sm={9}>
                                        <Button color="danger" onClick={this.handleSubmitDelete}>
                                            <FontAwesomeIcon icon={faTrash} /> <ContentTranslator page="SfiaEvidence" name="SfiaEvidenceButtonDelete" contentText="Delete" />
                                        </Button>
                                    </Col>
                                </FormGroup>
                            )}
                        </Form>
                    )}
                </ModalBody>
                <ModalFooter>
                    {!showOnly && (
                        <Button
                            color="primary"
                            onClick={this.handleSubmitEdit}
                            disabled={
                                !(
                                    this.state.isFormValid &&
                                    !(this.state.evidenceOption == "Upload File" && this.state.fileData && this.state.fileExtension && forbiddenExtensions.includes(this.state.fileExtension)) &&
                                    (selectedSkills.filter((it) => !it.removed).length > 0 || selectedLors.filter((it) => !it.removed).length > 0) &&
                                    (this.state.evidenceOption == "Description in the Notes"
                                        ? form.notes.value && form.notes.value.length >= 30 && form.notes.value.length <= 5000
                                            ? true
                                            : false
                                        : this.state.evidenceOption == "Specify Link"
                                        ? form.link.value
                                            ? true
                                            : false
                                        : this.state.fileName
                                        ? true
                                        : false)
                                )
                            }
                        >
                            <ContentTranslator page="SfiaAll" name="Save" contentText="Save" />
                        </Button>
                    )}
                    <Button color="secondary" onClick={this.togleEditEvidenceModal}>
                        <ContentTranslator page="SfiaAll" name="Close" contentText="Close" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    render() {
        let { evidences } = this.props;

        if (!evidences) return <p></p>;
        return (
            <React.Fragment>
                {this.renderEditEvidenceDialog()}
                <p className={"page-title"}>
                    {this.props.managedPeople
                        ? this.parameterizedString(this.contentTranslator(this.props.contentTranslations, "SfiaEvidence", "SfiaEvidenceListFor", "Evidence List for {s1}"), `${this.props.managedPeople.personB.firstName} ${this.props.managedPeople.personB.lastName}`)
                        : this.contentTranslator(this.props.contentTranslations, "SfiaEvidence", "SfiaEvidenceList", "Your Evidence List")}
                </p>
                {this.props.loadingEvidence ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    this.renderEvidenceList()
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.evidence.loading,
        error: state.evidence.error,
        evidenceFile: state.evidence.evidenceFile,
        updateInProgress: state.evidence.updateInProgress,
        updatedEvidence: state.evidence.updatedEvidence,
        evidenceTypes: state.evidence.evidenceTypes,

        lors: state.lor.lors,
        managedPeople: state.auth.managedPeople,
        authMode: state.auth.authMode,
        personId: state.auth.personId,
        contentTranslations: state.content.contentTranslations,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onEvidence: bindActionCreators(evidenceActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EvidenceList);
