import * as authActions from "../../store/actions/authAction";
import * as categoryActions from "../../store/actions/categoryAction";
import * as configActions from "../../store/actions/configAction";
import * as contentActions from "../../store/actions/contentAction";
import * as personActions from "../../store/actions/personAction";
import * as adminPersonActions from "../../store/admin/actions/adminPersonActions";
import { dismisAlert, generateAlert } from "../../utils/alertUtils";
import { parameterizedString } from "../../utils/stringUtils";
import { contentTranslator } from "../../utils/translatorUtils";
import StepProfile from "../profile/StepProfile";
import ContentTranslator from "./../translator/ContentTranslator";
import Profile from "./Profile";
import SfiaSkills from "./SfiaSkills";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Alert, Button, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledTooltip } from "reactstrap";
import { bindActionCreators } from "redux";

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

const step1 = process.env.REACT_APP_SKILLPROFILE_STEP_1;
const step2 = process.env.REACT_APP_SKILLPROFILE_STEP_2;
const step3 = process.env.REACT_APP_SKILLPROFILE_STEP_3;

const step1Hover = process.env.REACT_APP_SKILLPROFILE_STEP_1_HOVER;
const step2Hover = process.env.REACT_APP_SKILLPROFILE_STEP_2_HOVER;
const step3Hover = process.env.REACT_APP_SKILLPROFILE_STEP_3_HOVER;

class SkillsProfile extends React.Component {
    static displayName = SkillsProfile.name;

    constructor(props) {
        super(props);
        this.state = {
            forceOpenEditProfile: false,
            forceOpenEditJob: false,
            stepData: {
                step1: {
                    desc: step1,
                    hover: step1Hover,
                },
                step2: {
                    desc: step2,
                    hover: step2Hover,
                },
                step3: {
                    desc: step3,
                    hover: step3Hover,
                },
                currentStep: null,
            },
            loading: true,
            contentLoading: true,
            categoryLoading: true,
            selectedVersion: null,
            showApproveDialog: false,
            approveInProgress: false,
            comment: null,
            showPostponeDialog: false,
            postponeComment: null,
            postponeType: null,
            showConfirmPostponeDialog: false,
            alerts: [],
            endorsedProfileApproved: null,
            maxNumberOfBadgeRequestSkill: 6,
        };

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
        this.parameterizedString = parameterizedString.bind(this);
    }

    componentDidMount() {
        if (this.props.language) {
            this.props.onContent.getContentTranslations(this.props.language.id, "SfiaAll-Menu-SfiaProfileMenu-SfiaProfile-SfiaProfileTooltip-Lors-Skills").then(() => {
                this.setState({ contentLoading: this.props.contentLoading });

                const stepData = {
                    step1: {
                        desc: this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SfiaProfileStep1Description", step1),
                        hover: this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SfiaProfileStep1Hover", step1Hover),
                    },
                    step2: {
                        desc: this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SfiaProfileStep2Description", step2),
                        hover: this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SfiaProfileStep2Hover", step2Hover),
                    },
                    step3: {
                        desc: this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SfiaProfileStep3Description", step3),
                        hover: this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SfiaProfileStep3Hover", step3Hover),
                    },
                    currentStep: null,
                };

                this.setState({ stepData });

                this.props.onAuth.authCheckState().then(() => {
                    if (!this.props.currAssessment) {
                        this.props.onConfig.getCurrAssessment().then(() => {});
                    }

                    if (window.location.pathname.includes("/Manage") || window.location.pathname.includes("/Certify")) {
                        const query = new URLSearchParams(this.props.location.search);
                        const email = query.get("email");

                        if (!this.props.managedPeople || !(this.props.managedPeople && this.props.managedPeople.personB.email.toLowerCase() == email.toLowerCase())) {
                            this.props.onPerson.getPersonMyPeopleByEmail(email).then(() => {
                                if (!this.props.personLoading && this.props.personManagedPeople) {
                                    this.props.onAuth.myPeopleSetManagedRelationship(this.props.personManagedPeople).then(() => {
                                        this.setState({ loading: this.props.personLoading });
                                    });
                                } else {
                                    this.setState({ loading: this.props.personLoading });
                                }
                            });
                        } else {
                            this.setState({ loading: this.props.loading });
                        }
                    } else {
                        this.setState({ loading: this.props.loading });
                    }

                    Promise.all([this.props.onCategory.getExtraFrameworkCapabilities(), this.props.onCategory.getExtraFrameworkLevels(), this.props.onCategory.getCategories(false, this.props.language ? this.props.language.id : null)]).then(() => {
                        this.setState({ categoryLoading: this.props.categoryLoading });
                    });
                });
            });
        } else {
            this.props.onAuth.authCheckState().then(() => {
                if (!this.props.currAssessment) {
                    this.props.onConfig.getCurrAssessment().then(() => {});
                }

                if (window.location.pathname.includes("/Manage") || window.location.pathname.includes("/Certify")) {
                    const query = new URLSearchParams(this.props.location.search);
                    const email = query.get("email");

                    if (!this.props.managedPeople || !(this.props.managedPeople && this.props.managedPeople.personB.email.toLowerCase() == email.toLowerCase())) {
                        this.props.onPerson.getPersonMyPeopleByEmail(email).then(() => {
                            if (!this.props.personLoading && this.props.personManagedPeople) {
                                this.props.onAuth.myPeopleSetManagedRelationship(this.props.personManagedPeople).then(() => {
                                    this.setState({ loading: this.props.personLoading });
                                });
                            } else {
                                this.setState({ loading: this.props.personLoading });
                            }
                        });
                    } else {
                        this.setState({ loading: this.props.loading });
                    }
                } else {
                    this.setState({ loading: this.props.loading });
                }

                Promise.all([this.props.onCategory.getExtraFrameworkCapabilities(), this.props.onCategory.getExtraFrameworkLevels(), this.props.onCategory.getCategories(false, this.props.language ? this.props.language.id : null)]).then(() => {
                    this.setState({ categoryLoading: this.props.categoryLoading });
                });
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.profileUpdatingSuccess !== this.props.profileUpdatingSuccess || prevProps.profileUpdatingError !== this.props.profileUpdatingError || prevProps.udpatedSkills !== this.props.udpatedSkills) {
            this.setState({
                profileUpdatingSuccess: this.props.profileUpdatingSuccess,
                profileUpdatingError: this.props.profileUpdatingError,
                udpatedSkills: this.props.udpatedSkills,
            });

            if (this.props.profileUpdatingSuccess && this.props.udpatedSkills) {
                const udpatedSkills = this.props.udpatedSkills;
                const lorProfileResponses = udpatedSkills ? udpatedSkills.lorProfileResponses : [];
                const skillProfileResponses = udpatedSkills ? udpatedSkills.skillProfileResponses : [];

                if ((lorProfileResponses && lorProfileResponses.find((it) => it.profileType == "Endorsed")) || (skillProfileResponses && skillProfileResponses.find((it) => it.profileType == "Endorsed"))) {
                    const query = new URLSearchParams(this.props.location.search);
                    const email = query.get("email");
                    this.props.onPerson.getPersonMyPeopleByEmail(email).then(() => {
                        if (!this.props.personLoading && this.props.personManagedPeople) {
                            this.props.onAuth.myPeopleSetManagedRelationship(this.props.personManagedPeople);
                        }

                        this.setState({ loading: this.props.personLoading });
                    });
                }
            }
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    selectVersion = (selectedVersion) => {
        this.setState({ selectedVersion });
    };

    approveProfile = () => {
        const { managedPeople } = this.props;

        if (managedPeople) {
            const managedPeopleAccount = managedPeople.personB.accounts && managedPeople.personB.accounts.length > 0 ? managedPeople.personB.accounts[0] : null;
            if (managedPeopleAccount) {
                const approvedOn = new Date();

                const approval = {
                    accountId: managedPeopleAccount.id,
                    email: managedPeople.personB.email,
                    approvedVersion: this.state.selectedVersion,
                    comment: this.state.comment,
                    approvedBy: localStorage.getItem("firstName") + " " + localStorage.getItem("lastName"),
                    approvedOn: approvedOn,
                };

                this.setState({ approveInProgress: true });
                this.props.onPerson.approveMyPeopleProfile(approval).then(() => {
                    if (!this.props.personUpdating) {
                        if (this.props.personError) {
                            this.generateAlert("danger", this.props.personError.errData.Message);
                        } else {
                            managedPeopleAccount.approvals = [approval];
                            this.props.onAdminPerson.updatePersonSelfAssessedApproved({
                                personId: managedPeople.personB.id,
                                selfAssessedLastApprovedOn: approvedOn,
                                selfAssessedLastApprovedBy: localStorage.getItem("firstName") + " " + localStorage.getItem("lastName"),
                                selfAssessedLastApprovedById: this.props.personId,
                                selfAssessedLastApprovedVersion: this.state.selectedVersion,
                            });

                            this.setState({ showApproveDialog: false });
                        }
                    }

                    this.setState({ approveInProgress: this.props.personUpdating });
                });
            }
        }
    };

    showApprovalModal = () => {
        this.setState({ showApproveDialog: true });
    };

    togleShowApprovalModal = () => {
        const showApproveDialog = this.state.showApproveDialog;
        this.setState({ showApproveDialog: !showApproveDialog });
    };

    handleCommentChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            comment: value,
        });
    };

    renderApprovalDialog = () => {
        return (
            <Modal isOpen={this.state.showApproveDialog} toggle={this.togleShowApprovalModal}>
                <ModalHeader toggle={this.togleShowApprovalModal}>
                    <ContentTranslator page="SfiaProfile" name="SfiaProfileApproveProfileDialogTitle" contentText="Approve Profile" />
                </ModalHeader>
                <ModalBody>
                    {this.state.approveInProgress ? (
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <Spinner size="lg" animation="border" role="status"></Spinner>
                        </div>
                    ) : (
                        <Form>
                            <FormGroup row>
                                <Label sm={12}>
                                    <ContentTranslator page="SfiaProfile" name="SfiaProfileApproveProfileDialogLabel" contentText="Would you like to approve this profile? Once approved this cannot be unapproved." />
                                </Label>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={12}>
                                    <Input type="textarea" name="comment" placeholder="Please enter comment" value={this.state.comment ? this.state.comment : ""} onChange={this.handleCommentChange} />
                                </Col>
                            </FormGroup>
                        </Form>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.approveProfile} disabled={!(this.state.comment && this.state.comment != "")}>
                        <ContentTranslator page="SfiaAll" name="Approve" contentText="Approve" />
                    </Button>
                    <Button color="secondary" onClick={this.togleShowApprovalModal}>
                        <ContentTranslator page="SfiaAll" name="Close" contentText="Close" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    step1Clicked = () => {
        this.setState({ forceOpenEditProfile: new Date() });
    };

    step2Clicked = () => {
        this.setState({ forceOpenEditJob: new Date() });
    };

    step3Clicked = () => {
        this.props.history.push("/SfiaProfile/ActionPlan");
    };

    endorsedProfileApproved = () => {
        if (this.props.managedPeople) {
            this.props.onPerson.getPersonMyPeopleByEmail(this.props.managedPeople.personB.email).then(() => {
                this.props.onAuth.myPeopleSetManagedRelationship(this.props.personManagedPeople);
                this.setState({ endorsedProfileApproved: new Date() });
            });
        }
    };

    showPostponeModal = () => {
        this.setState({ showPostponeDialog: true });
    };

    togleShowPostponeModal = () => {
        const showPostponeDialog = this.state.showPostponeDialog;
        this.setState({ showPostponeDialog: !showPostponeDialog });
    };

    handlePostponeCommentChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            postponeComment: value,
        });
    };

    renderPostponeDialog = () => {
        return (
            <Modal isOpen={this.state.showPostponeDialog} toggle={this.togleShowPostponeModal}>
                <ModalHeader toggle={this.togleShowPostponeModal}>
                    <ContentTranslator page="SfiaProfile" name="SfiaProfilePostponeProfileDialogTitle" contentText="Postpone request with reason" />
                </ModalHeader>
                <ModalBody>
                    {this.state.approveInProgress ? (
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <Spinner size="lg" animation="border" role="status"></Spinner>
                        </div>
                    ) : (
                        <Form>
                            <FormGroup row>
                                <Label sm={12}>
                                    <ContentTranslator page="SfiaProfile" name="SfiaProfilePostponeProfileDialogLabel" contentText="By postponing this request, a notification will be sent to the user with your reasons which you must enter below." />
                                </Label>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={12}>
                                    <Input type="textarea" name="postponeComment" placeholder="Please enter comment" value={this.state.postponeComment ? this.state.postponeComment : ""} onChange={this.handlePostponeCommentChange} />
                                </Col>
                            </FormGroup>
                        </Form>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" style={{ fontSize: "10px", height: "40px" }} onClick={() => this.postponeProfile("Revise Skills")} disabled={!(this.state.postponeComment && this.state.postponeComment != "")}>
                        <ContentTranslator page="SfiaProfile" name="SfiaProfilePostponeProfileDialogButtonReviseSkills" contentText="Postpone:<br />Revise skills" />
                    </Button>
                    <Button color="primary" style={{ fontSize: "10px", height: "40px" }} onClick={() => this.postponeProfile("Missing Evidence")} disabled={!(this.state.postponeComment && this.state.postponeComment != "")}>
                        <ContentTranslator page="SfiaProfile" name="SfiaProfilePostponeProfileDialogButtonMoreEvidence" contentText="Postpone:<br />More evidence" />
                    </Button>
                    <Button color="secondary" style={{ height: "40px" }} onClick={this.togleShowPostponeModal}>
                        <ContentTranslator page="SfiaAll" name="Close" contentText="Close" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    postponeProfile = (postponeType) => {
        this.setState({ postponeType, showPostponeDialog: false, showConfirmPostponeDialog: true });
    };

    closeConfirmPostponeModal = () => {
        this.setState({ showPostponeDialog: true, showConfirmPostponeDialog: false });
    };

    renderConfirmPostponeDialog = () => {
        return (
            <Modal isOpen={this.state.showConfirmPostponeDialog} toggle={!this.state.approveInProgress && this.closeConfirmPostponeModal}>
                <ModalBody>
                    {this.state.approveInProgress ? (
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <Spinner size="lg" animation="border" role="status"></Spinner>
                        </div>
                    ) : (
                        <Form>
                            <FormGroup row>
                                <Label sm={12}>
                                    <ContentTranslator page="SfiaProfile" name="SfiaProfileConfirmPostponeProfileDialogLabel" contentText="By confirming you will send a notification to the person with your comments.<br />Are you sure?" />
                                </Label>
                            </FormGroup>
                        </Form>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.confirmPostponeProfile} disabled={this.state.approveInProgress}>
                        <ContentTranslator page="SfiaAll" name="Confirm" contentText="Confirm" />
                    </Button>
                    <Button color="secondary" onClick={this.closeConfirmPostponeModal} disabled={this.state.approveInProgress}>
                        <ContentTranslator page="SfiaAll" name="Close" contentText="Close" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    confirmPostponeProfile = () => {
        const { managedPeople } = this.props;

        if (managedPeople) {
            const managedPeopleAccount = managedPeople.personB.accounts && managedPeople.personB.accounts.length > 0 ? managedPeople.personB.accounts[0] : null;
            if (managedPeopleAccount) {
                const approval = {
                    accountId: managedPeopleAccount.id,
                    email: managedPeople.personB.email,
                    type: this.state.postponeType,
                    comment: this.state.postponeComment,
                };

                this.setState({ approveInProgress: true });
                this.props.onPerson.postponeApproveMyPeopleProfile(approval).then(() => {
                    if (!this.props.personUpdating) {
                        if (this.props.personError) {
                            this.generateAlert("danger", this.props.personError.errData.Message);
                        } else {
                            managedPeopleAccount.isApprovalPostponed = true;

                            this.props.onAdminPerson.updatePersonSelfAssessedPostPoned({
                                personId: managedPeople.personB.id,
                                approvalPostponedOn: new Date(),
                                approvalPostponedBy: localStorage.getItem("firstName") + " " + localStorage.getItem("lastName"),
                                approvalPostponedById: this.props.personId,
                                approvalPostponedType: this.state.postponeType,
                                approvalPostponedComment: this.state.postponeComment,
                            });

                            this.setState({ showConfirmPostponeDialog: false });
                        }
                    }

                    this.setState({ approveInProgress: this.props.personUpdating });
                });
            }
        }
    };

    render() {
        const { managedPeople, account } = this.props;
        let approval = null;
        let managedPeopleAccount = null;

        let showApprove = false;
        let profileIsPostponed = false;
        let showEndorser = false;
        let endorsedBy = null;
        let endorsedVersion = null;
        let endorseCreatedAt = null;
        let endorseApprovedAt = null;
        const currAssessment = this.props.currAssessment;

        if (account && managedPeople) {
            if (managedPeople.personB.accounts && managedPeople.personB.accounts.length > 0) {
                const managedPeopleAccount = managedPeople.personB.accounts[0];
                if (
                    managedPeopleAccount.requireApproval &&
                    currAssessment &&
                    (managedPeople.relationshipType == "Admin" ||
                        (currAssessment.allowApprovals == 1 && managedPeople.relationshipType == "Line Manager") ||
                        (currAssessment.allowApprovals == 2 && (managedPeople.relationshipType == "Line Manager" || managedPeople.relationshipType == "Team Leader")) ||
                        (currAssessment.allowApprovals == 3 && (managedPeople.relationshipType == "Mentor" || managedPeople.relationshipType == "Team Leader")) ||
                        (currAssessment.allowApprovals == 4 && (managedPeople.relationshipType == "Line Manager" || managedPeople.relationshipType == "Mentor")) ||
                        currAssessment.allowApprovals == 5)
                ) {
                    showApprove = true;
                    profileIsPostponed = managedPeopleAccount.isApprovalPostponed;
                }
            }

            if ((managedPeople.relationshipType == "Endorser" || managedPeople.relationshipType == "Admin") && managedPeople.personB.accounts && managedPeople.personB.accounts.length > 0) {
                const managedPeopleAccount = managedPeople.personB.accounts[0];
                if (managedPeopleAccount.latestEndorsedProfileVersion) {
                    showEndorser = true;
                    endorsedBy = managedPeopleAccount.latestEndorsedProfileBy;
                    endorsedVersion = managedPeopleAccount.latestEndorsedProfileVersion;
                    endorseCreatedAt = managedPeopleAccount.latestEndorsedProfileCreatedOn;
                    endorseApprovedAt = managedPeopleAccount.latestEndorsedProfileApprovedOn;
                }
            } else {
                if (account && account.latestEndorsedProfileVersion) {
                    showEndorser = true;
                    endorsedBy = account.latestEndorsedProfileBy;
                    endorsedVersion = account.latestEndorsedProfileVersion;
                    endorseCreatedAt = account.latestEndorsedProfileCreatedOn;
                    endorseApprovedAt = account.latestEndorsedProfileApprovedOn;
                }
            }
        } else {
            if (account && currAssessment && currAssessment.enableApprovalRequest) {
                showApprove = true;
            }
        }

        if (managedPeople) {
            managedPeopleAccount = managedPeople.personB.accounts && managedPeople.personB.accounts.length > 0 ? managedPeople.personB.accounts[0] : null;
            if (managedPeopleAccount && managedPeopleAccount.approvals && managedPeopleAccount.approvals.length > 0 && managedPeopleAccount.approvals.filter((it) => !it.isFramework && it.approvedVersion == managedPeopleAccount.latestSelfAssessedProfileVersion).length > 0) {
                approval = managedPeopleAccount.approvals.filter((it) => !it.isFramework && it.approvedVersion == managedPeopleAccount.latestSelfAssessedProfileVersion).sort((b, a) => new Date(a.approvedOn) - new Date(b.approvedOn))[0];
            }
        } else {
            if (account && account.approvals && account.approvals.filter((it) => !it.isFramework && it.approvedVersion == account.latestSelfAssessedProfileVersion).length > 0) {
                approval = account.approvals.filter((it) => !it.isFramework && it.approvedVersion == account.latestSelfAssessedProfileVersion).sort((b, a) => new Date(a.approvedOn) - new Date(b.approvedOn))[0];
            }

            if (account && account.latestEndorsedProfileVersion) {
                showEndorser = true;
                endorsedBy = account.latestEndorsedProfileBy;
                endorsedVersion = account.latestEndorsedProfileVersion;
                endorseCreatedAt = account.latestEndorsedProfileCreatedOn;
                endorseApprovedAt = account.latestEndorsedProfileApprovedOn;
            }
        }

        return (
            <Container className="contentMain">
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                {this.renderApprovalDialog()}
                {this.renderPostponeDialog()}
                {this.renderConfirmPostponeDialog()}
                {!this.state.loading && !this.state.categoryLoading && !this.state.contentLoading ? (
                    <React.Fragment>
                        <Row>
                            <Col md="6">
                                <p className={"page-title"}>
                                    <ContentTranslator page="SfiaProfile" name="SfiaProfilePageTitlePersonalSummary" contentText="Personal Summary" /> {this.props.managedPeople && ` for ${this.props.managedPeople.personB.firstName} ${this.props.managedPeople.personB.lastName}`}
                                </p>
                            </Col>
                            {(this.props.hasPeoples || this.props.isEndorser || this.props.manageEndorsements || this.props.manageSelfAssessed) && managedPeople ? (
                                <React.Fragment>
                                    {(showApprove || showEndorser) && (
                                        <div className={!approval && showApprove ? "col-md-3-5" : "col-md-6"} style={{ textAlign: "center" }}>
                                            {showApprove && (
                                                <React.Fragment>
                                                    {approval ? (
                                                        <React.Fragment>
                                                            <Alert id="alertApproved" style={{ backgroundColor: "#70ad47", color: "#ffffff", fontWeight: "bold", padding: "7px" }}>
                                                                {`v${approval.approvedVersion} ${
                                                                    approval.updatedOn || approval.createdOn
                                                                        ? approval.updatedOn
                                                                            ? `(${new Intl.DateTimeFormat("en-GB", {
                                                                                  year: "2-digit",
                                                                                  month: "short",
                                                                                  day: "numeric",
                                                                              }).format(new Date(approval.updatedOn))}) `
                                                                            : approval.createdOn
                                                                            ? `(${new Intl.DateTimeFormat("en-GB", {
                                                                                  year: "2-digit",
                                                                                  month: "short",
                                                                                  day: "numeric",
                                                                              }).format(new Date(approval.createdOn))}) `
                                                                            : ""
                                                                        : ""
                                                                }`}
                                                                {approval.approvedBy
                                                                    ? this.parameterizedString(
                                                                          this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SfiaProfileApprovedBy", "Approved by {s1} on {s2}"),
                                                                          approval.approvedBy,
                                                                          new Intl.DateTimeFormat("en-GB", {
                                                                              year: "2-digit",
                                                                              month: "short",
                                                                              day: "numeric",
                                                                          }).format(new Date(approval.approvedOn))
                                                                      )
                                                                    : this.parameterizedString(
                                                                          this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SfiaProfileApprovedOn", "Approved on {s1}"),
                                                                          new Intl.DateTimeFormat("en-GB", {
                                                                              year: "2-digit",
                                                                              month: "short",
                                                                              day: "numeric",
                                                                          }).format(new Date(approval.approvedOn))
                                                                      )}
                                                            </Alert>
                                                            <UncontrolledTooltip target={`alertApproved`} placement="bottom">
                                                                {approval.comment}
                                                            </UncontrolledTooltip>
                                                        </React.Fragment>
                                                    ) : managedPeopleAccount && managedPeopleAccount.isApprovalRequested && showApprove ? (
                                                        <Alert style={{ backgroundColor: "#ffc000", color: "#ffffff", fontWeight: "bold", padding: "7px" }}>
                                                            {this.parameterizedString(
                                                                this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SfiaProfileApprovalRequestedOn", "Approval requested on {s1}"),
                                                                new Intl.DateTimeFormat("en-GB", {
                                                                    year: "2-digit",
                                                                    month: "short",
                                                                    day: "numeric",
                                                                }).format(new Date(managedPeopleAccount.approvalRequestSentOn))
                                                            )}
                                                        </Alert>
                                                    ) : (
                                                        <Alert style={{ backgroundColor: "#ff0000", color: "#ffffff", fontWeight: "bold", padding: "7px" }}>
                                                            <ContentTranslator page="SfiaProfile" name="SfiaProfilePageManagedPeopleApprovalNotRequested" contentText="Approval not yet requested" />
                                                        </Alert>
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {showEndorser && (
                                                <React.Fragment>
                                                    <Alert id="alertApproved" style={{ backgroundColor: "#3164c9", color: "#ffffff", fontWeight: "bold", padding: "7px", width: "100%" }}>
                                                        {`v${endorsedVersion} ${
                                                            endorseCreatedAt || endorseApprovedAt
                                                                ? endorseCreatedAt
                                                                    ? `(${new Intl.DateTimeFormat("en-GB", {
                                                                          year: "2-digit",
                                                                          month: "short",
                                                                          day: "numeric",
                                                                      }).format(new Date(endorseCreatedAt))}) `
                                                                    : endorseApprovedAt
                                                                    ? `(${new Intl.DateTimeFormat("en-GB", {
                                                                          year: "2-digit",
                                                                          month: "short",
                                                                          day: "numeric",
                                                                      }).format(new Date(endorseApprovedAt))}) `
                                                                    : ""
                                                                : ""
                                                        }`}
                                                        {endorsedBy
                                                            ? this.parameterizedString(
                                                                  this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SfiaProfileEndorsedBy", "Endorsed By {s1} on {s2}"),
                                                                  endorsedBy,
                                                                  new Intl.DateTimeFormat("en-GB", {
                                                                      year: "2-digit",
                                                                      month: "short",
                                                                      day: "numeric",
                                                                  }).format(new Date(endorseApprovedAt))
                                                              )
                                                            : this.parameterizedString(
                                                                  this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SfiaProfileEndorsedOn", "Endorsed on {s1}"),
                                                                  new Intl.DateTimeFormat("en-GB", {
                                                                      year: "2-digit",
                                                                      month: "short",
                                                                      day: "numeric",
                                                                  }).format(new Date(endorseApprovedAt))
                                                              )}
                                                    </Alert>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    )}
                                    {!approval && showApprove && !this.props.badgeCertification && (
                                        <React.Fragment>
                                            <div className="col-md-2-5" style={{ textAlign: "center" }}>
                                                <button className="btn btn-light btnSecondary-Style" onClick={this.showApprovalModal} disabled={profileIsPostponed}>
                                                    <ContentTranslator page="SfiaProfile" name="SfiaProfilePageManagedPeopleButtonApprove" contentText="Approve" />
                                                </button>
                                                &nbsp;
                                                <button className="btn btn-light btnSecondary-Style" style={{ whiteSpace: "wrap", width: "85px" }} onClick={this.showPostponeModal} disabled={profileIsPostponed}>
                                                    <ContentTranslator page="SfiaProfile" name="SfiaProfilePageManagedPeopleButtonPostpone" contentText="Postpone with reason" />
                                                </button>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Col md="6" style={{ textAlign: "center" }}>
                                        {approval && (
                                            <React.Fragment>
                                                <Alert id="alertApproved" style={{ backgroundColor: "#70ad47", color: "#ffffff", fontWeight: "bold", padding: "7px" }}>
                                                    {`v${approval.approvedVersion} ${
                                                        approval.updatedOn || approval.createdOn
                                                            ? approval.updatedOn
                                                                ? `(${new Intl.DateTimeFormat("en-GB", {
                                                                      year: "2-digit",
                                                                      month: "short",
                                                                      day: "numeric",
                                                                  }).format(new Date(approval.updatedOn))}) `
                                                                : approval.createdOn
                                                                ? `(${new Intl.DateTimeFormat("en-GB", {
                                                                      year: "2-digit",
                                                                      month: "short",
                                                                      day: "numeric",
                                                                  }).format(new Date(approval.createdOn))}) `
                                                                : ""
                                                            : ""
                                                    }`}
                                                    {approval.approvedBy
                                                        ? this.parameterizedString(
                                                              this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SfiaProfileApprovedBy", "Approved by {s1} on {s2}"),
                                                              approval.approvedBy,
                                                              new Intl.DateTimeFormat("en-GB", {
                                                                  year: "2-digit",
                                                                  month: "short",
                                                                  day: "numeric",
                                                              }).format(new Date(approval.approvedOn))
                                                          )
                                                        : this.parameterizedString(
                                                              this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SfiaProfileApprovedOn", "Approved on {s1}"),
                                                              new Intl.DateTimeFormat("en-GB", {
                                                                  year: "2-digit",
                                                                  month: "short",
                                                                  day: "numeric",
                                                              }).format(new Date(approval.approvedOn))
                                                          )}
                                                </Alert>
                                                <UncontrolledTooltip target={`alertApproved`} placement="bottom">
                                                    {approval.comment}
                                                </UncontrolledTooltip>
                                            </React.Fragment>
                                        )}
                                        {showEndorser && (
                                            <React.Fragment>
                                                <Alert id="alertEndorsed" style={{ backgroundColor: "#3164c9", color: "#ffffff", fontWeight: "bold", padding: "7px", width: "100%" }}>
                                                    {`v${endorsedVersion} ${
                                                        endorseCreatedAt || endorseApprovedAt
                                                            ? endorseCreatedAt
                                                                ? `(${new Intl.DateTimeFormat("en-GB", {
                                                                      year: "2-digit",
                                                                      month: "short",
                                                                      day: "numeric",
                                                                  }).format(new Date(endorseCreatedAt))}) `
                                                                : endorseApprovedAt
                                                                ? `(${new Intl.DateTimeFormat("en-GB", {
                                                                      year: "2-digit",
                                                                      month: "short",
                                                                      day: "numeric",
                                                                  }).format(new Date(endorseApprovedAt))}) `
                                                                : ""
                                                            : ""
                                                    }`}
                                                    {endorsedBy
                                                        ? this.parameterizedString(
                                                              this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SfiaProfileEndorsedBy", "Endorsed By {s1} on {s2}"),
                                                              endorsedBy,
                                                              new Intl.DateTimeFormat("en-GB", {
                                                                  year: "2-digit",
                                                                  month: "short",
                                                                  day: "numeric",
                                                              }).format(new Date(endorseApprovedAt))
                                                          )
                                                        : this.parameterizedString(
                                                              this.contentTranslator(this.props.contentTranslations, "SfiaProfile", "SfiaProfileEndorsedOn", "Endorsed on {s1}"),
                                                              new Intl.DateTimeFormat("en-GB", {
                                                                  year: "2-digit",
                                                                  month: "short",
                                                                  day: "numeric",
                                                              }).format(new Date(endorseApprovedAt))
                                                          )}
                                                </Alert>
                                            </React.Fragment>
                                        )}
                                    </Col>
                                </React.Fragment>
                            )}
                        </Row>
                        {!managedPeople && <StepProfile stepData={this.state.stepData} step1Clicked={this.step1Clicked} step2Clicked={this.step2Clicked} step3Clicked={this.step3Clicked}></StepProfile>}
                        <Profile forceOpenEditJob={this.state.forceOpenEditJob} />
                        <SfiaSkills
                            forceOpenEditProfile={this.state.forceOpenEditProfile}
                            selectVersion={this.selectVersion}
                            endorsedProfileApproved={this.endorsedProfileApproved}
                            badgeCertification={this.props.badgeCertification}
                            maxNumberOfBadgeRequestSkill={this.state.maxNumberOfBadgeRequestSkill}
                        />
                    </React.Fragment>
                ) : (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        token: state.auth.token,
        personId: state.auth.personId,

        personLoading: state.person.loading,
        account: state.person.account,
        personUpdating: state.person.updating,
        personError: state.person.error,

        personManagedPeople: state.person.managedPeople,
        managedPeople: state.auth.managedPeople,

        extraFrameworkCapabilities: state.category.extraFrameworkCapabilities,
        extraFrameworkLevels: state.category.extraFrameworkLevels,
        categories: state.category.categories,
        categoryLoading: state.category.loading,
        categoryError: state.category.error,

        language: state.language.language,
        contentTranslations: state.content.contentTranslations,
        contentLoading: state.content.loading,
        contentError: state.content.error,

        hasPeoples: state.auth.hasPeoples,
        isEndorser: state.auth.isEndorser,
        manageEndorsements: state.auth.manageEndorsements,
        manageSelfAssessed: state.auth.manageSelfAssessed,
        manageSelfProfiles: state.auth.manageSelfProfiles,
        currAssessment: state.config.currAssessment,

        profileUpdating: state.skillProfile.profileUpdating,
        profileUpdatingSuccess: state.skillProfile.profileUpdatingSuccess,
        profileUpdatingError: state.skillProfile.profileUpdatingError,
        udpatedSkills: state.skillProfile.udpatedSkills,
        manageEndorsementOperation: state.skillProfile.manageEndorsementOperation,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onPerson: bindActionCreators(personActions, dispatch),
        onCategory: bindActionCreators(categoryActions, dispatch),
        onContent: bindActionCreators(contentActions, dispatch),
        onConfig: bindActionCreators(configActions, dispatch),
        onAdminPerson: bindActionCreators(adminPersonActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillsProfile);
