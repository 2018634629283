import * as actionPlanActions from "../../store/actions/actionPlanAction";
import changeInput from "../../utils/changeInput";
import { parameterizedString } from "../../utils/stringUtils";
import { contentTranslator } from "../../utils/translatorUtils";
import { ContentWrapperLg } from "../common/ContentWrapperLg";
import ContentTranslator from "../translator/ContentTranslator";
import "./../../custom-edit-action.css";
import { faArrowDown, faArrowUp, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Combobox from "react-widgets/Combobox";
import { Alert, Button, ButtonGroup, CustomInput, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, UncontrolledTooltip } from "reactstrap";
import { Col, Container, Row } from "reactstrap";
import { bindActionCreators } from "redux";

var _ = require("lodash");

const filterOutLoROnProposeAction = false;

class Todo extends React.Component {
    static displayName = Todo.name;

    constructor(props) {
        super(props);
        this.state = {
            optionsStatus: [
                { label: "Todo", value: "ToDo" },
                { label: "In Progress", value: "InProgress" },
                { label: "Done", value: "Done - self verified" },
            ],
            optionsActionTypes: this.props.optionsActionTypes,
            optionsActionSkills: this.props.optionsActionSkills,
            optionProposeCapabilities: [{ name: "Knowledge" }, { name: "Proficient" }, { name: "Competent" }],
            showActionPlanDetail: false,
            poppedUpAction: null,
            showActionPlanEdit: false,
            editedAction: null,
            form: {
                id: {
                    validation: {
                        required: true,
                    },
                    value: null,
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                status: {
                    validation: {
                        required: true,
                    },
                    value: null,
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                actionType: {
                    validation: {
                        required: true,
                    },
                    value: null,
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                name: {
                    validation: {
                        required: true,
                    },
                    value: null,
                    valid: false,
                    isValidFormat: false,
                    touched: false,
                },
                targetCompletionOn: {
                    validation: {
                        required: this.props.containerConfig && this.props.containerConfig ? this.props.containerConfig.actionTargetCompletionDateIsMandatory : false,
                        isDateTime: true,
                    },
                    value: null,
                    valid: !(this.props.containerConfig && this.props.containerConfig ? this.props.containerConfig.actionTargetCompletionDateIsMandatory : false),
                    isValidFormat: true,
                    touched: false,
                },
                description: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                link: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                duration: {
                    validation: {
                        required: false,
                        isNumeric: true,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                durationHours: {
                    validation: {
                        required: false,
                        isNumeric: true,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                proposeCapability: {
                    validation: {
                        required: false,
                    },
                    value: null,
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            isFormValid: false,
            selectedSkills: [],
            pickedOtherSkills: [],
            selectedOtherSkill: "",
            selectedOtherSkills: [],
            actionPlanUpdating: false,
            selectedSortOption: "targetCompletionOn",
            selectedSortOptionDirection: "asc",
            optionSorts: [
                {
                    label: "Target Completion Date",
                    field: "targetCompletionOn",
                    selected: true,
                },
                {
                    label: "Status",
                    field: "status",
                },
                {
                    label: "Type",
                    field: "actionType",
                },
            ],
            showDone: false,
            showHidden: false,
            showPopUpAsPromoteAction: false,
            showActionPromotionConfirmationDialog: false,
            proposeActionMode: false,
            showPopUpAsProposeAction: false,
            showPopUpAsViewProposedAction: false,
            showProposeActionConfirmationDialog: false,
            viewProposedActionMode: false,
            showIssueBadgeConfirmationDialog: false,
            showRejectBadgeConfirmationDialog: false,
        };

        this.openActionPlanDetailModal = this.openActionPlanDetailModal.bind(this);
        this.togleActionPlanDetailModal = this.togleActionPlanDetailModal.bind(this);

        this.openActionPlanEditModal = this.openActionPlanEditModal.bind(this);
        this.togleActionPlanEditModal = this.togleActionPlanEditModal.bind(this);

        this.handleSkillCheckedChange = this.handleSkillCheckedChange.bind(this);
        this.handleChangeOtherSkill = this.handleChangeOtherSkill.bind(this);
        this.handleAddOtherSkill = this.handleAddOtherSkill.bind(this);
        this.handleOtherSkillCheckedChange = this.handleOtherSkillCheckedChange.bind(this);
        this.handleSubmitEdit = this.handleSubmitEdit.bind(this);
        this.handleSubmitDelete = this.handleSubmitDelete.bind(this);

        this.handleChangeSortOption = this.handleChangeSortOption.bind(this);
        this.handleChangeSortDirection = this.handleChangeSortDirection.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
        this.parameterizedString = parameterizedString.bind(this);
    }

    componentDidMount() {
        this.processJobGapData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loadingAction !== this.props.loadingAction || prevProps.loadingProfile !== this.props.loadingProfile || prevProps.selectedJob !== this.props.selectedJob || prevProps.actions !== this.props.actions) {
            this.processJobGapData();
        }

        if (prevProps.showHiddenSkills !== this.props.showHiddenSkills) {
            this.setState({ showActionPlanEdit: false });
        }
    }

    processJobGapData = () => {
        if (!this.props.loadingAction && !this.props.loadingProfile) {
            const { actions, jobLorProfiles, jobSkillProfiles, lorProfiles, skillProfiles, selectedJob, showHiddenSkills } = this.props;
            let { categories } = this.props;

            categories = categories.map((category) => {
                return {
                    id: category.id,
                    name: category.name,
                    description: category.description,
                    colour: category.colour,
                    sfiaVersion: category.sfiaVersion,
                    subCategories: category.subCategories.map((subCategory) => {
                        return {
                            id: subCategory.id,
                            name: subCategory.name,
                            description: subCategory.description,
                            colour: subCategory.colour,
                            skillColour: subCategory.skillColour,
                            skills: subCategory.skills.map((skill) => {
                                let skillNameShown = false;

                                return {
                                    id: skill.id,
                                    name: skill.name,
                                    skillsCode: skill.skillsCode,
                                    description: skill.description,
                                    levels: skill.levels.map((level) => {
                                        const skillProfile = skillProfiles.find((x) => x.skillCode == skill.skillsCode && x.level == level.level);
                                        const jobSkillProfile = jobSkillProfiles.find((x) => x.jobId == selectedJob.id && x.skillCode == skill.skillsCode && x.level == level.level);

                                        if (jobSkillProfile && skillProfile) {
                                            if (skillProfile.numericVal == 1 || skillProfile.stringVal == "M") {
                                                jobSkillProfile.actioned = true;
                                            }
                                        }

                                        const action = actions.find((actions) => actions.skills.some((it) => it.code == skill.skillsCode && it.level == level.level));

                                        level = {
                                            skillNameShown: false,
                                            show: false,
                                            id: level.id,
                                            level: level.level,
                                            description: level.description,
                                            skillProfile: skillProfile,
                                            jobSkillProfile: jobSkillProfile,
                                            action: action,
                                        };

                                        if (!skillNameShown) {
                                            if ((level.skillProfile && level.jobSkillProfile && level.skillProfile.numericVal < 1) || (!level.skillProfile && level.jobSkillProfile) || this.state.showAllSkill) {
                                                level.skillNameShown = true;
                                                skillNameShown = true;
                                            }
                                        }

                                        if ((level.skillProfile && level.jobSkillProfile && level.skillProfile.numericVal < 1) || (!level.skillProfile && level.jobSkillProfile)) {
                                            level.show = true;
                                        }

                                        return level;
                                    }),
                                };
                            }),
                        };
                    }),
                };
            });

            jobLorProfiles.map((jobLorProfile) => {
                const lorProfile = lorProfiles.find((it) => it.lorCode == jobLorProfile.lorCode && it.level >= jobLorProfile.level);
                const plan = actions.find((it) => it.skills.some((skill) => skill.code == jobLorProfile.lorCode && skill.level >= jobLorProfile.level));

                if (lorProfile || plan) {
                    jobLorProfile.actioned = true;
                }
            });

            jobSkillProfiles.map((jobSkillProfile) => {
                const plan = actions.find((it) => it.skills.some((skill) => skill.code == jobSkillProfile.skillCode && skill.level == jobSkillProfile.level));
                if (plan) {
                    jobSkillProfile.actioned = true;
                }
            });

            let optionsActionSkills = this.props.optionsActionSkills;
            optionsActionSkills.forEach((actionSkill) => {
                actionSkill.skills.forEach((skill) => {
                    if (
                        actions.filter((x) => x.skills.some((y) => y.code == skill.skillCode && y.level == skill.skillLevel)).length > 0 ||
                        jobLorProfiles.filter((it) => it.lorCode == skill.skillCode && it.level == skill.skillLevel).length > 0 ||
                        jobSkillProfiles.filter((it) => it.skillCode == skill.skillCode && it.level == skill.skillLevel).length > 0
                    ) {
                        skill.actioned = true;
                    }
                });
            });

            this.setState({
                optionsActionSkills,
            });
        }
    };

    openActionPlanEditModal(action, promoteAction, proposeAction, viewProposedAction) {
        let form = this.state.form;

        form.id.value = action.id;
        form.id.valid = true;
        form.id.isValidFormat = true;

        form.status.value = action.status;
        form.status.valid = true;
        form.status.isValidFormat = true;

        form.actionType.value = action.actionType;
        form.actionType.valid = true;
        form.actionType.isValidFormat = true;

        form.name.value = action.name;
        form.name.valid = true;
        form.name.isValidFormat = true;

        form.targetCompletionOn.value = action.targetCompletionOn;
        form.targetCompletionOn.valid = form.targetCompletionOn.validation.required ? (action.targetCompletionOn ? true : false) : true;
        form.targetCompletionOn.isValidFormat = form.targetCompletionOn.validation.required ? (action.targetCompletionOn ? true : false) : true;

        form.description.value = action.description;
        form.link.value = action.link;
        form.duration.value = action.duration;
        form.durationHours.value = action.durationHours;

        let proposeCapability = null;
        if (viewProposedAction && action && action.updateProfileEvidenceProposalSkills && action.updateProfileEvidenceProposalSkills.length > 0) {
            proposeCapability = action.updateProfileEvidenceProposalSkills[0].capability;
        }

        form.proposeCapability.value = proposeCapability;
        form.proposeCapability.valid = !proposeAction;
        form.proposeCapability.isValidFormat = !proposeAction;

        this.setState({
            editedAction: action,
            showActionPlanEdit: true,
            form,
            isFormValid: form.targetCompletionOn.valid && !proposeAction,
            selectedSkills: action.skills.map((skill) => {
                return {
                    id: skill.id,
                    skillName: skill.name,
                    skillCode: skill.code,
                    skillLevel: skill.level,
                    skillType: skill.type,
                };
            }),
            pickedOtherSkills: [],
            selectedOtherSkills: [],
            showPopUpAsPromoteAction: promoteAction,
            showPopUpAsProposeAction: proposeAction,
            showPopUpAsViewProposedAction: viewProposedAction,
        });
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handleChangeDate(name, value) {
        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    }

    togleActionPlanEditModal() {
        const showActionPlanEdit = this.state.showActionPlanEdit;
        this.setState({ showActionPlanEdit: !showActionPlanEdit });
    }

    openActionPlanDetailModal(action) {
        this.setState({ poppedUpAction: action, showActionPlanDetail: true });
    }

    togleActionPlanDetailModal() {
        const showActionPlanDetail = this.state.showActionPlanDetail;
        this.setState({ showActionPlanDetail: !showActionPlanDetail });
    }

    modalActionPlanDetail() {
        const { poppedUpAction } = this.state;

        return (
            <Modal isOpen={this.state.showActionPlanDetail} toggle={this.togleActionPlanDetailModal}>
                <ModalHeader toggle={this.togleActionPlanDetailModal}>
                    <ContentTranslator page="SfiaPlan" name="SfiaInterventionInfoTitle" contentText="Action Info" />
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup row>
                            <Label sm={3} style={{ textAlign: "right" }}>
                                <ContentTranslator page="SfiaPlan" name="SfiaActionType" contentText="Type" />
                            </Label>
                            <Col sm={9}>
                                <Input value={poppedUpAction && poppedUpAction.actionType} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3} style={{ textAlign: "right" }}>
                                <ContentTranslator page="SfiaPlan" name="SfiaActionName" contentText="Name" />
                            </Label>
                            <Col sm={9}>
                                <Input value={poppedUpAction && poppedUpAction.name} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3} style={{ textAlign: "right" }}>
                                <ContentTranslator page="SfiaPlan" name="SfiaActionTarget" contentText="Target" />
                            </Label>
                            <Col sm={9}>
                                <Input
                                    value={
                                        poppedUpAction &&
                                        poppedUpAction.targetCompletionOn &&
                                        new Intl.DateTimeFormat("en-GB", {
                                            year: "2-digit",
                                            month: "short",
                                            day: "numeric",
                                        }).format(new Date(poppedUpAction.targetCompletionOn))
                                    }
                                    disabled
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3} style={{ textAlign: "right" }}>
                                <ContentTranslator page="SfiaPlan" name="SfiaActionDescription" contentText="Description" />
                            </Label>
                            <Col sm={9}>
                                <Input type="textarea" value={poppedUpAction && poppedUpAction.description} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3} style={{ textAlign: "right" }}>
                                <ContentTranslator page="SfiaPlan" name="SfiaActionWebsite" contentText="Website" />
                            </Label>
                            <Col sm={9}>
                                <Input value={poppedUpAction && poppedUpAction.link} disabled />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3} style={{ textAlign: "right" }}>
                                <ContentTranslator page="SfiaPlan" name="SfiaActionDuration" contentText="Duration" />
                            </Label>
                            <Col sm={9}>
                                <Input value={poppedUpAction && poppedUpAction.duration} disabled />
                            </Col>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.togleActionPlanDetailModal();
                            this.openActionPlanEditModal(poppedUpAction, false, false, false);
                        }}
                    >
                        <ContentTranslator page="SfiaAll" name="Edit" contentText="Edit" />
                    </Button>
                    <Button color="secondary" onClick={this.togleActionPlanDetailModal}>
                        <ContentTranslator page="SfiaAll" name="Close" contentText="Close" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    handleSkillCheckedChange(skillName, skillCode, skillLevel, skillType) {
        let selectedSkills = this.state.selectedSkills;

        const selectedSkill = {
            skillName,
            skillCode,
            skillLevel,
            skillType,
        };

        if (selectedSkills.find((it) => it.skillName == skillName && it.skillCode == skillCode && it.skillLevel == skillLevel)) {
            const unSelectedSkill = selectedSkills.find((it) => it.skillName == skillName && it.skillCode == skillCode && it.skillLevel == skillLevel);

            if (unSelectedSkill.id) {
                if (unSelectedSkill.removed) {
                    unSelectedSkill.removed = false;
                } else {
                    unSelectedSkill.removed = true;
                }

                const index = selectedSkills.indexOf(unSelectedSkill);
                if (index !== -1) {
                    selectedSkills[index].removed = unSelectedSkill.removed;
                }

                this.setState({ selectedSkills });
            } else {
                const index = selectedSkills.indexOf(unSelectedSkill);
                if (index !== -1) {
                    selectedSkills.splice(index, 1);
                }

                this.setState({ selectedSkills });
            }
        } else {
            this.setState({ selectedSkills: [...selectedSkills, selectedSkill] });
        }
    }

    handleChangeOtherSkill = (event) => {
        this.setState({ selectedOtherSkill: event.target.value });
    };

    handleAddOtherSkill() {
        const selectedOtherSkill = this.state.selectedOtherSkill;
        let { optionsActionSkills, pickedOtherSkills, selectedOtherSkills } = this.state;

        if (selectedOtherSkill && selectedOtherSkill !== "") {
            let optionsActionSkill = optionsActionSkills.find((it) => it.skills.some((skill) => `${skill.skillCode}-${skill.skillLevel}` == selectedOtherSkill));
            if (optionsActionSkill) {
                let skill = optionsActionSkill.skills.find((skill) => `${skill.skillCode}-${skill.skillLevel}` == selectedOtherSkill);
                if (skill) {
                    skill.selected = true;

                    pickedOtherSkills.push({
                        skillName: skill.skillName,
                        skillCode: skill.skillCode,
                        skillLevel: skill.skillLevel,
                        skillType: skill.skillType,
                    });

                    selectedOtherSkills.push({
                        skillName: skill.skillName,
                        skillCode: skill.skillCode,
                        skillLevel: skill.skillLevel,
                        skillType: skill.skillType,
                    });
                }

                this.setState({ optionsActionSkills, selectedOtherSkill: "", pickedOtherSkills, selectedOtherSkills });
            }
        }
    }

    handleOtherSkillCheckedChange(skillName, skillCode, skillLevel, skillType) {
        let selectedOtherSkills = this.state.selectedOtherSkills;

        const selectedSkill = {
            skillName,
            skillCode,
            skillLevel,
            skillType,
        };

        if (selectedOtherSkills.find((it) => it.skillName == skillName && it.skillCode == skillCode && it.skillLevel == skillLevel)) {
            const unSelectedSkill = selectedOtherSkills.find((it) => it.skillName == skillName && it.skillCode == skillCode && it.skillLevel == skillLevel);
            const index = selectedOtherSkills.indexOf(unSelectedSkill);
            if (index !== -1) {
                selectedOtherSkills.splice(index, 1);
            }

            this.setState({ selectedOtherSkills });
        } else {
            this.setState({ selectedOtherSkills: [...selectedOtherSkills, selectedSkill] });
        }
    }

    handleSubmitEdit() {
        const { editedAction, selectedSkills, selectedOtherSkills } = this.state;

        const actionRequest = {
            personEmail: this.props.managedPeople && this.props.managedPeople.personB.email,
            actionType: this.state.form.actionType.value,
            name: this.state.form.name.value,
            description: this.state.form.description.value,
            status: this.state.form.status.value,
            targetCompletionOn: this.state.form.targetCompletionOn.value,
            duration: this.state.form.duration.value,
            durationHours: this.state.form.durationHours.value,
            link: this.state.form.link.value,
            skills: [...selectedSkills.filter((it) => !it.removed), ...selectedOtherSkills.filter((it) => !it.removed)].map((skill) => {
                return {
                    id: skill.id ? skill.id : null,
                    name: skill.skillName,
                    code: skill.skillCode,
                    level: skill.skillLevel,
                    type: skill.skillType,
                };
            }),
        };

        this.setState({ actionPlanUpdating: true });
        Promise.resolve(this.props.managedPeople ? this.props.onActionPlan.updateManagedPeopleAction(editedAction.id, actionRequest) : this.props.onActionPlan.updateAction(editedAction.id, actionRequest)).then(() => {
            if (!this.props.actionPlanUpdating) {
                if (this.props.actionPlanUpdatingError) {
                    this.props.generateAlert("danger", this.props.actionPlanUpdatingError.errData.Message);
                } else {
                    this.setState({ showActionPlanEdit: false, selectedSkills: [] });
                    this.props.handleActionPlanUpdated(this.props.updatedAction);
                    this.props.generateAlert("success", "Action plan updated");
                }
            }
            this.setState({ actionPlanUpdating: this.props.actionPlanUpdating });
        });
    }

    handleSubmitDelete() {
        const { editedAction } = this.state;

        const deletedId = editedAction.id;

        this.setState({ actionPlanUpdating: true });
        Promise.resolve(this.props.managedPeople ? this.props.onActionPlan.deleteManagedPeopleAction(deletedId, this.props.managedPeople.personB.email) : this.props.onActionPlan.deleteAction(deletedId)).then(() => {
            if (!this.props.actionPlanUpdating) {
                if (this.props.actionPlanUpdatingError) {
                    this.props.generateAlert("danger", this.props.actionPlanUpdatingError.errData.Message);
                } else {
                    this.setState({ showActionPlanEdit: false });
                    this.props.handleActionPlanDeleted(deletedId);
                }
            }
            this.setState({ actionPlanUpdating: this.props.actionPlanUpdating });
        });
    }

    handleSubmitIssueBadge = () => {
        this.setState({ showActionPlanEdit: false, showIssueBadgeConfirmationDialog: true });
    };

    handleCancelIssueBadge = () => {
        this.setState({ showActionPlanEdit: true, showIssueBadgeConfirmationDialog: false });
    };

    handleConfirmIssueBadge = () => {
        const { editedAction } = this.state;

        this.setState({ actionPlanUpdating: true });
        this.props.onActionPlan.issueBadgeManagedPeopleAction(editedAction.id, this.props.managedPeople.personB.email).then(() => {
            if (!this.props.actionPlanUpdating) {
                if (this.props.actionPlanUpdatingError) {
                    this.props.generateAlert("danger", this.props.actionPlanUpdatingError.errData.Message);
                } else {
                    this.setState({ showIssueBadgeConfirmationDialog: false });
                    this.props.handleActionPlanUpdated(this.props.updatedAction);
                    this.props.generateAlert("success", "Issue badge success");
                }
            }
            this.setState({ actionPlanUpdating: this.props.actionPlanUpdating });
        });
    };

    renderIssueBadgeConfirmation = () => {
        return (
            <Modal isOpen={this.state.showIssueBadgeConfirmationDialog}>
                <ModalHeader>
                    <ContentTranslator page="SfiaPlan" name="SfiaPlanIssueBadgeConfirmationTitle" contentText="Issue Badge Confirmation" />
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        <ContentTranslator page="SfiaPlan" name="SfiaPlanIssueBadgeConfirmationNotes" contentText="This completed action will trigger the issuing of badges and incurs a cost. Are you authorized and sure you want to continue?" />
                                    </Label>
                                    <br />
                                    <Label sm={12}>
                                        <ContentTranslator page="SfiaPlan" name="SfiaPlanIssueBadgeConfirmationNotes2" contentText="Note: The issuing of badges can take a little while so please be patient after selecting OK and wait for the Success pop-up comment." />
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmIssueBadge();
                        }}
                        disabled={this.state.actionPlanUpdating}
                    >
                        <ContentTranslator page="SfiaPlan" name="SfiaPlanIssueBadgeConfirmationButtonOk" contentText="OK" />
                    </Button>
                    <Button color="secondary" onClick={this.handleCancelIssueBadge} disabled={this.state.actionPlanUpdating}>
                        <ContentTranslator page="SfiaPlan" name="SfiaPlanIssueBadgeConfirmationButtonCancel" contentText="Cancel" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleRejectIssueBadge = () => {
        this.setState({ showActionPlanEdit: false, showRejectBadgeConfirmationDialog: true });
    };

    handleCancelRejectBadge = () => {
        this.setState({ showActionPlanEdit: true, showRejectBadgeConfirmationDialog: false });
    };

    handleConfirmRejectBadge = () => {
        const { editedAction } = this.state;

        this.setState({ actionPlanUpdating: true });
        this.props.onActionPlan.rejectBadgeManagedPeopleAction(editedAction.id, this.props.managedPeople.personB.email).then(() => {
            if (!this.props.actionPlanUpdating) {
                if (this.props.actionPlanUpdatingError) {
                    this.props.generateAlert("danger", this.props.actionPlanUpdatingError.errData.Message);
                } else {
                    this.setState({ showRejectBadgeConfirmationDialog: false });
                    this.props.handleActionPlanUpdated(this.props.updatedAction);
                    this.props.generateAlert("success", "Reject issue badge success");
                }
            }
            this.setState({ actionPlanUpdating: this.props.actionPlanUpdating });
        });
    };

    renderRejectBadgeConfirmation = () => {
        return (
            <Modal isOpen={this.state.showRejectBadgeConfirmationDialog}>
                <ModalHeader>
                    <ContentTranslator page="SfiaPlan" name="SfiaPlanRejectBadgeConfirmationTitle" contentText="Reject Issue Badge Confirmation" />
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        <ContentTranslator page="SfiaPlan" name="SfiaPlanRejectBadgeConfirmationNotes" contentText="Are you sure to reject badge issuance?" />
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmRejectBadge();
                        }}
                        disabled={this.state.actionPlanUpdating}
                    >
                        <ContentTranslator page="SfiaPlan" name="SfiaPlanRejectBadgeConfirmationButtonOk" contentText="OK" />
                    </Button>
                    <Button color="secondary" onClick={this.handleCancelRejectBadge} disabled={this.state.actionPlanUpdating}>
                        <ContentTranslator page="SfiaPlan" name="SfiaPlanRejectBadgeConfirmationButtonCancel" contentText="Cancel" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    renderEditActionPlan() {
        let isReadOnly = false;

        const authMode = this.props.authMode;
        let authModes = authMode.split(",").map((item) => item.trim());
        if (authModes.filter((it) => it != "Admin").length == 0) {
            isReadOnly = true;
        }

        const { editedAction, form, optionsActionSkills, optionProposeCapabilities, selectedSkills, pickedOtherSkills, selectedOtherSkills, showPopUpAsPromoteAction, showPopUpAsProposeAction, showPopUpAsViewProposedAction } = this.state;
        const { optionsActionTypes, lorProfiles, skillProfiles, showHiddenSkills, categories, lors, jobLorProfiles, jobSkillProfiles } = this.props;

        let editedOptionsActionTypes = [...optionsActionTypes];

        if (editedAction && !editedOptionsActionTypes.find((it) => it.name == editedAction.actionType)) {
            editedOptionsActionTypes.push({
                name: editedAction.actionType,
            });
        }

        if (editedAction && editedAction.status == "IssueBadge") {
            isReadOnly = true;
        }

        const availableSkillCodes = categories
            ? categories.flatMap((category, index) => {
                  return category.subCategories.flatMap((subCategory, index) => {
                      return subCategory.skills
                          .filter((it) => !it.isHidden)
                          .flatMap((skill, index) => {
                              return skill.skillsCode;
                          });
                  });
              })
            : [];

        let optionsStatus = [
            { label: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanStatusTodo", "Todo"), value: "ToDo" },
            { label: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanStatusInProgress", "In Progress"), value: "InProgress" },
            { label: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanStatusDone", "Done"), value: "Done - self verified" },
        ];

        if (editedAction && editedAction.interventionId && editedAction.actionType == "Accredited Badge Issuing Action" && editedAction.status == "IssueBadge" && !optionsStatus.find((it) => it.value == "IssueBadge")) {
            optionsStatus.push({ label: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanStatusIssueBadge", "Issue Badge"), value: "IssueBadge" });
        }

        let showIssueBadge = false;
        if (this.props.managedPeople && editedAction && editedAction.interventionId && editedAction.actionType == "Accredited Badge Issuing Action" && editedAction.status != "IssueBadge" && this.props.containerConfig && this.props.containerConfig.allowBadgesFromActions == true) {
            showIssueBadge = true;
        }

        const spreadedActionSkills = [].concat.apply(
            [],
            optionsActionSkills
                .filter((it) => it.skills.some((skill) => !skill.selected && !skill.actioned))
                .map((it, groupIndex) => {
                    return it.skills
                        .filter((skill) => !skill.selected && !skill.actioned && (showHiddenSkills || (!showHiddenSkills && availableSkillCodes.includes(skill.skillCode))))
                        .map((skill) => {
                            return {
                                groupIndex,
                                skillName: skill.skillType == "Lor" ? this.contentTranslator(this.props.contentTranslations, "Lors", skill.skillName, skill.skillName) : this.contentTranslator(this.props.contentTranslations, "Skills", skill.skillName, skill.skillName),
                                skillCode: skill.skillCode,
                                skillLabel:
                                    skill.skillType == "Lor"
                                        ? `${this.contentTranslator(this.props.contentTranslations, "Lors", skill.skillName, skill.skillName)} ${skill.skillLevel}`
                                        : `${this.contentTranslator(this.props.contentTranslations, "Skills", skill.skillName, skill.skillName)} ${skill.skillLevel}`,
                                skillLevel: skill.skillLevel,
                                skillType: skill.skillType,
                            };
                        });
                })
        );

        let updateProfileEvidenceProposalSkills = [];
        if (showPopUpAsViewProposedAction) {
            updateProfileEvidenceProposalSkills = editedAction && editedAction.updateProfileEvidenceProposalSkills ? editedAction.updateProfileEvidenceProposalSkills : [];
        }

        let updatedProposedSkills = 0;

        if (showPopUpAsProposeAction && form.proposeCapability.value) {
            let selectedCapabilityNumber = form.proposeCapability.value == "Competent" ? 1 : form.proposeCapability.value == "Proficient" ? 0.5 : form.proposeCapability.value == "Knowledge" ? 0.25 : 0;

            selectedSkills
                .filter(
                    (it) =>
                        !it.removed &&
                        ![...jobLorProfiles.map((it) => it.lorCode + it.level), ...jobSkillProfiles.map((it) => it.skillCode + it.level)].includes(it.skillCode + it.skillLevel) &&
                        (!showPopUpAsPromoteAction || (showPopUpAsPromoteAction && !it.removed)) &&
                        ((!showPopUpAsProposeAction && !showPopUpAsViewProposedAction) || ((showPopUpAsViewProposedAction || showPopUpAsProposeAction) && (it.skillType != "Lor" || !filterOutLoROnProposeAction)))
                )
                .forEach((skill) => {
                    if (skill.skillType == "Skill") {
                        const skillProfile = skillProfiles.find((it) => it.skillCode == skill.skillCode && it.level == skill.skillLevel && it.numericVal > 0);
                        if (skillProfile) {
                            if (selectedCapabilityNumber > skillProfile.numericVal) {
                                updatedProposedSkills++;
                            }
                        } else {
                            updatedProposedSkills++;
                        }
                    } else if (!filterOutLoROnProposeAction) {
                        if (lorProfiles.find((it) => it.lorCode == skill.skillCode)) {
                            const lorProfile = lorProfiles.filter((it) => it.lorCode == skill.skillCode).sort((a, b) => b.level - a.level)[0];
                            if (lorProfile.level < skill.skillLevel) {
                                updatedProposedSkills++;
                            }
                        }
                    }
                });

            if (jobLorProfiles) {
                jobLorProfiles
                    .filter(
                        (it) =>
                            selectedSkills.some((skill) => !skill.removed && skill.skillCode == it.lorCode && skill.skillLevel >= it.level) &&
                            ((!showPopUpAsPromoteAction && !showPopUpAsProposeAction && !showPopUpAsViewProposedAction && !filterOutLoROnProposeAction) ||
                                (showPopUpAsPromoteAction && !!selectedSkills.find((s) => s.skillCode == it.lorCode && s.skillLevel == it.level && !s.removed)) ||
                                (!filterOutLoROnProposeAction && (showPopUpAsProposeAction || showPopUpAsViewProposedAction)) ||
                                (filterOutLoROnProposeAction &&
                                    (showPopUpAsProposeAction || showPopUpAsViewProposedAction) &&
                                    !!selectedSkills.find((s) => s.skillCode == it.lorCode && s.skillLevel == it.level) &&
                                    !!updateProfileEvidenceProposalSkills.find((s) => s.code == it.lorCode && s.level == it.level)))
                    )
                    .forEach((jobLorProfile) => {
                        if (showPopUpAsProposeAction && !filterOutLoROnProposeAction) {
                            if (lorProfiles.find((it) => it.lorCode == jobLorProfile.lorCode)) {
                                const lorProfile = lorProfiles.filter((it) => it.lorCode == jobLorProfile.lorCode).sort((a, b) => b.level - a.level)[0];
                                if (lorProfile.level < jobLorProfile.level) {
                                    updatedProposedSkills++;
                                }
                            }
                        }
                    });
            }

            if (jobSkillProfiles) {
                jobSkillProfiles
                    .filter(
                        (it) =>
                            (showHiddenSkills || (!showHiddenSkills && availableSkillCodes.includes(it.skillCode))) &&
                            selectedSkills.some((skill) => !skill.removed && skill.skillCode == it.skillCode && skill.skillLevel == it.level) &&
                            ((!showPopUpAsPromoteAction && !showPopUpAsProposeAction && !showPopUpAsViewProposedAction) ||
                                (showPopUpAsPromoteAction && !!selectedSkills.find((s) => s.skillCode == it.skillCode && s.skillLevel == it.level && !s.removed)) ||
                                (showPopUpAsProposeAction && !!selectedSkills.find((s) => s.skillCode == it.skillCode && s.skillLevel == it.level)) ||
                                (showPopUpAsViewProposedAction && !!selectedSkills.find((s) => s.skillCode == it.skillCode && s.skillLevel == it.level) && updateProfileEvidenceProposalSkills.find((s) => s.code == it.skillCode && s.level == it.level)))
                    )
                    .forEach((jobSkillProfile) => {
                        if (showPopUpAsProposeAction) {
                            const skillProfile = skillProfiles.find((it) => it.skillCode == jobSkillProfile.skillCode && it.level == jobSkillProfile.level && it.numericVal > 0);
                            if (skillProfile) {
                                if (selectedCapabilityNumber > skillProfile.numericVal) {
                                    updatedProposedSkills++;
                                }
                            } else {
                                updatedProposedSkills++;
                            }
                        }
                    });
            }
        }

        return (
            <Modal isOpen={this.state.showActionPlanEdit} toggle={this.togleActionPlanEditModal} size={showPopUpAsProposeAction || showPopUpAsViewProposedAction ? "xl" : "lg"}>
                <ModalHeader toggle={this.togleActionPlanEditModal}>
                    {showPopUpAsPromoteAction ? (
                        <ContentTranslator page="SfiaPlan" name="SfiaPromoteActionTitle" contentText="Promote Action to Action List" />
                    ) : showPopUpAsProposeAction || showPopUpAsViewProposedAction ? (
                        <ContentTranslator page="SfiaPlan" name="SfiaProposeActionTitle" contentText="Proposed updates to person’s skill profile" />
                    ) : isReadOnly ? (
                        <ContentTranslator page="SfiaPlan" name="SfiaShowActionTitle" contentText="Action Detail" />
                    ) : (
                        <ContentTranslator page="SfiaPlan" name="SfiaEditActionTitle" contentText="Edit Action" />
                    )}
                </ModalHeader>
                <ModalBody>
                    {this.state.actionPlanUpdating ? (
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <Spinner size="lg" animation="border" role="status"></Spinner>
                        </div>
                    ) : (
                        <Form>
                            {!showPopUpAsPromoteAction && (
                                <FormGroup row>
                                    <Label sm={3} style={{ textAlign: "right" }}>
                                        <ContentTranslator page="SfiaPlan" name="SfiaActionStatus" contentText="Status" />
                                    </Label>
                                    <Col sm={9}>
                                        <Combobox
                                            onSelect={(value) =>
                                                this.handleChange({
                                                    target: {
                                                        name: "status",
                                                        value: value.value,
                                                    },
                                                })
                                            }
                                            data={optionsStatus}
                                            textField="label"
                                            dataKey="value"
                                            value={optionsStatus.find((it) => it.value == form.status.value)}
                                            defaultValue={optionsStatus.find((it) => it.value == form.status.value)}
                                            style={{ fontSize: "14px", fontFamily: "IBMPlexSans" }}
                                            readOnly={showPopUpAsProposeAction || showPopUpAsViewProposedAction || isReadOnly}
                                        />
                                    </Col>
                                </FormGroup>
                            )}
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaPlan" name="SfiaActionType" contentText="Type" />
                                </Label>
                                <Col sm={9}>
                                    <Combobox
                                        onSelect={(value) =>
                                            this.handleChange({
                                                target: {
                                                    name: "actionType",
                                                    value: value.name,
                                                },
                                            })
                                        }
                                        value={editedOptionsActionTypes.find((it) => it.name == form.actionType.value)}
                                        data={editedOptionsActionTypes}
                                        textField="name"
                                        dataKey="name"
                                        defaultValue={editedOptionsActionTypes.find((it) => it.name == form.actionType.value)}
                                        style={{ fontSize: "14px", fontFamily: "IBMPlexSans" }}
                                        readOnly={showPopUpAsPromoteAction || showPopUpAsProposeAction || showPopUpAsViewProposedAction || isReadOnly || (editedAction && editedAction.interventionId)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaPlan" name="SfiaActionName" contentText="Name" />
                                </Label>
                                <Col sm={9}>
                                    <Input
                                        type="text"
                                        name="name"
                                        id={"name"}
                                        value={form.name.value}
                                        invalid={form.name.touched && !form.name.valid}
                                        onChange={this.handleChange}
                                        disabled={showPopUpAsPromoteAction || showPopUpAsProposeAction || showPopUpAsViewProposedAction || isReadOnly || (editedAction && editedAction.interventionId)}
                                    />
                                </Col>
                            </FormGroup>
                            {!showPopUpAsPromoteAction && (
                                <FormGroup row>
                                    <Label sm={3} style={{ textAlign: "right" }}>
                                        <ContentTranslator page="SfiaPlan" name="SfiaActionTarget" contentText="Target" />
                                    </Label>
                                    <Col sm={9}>
                                        <DatePicker
                                            selected={form.targetCompletionOn.value ? new Date(form.targetCompletionOn.value) : null}
                                            dateFormat="dd/MM/yyyy"
                                            disabled={showPopUpAsProposeAction || showPopUpAsViewProposedAction || isReadOnly}
                                            onChange={(value) => this.handleChangeDate("targetCompletionOn", value)}
                                            className="form-control"
                                            isClearable={!(showPopUpAsProposeAction || showPopUpAsViewProposedAction || isReadOnly)}
                                        />
                                        {form && !form.targetCompletionOn.valid && <span style={{ color: "#ff0000", fontSize: "12px" }}>Target date is mandatory</span>}
                                    </Col>
                                </FormGroup>
                            )}
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaPlan" name="SfiaActionDescription" contentText="Description" />
                                </Label>
                                <Col sm={9}>
                                    <Input type="textarea" name="description" id={"inputDescription"} value={form.description.value} onChange={this.handleChange} disabled={showPopUpAsPromoteAction || showPopUpAsProposeAction || showPopUpAsViewProposedAction || isReadOnly} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaPlan" name="SfiaActionWebsite" contentText="Website" />
                                </Label>
                                <Col sm={9}>
                                    <Input type="text" name="link" id={"inputLink"} value={form.link.value} onChange={this.handleChange} disabled={showPopUpAsPromoteAction || showPopUpAsProposeAction || showPopUpAsViewProposedAction || isReadOnly} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaPlan" name="SfiaActionDuration" contentText="Duration" />
                                </Label>
                                <Label sm={1} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaPlan" name="SfiaPlanDays" contentText="Days" />
                                </Label>
                                <Col sm={3}>
                                    <Input
                                        type="text"
                                        name="duration"
                                        id={"inputDuration"}
                                        invalid={form.duration.touched && (!form.duration.valid || !form.duration.isValidFormat)}
                                        value={form.duration.value}
                                        onChange={this.handleChange}
                                        disabled={showPopUpAsPromoteAction || showPopUpAsProposeAction || showPopUpAsViewProposedAction || isReadOnly}
                                    />
                                </Col>
                                <Label sm={1} style={{ textAlign: "right" }}>
                                    <ContentTranslator page="SfiaPlan" name="SfiaPlanHours" contentText="Hours" />
                                </Label>
                                <Col sm={4}>
                                    <Input
                                        type="text"
                                        name="durationHours"
                                        id={"inputDurationHours"}
                                        invalid={form.durationHours.touched && (!form.durationHours.valid || !form.durationHours.isValidFormat)}
                                        value={form.durationHours.value}
                                        onChange={this.handleChange}
                                        disabled={showPopUpAsPromoteAction || showPopUpAsProposeAction || showPopUpAsViewProposedAction || isReadOnly}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} style={{ textAlign: "right" }}>
                                    {showPopUpAsProposeAction || showPopUpAsViewProposedAction ? (
                                        <ContentTranslator page="SfiaPlan" name="SfiaActionProposedSkills" contentText="Select skills to be updated or added to the profile" />
                                    ) : (
                                        <ContentTranslator page="SfiaPlan" name="SfiaActionGaps" contentText="Gaps" />
                                    )}
                                </Label>
                                <Col sm={9}>
                                    {!showPopUpAsViewProposedAction ? (
                                        <React.Fragment>
                                            {selectedSkills
                                                .filter(
                                                    (it) =>
                                                        ![...jobLorProfiles.map((it) => it.lorCode + it.level), ...jobSkillProfiles.map((it) => it.skillCode + it.level)].includes(it.skillCode + it.skillLevel) &&
                                                        (!showPopUpAsPromoteAction || (showPopUpAsPromoteAction && !it.removed)) &&
                                                        ((!showPopUpAsProposeAction && !showPopUpAsViewProposedAction && !isReadOnly) || showPopUpAsProposeAction || showPopUpAsViewProposedAction || isReadOnly) &&
                                                        (it.skillType != "Lor" || !filterOutLoROnProposeAction)
                                                )
                                                .map((selectedSkill, index) => {
                                                    let currentCapability;

                                                    if (showPopUpAsProposeAction) {
                                                        if (selectedSkill.skillType == "Skill") {
                                                            const skillProfile = skillProfiles.find((it) => it.skillCode == selectedSkill.skillCode && it.level == selectedSkill.skillLevel && it.numericVal > 0);
                                                            if (skillProfile) {
                                                                currentCapability = skillProfile.stringVal == "M" ? "Competent" : skillProfile.stringVal == "P" ? "Proficient" : skillProfile.stringVal == "NA" ? "Knowledge" : "Missing";
                                                            } else {
                                                                currentCapability = "Missing";
                                                            }
                                                        } else if (!filterOutLoROnProposeAction) {
                                                            if (lorProfiles.find((it) => it.lorCode == selectedSkill.skillCode)) {
                                                                const lorProfile = lorProfiles.filter((it) => it.lorCode == selectedSkill.skillCode).sort((a, b) => b.level - a.level)[0];
                                                                if (lorProfile.level >= selectedSkill.skillLevel) {
                                                                    currentCapability = "Competent";
                                                                } else {
                                                                    currentCapability = "Missing";
                                                                }
                                                            }
                                                        }
                                                    }

                                                    let tooltipDescription = "";
                                                    if (selectedSkill.skillType == "Skill") {
                                                        const category = categories.find((it) => it.subCategories.some((subCategory) => subCategory.skills.some((it) => it.name == selectedSkill.skillName && it.skillsCode == selectedSkill.skillCode)));
                                                        const subCategory = category && category.subCategories.find((it) => it.skills.some((it) => it.name == selectedSkill.skillName && it.skillsCode == selectedSkill.skillCode));
                                                        const skill = subCategory && subCategory.skills.find((it) => it.name == selectedSkill.skillName && it.skillsCode == selectedSkill.skillCode);
                                                        const skillLevel = skill && skill.levels.find((it) => it.level == selectedSkill.skillLevel);

                                                        let skillLevelDescription = skillLevel ? skillLevel.description : "";
                                                        tooltipDescription = this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${selectedSkill.skillCode} ${selectedSkill.skillLevel} Description`, skillLevelDescription);
                                                    } else {
                                                        const lor = lors.find((it) => it.lorCode == selectedSkill.skillCode);
                                                        const lorLevel = lor ? lor.levels.find((x) => x.levelNumber == selectedSkill.skillLevel) : null;

                                                        if (lorLevel) {
                                                            tooltipDescription = this.contentTranslator(this.props.contentTranslations, "LorLevels", `${lor.name} ${lorLevel.levelName} Description`, lorLevel.description);
                                                        }
                                                    }

                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div id={`divChkSkill${selectedSkill.id}`} style={{ float: "left" }}>
                                                                <CustomInput
                                                                    type="checkbox"
                                                                    className={showPopUpAsProposeAction || showPopUpAsViewProposedAction ? "edit-propose-action-checkbox" : "edit-action-260-checkbox"}
                                                                    name={`chkSkill${selectedSkill.id}`}
                                                                    id={`chkSkill${selectedSkill.id}`}
                                                                    checked={!selectedSkill.removed}
                                                                    label={
                                                                        <div style={{ paddingTop: "0px", marginTop: "-2px" }}>
                                                                            {selectedSkill.skillCode}
                                                                            {selectedSkill.skillLevel}
                                                                            {" - "}
                                                                            {selectedSkill.skillType == "Skill"
                                                                                ? this.contentTranslator(this.props.contentTranslations, "Skills", selectedSkill.skillName, selectedSkill.skillName)
                                                                                : this.contentTranslator(this.props.contentTranslations, "Lors", selectedSkill.skillName, selectedSkill.skillName)}{" "}
                                                                            {selectedSkill.skillLevel}
                                                                            {showPopUpAsProposeAction && currentCapability ? ` - ${currentCapability}` : ""}
                                                                        </div>
                                                                    }
                                                                    onChange={() => this.handleSkillCheckedChange(selectedSkill.skillName, selectedSkill.skillCode, selectedSkill.skillLevel, selectedSkill.skillType)}
                                                                    disabled={showPopUpAsPromoteAction || showPopUpAsViewProposedAction || isReadOnly || (editedAction && editedAction.interventionId)}
                                                                    inline
                                                                />
                                                            </div>
                                                            <UncontrolledTooltip
                                                                target={`divChkSkill${selectedSkill.id}`}
                                                                placement="bottom"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: tooltipDescription,
                                                                }}
                                                            ></UncontrolledTooltip>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            {jobLorProfiles &&
                                                jobLorProfiles
                                                    .filter(
                                                        (it) =>
                                                            (!it.actioned || selectedSkills.some((skill) => skill.skillCode == it.lorCode && skill.skillLevel >= it.level)) &&
                                                            ((!showPopUpAsPromoteAction && !showPopUpAsProposeAction && !showPopUpAsViewProposedAction && !isReadOnly && !filterOutLoROnProposeAction) ||
                                                                (showPopUpAsPromoteAction && !!selectedSkills.find((s) => s.skillCode == it.lorCode && s.skillLevel == it.level && !s.removed)) ||
                                                                (!filterOutLoROnProposeAction && (showPopUpAsProposeAction || showPopUpAsViewProposedAction || isReadOnly) && !!selectedSkills.find((s) => s.skillCode == it.lorCode && s.skillLevel == it.level)))
                                                    )
                                                    .sort((a, b) => (a.lorCode + a.level > b.lorCode + b.level ? 1 : -1))
                                                    .map((jobLorProfile, index) => {
                                                        let currentCapability;

                                                        if (showPopUpAsProposeAction && !filterOutLoROnProposeAction) {
                                                            if (lorProfiles.find((it) => it.lorCode == jobLorProfile.lorCode)) {
                                                                const lorProfile = lorProfiles.filter((it) => it.lorCode == jobLorProfile.lorCode).sort((a, b) => b.level - a.level)[0];
                                                                if (lorProfile.level >= jobLorProfile.level) {
                                                                    currentCapability = "Competent";
                                                                } else {
                                                                    currentCapability = "Missing";
                                                                }
                                                            }
                                                        }

                                                        const lor = lors.find((it) => it.lorCode == jobLorProfile.lorCode);
                                                        const lorLevel = lor ? lor.levels.find((x) => x.levelNumber == jobLorProfile.level) : null;

                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div id={`divChkLor${jobLorProfile.id}${jobLorProfile.lorCode}${jobLorProfile.level}`} style={{ float: "left" }}>
                                                                    <CustomInput
                                                                        type="checkbox"
                                                                        className={showPopUpAsProposeAction || showPopUpAsViewProposedAction ? "edit-propose-action-checkbox" : "edit-action-260-checkbox"}
                                                                        name={`chkLor${jobLorProfile.id}${jobLorProfile.lorCode}${jobLorProfile.level}`}
                                                                        id={`chkLor${jobLorProfile.id}${jobLorProfile.lorCode}${jobLorProfile.level}`}
                                                                        checked={!!selectedSkills.find((it) => it.skillCode == jobLorProfile.lorCode && it.skillLevel == jobLorProfile.level && !it.removed)}
                                                                        label={
                                                                            <div style={{ paddingTop: "0px", marginTop: "-2px" }}>
                                                                                {jobLorProfile.lorCode}
                                                                                {jobLorProfile.level}
                                                                                {" - "}
                                                                                {this.contentTranslator(this.props.contentTranslations, "Lors", jobLorProfile.lor, jobLorProfile.lor)} {jobLorProfile.level}
                                                                                {showPopUpAsProposeAction && currentCapability ? ` - ${currentCapability}` : ""}
                                                                            </div>
                                                                        }
                                                                        onChange={() => this.handleSkillCheckedChange(jobLorProfile.lor, jobLorProfile.lorCode, jobLorProfile.level, "Lor")}
                                                                        disabled={showPopUpAsPromoteAction || showPopUpAsViewProposedAction || isReadOnly || (editedAction && editedAction.interventionId)}
                                                                        inline
                                                                    />
                                                                </div>
                                                                <UncontrolledTooltip target={`divChkLor${jobLorProfile.id}${jobLorProfile.lorCode}${jobLorProfile.level}`} placement="bottom">
                                                                    {lorLevel && <ContentTranslator page="LorLevels" name={`${lor.name} ${lorLevel.levelName} Description`} contentText={lorLevel.description} />}
                                                                </UncontrolledTooltip>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                            {jobSkillProfiles &&
                                                jobSkillProfiles
                                                    .filter(
                                                        (it) =>
                                                            (showHiddenSkills || (!showHiddenSkills && availableSkillCodes.includes(it.skillCode))) &&
                                                            (!it.actioned || selectedSkills.some((skill) => skill.skillCode == it.skillCode && skill.skillLevel == it.level)) &&
                                                            ((!showPopUpAsPromoteAction && !showPopUpAsProposeAction && !showPopUpAsViewProposedAction && !isReadOnly) ||
                                                                (showPopUpAsPromoteAction && !!selectedSkills.find((s) => s.skillCode == it.skillCode && s.skillLevel == it.level && !s.removed)) ||
                                                                ((isReadOnly || showPopUpAsProposeAction) && !!selectedSkills.find((s) => s.skillCode == it.skillCode && s.skillLevel == it.level)))
                                                    )
                                                    .sort((a, b) => (a.skillCode + a.level > b.skillCode + b.level ? 1 : -1))
                                                    .map((jobSkillProfile, index) => {
                                                        let currentCapability;

                                                        if (showPopUpAsProposeAction) {
                                                            const skillProfile = skillProfiles.find((it) => it.skillCode == jobSkillProfile.skillCode && it.level == jobSkillProfile.level && it.numericVal > 0);
                                                            if (skillProfile) {
                                                                currentCapability = skillProfile.stringVal == "M" ? "Competent" : skillProfile.stringVal == "P" ? "Proficient" : skillProfile.stringVal == "NA" ? "Knowledge" : "Missing";
                                                            } else {
                                                                currentCapability = "Missing";
                                                            }
                                                        }

                                                        const category = categories.find((it) => it.subCategories.some((subCategory) => subCategory.skills.some((it) => it.name == jobSkillProfile.skill && it.skillsCode == jobSkillProfile.skillCode)));
                                                        const subCategory = category && category.subCategories.find((it) => it.skills.some((it) => it.name == jobSkillProfile.skill && it.skillsCode == jobSkillProfile.skillCode));
                                                        const skill = subCategory && subCategory.skills.find((it) => it.name == jobSkillProfile.skill && it.skillsCode == jobSkillProfile.skillCode);
                                                        const skillLevel = skill && skill.levels.find((it) => it.level == jobSkillProfile.level);

                                                        let skillLevelDescription = skillLevel ? skillLevel.description : "";
                                                        skillLevelDescription = this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${jobSkillProfile.skillCode} ${jobSkillProfile.level} Description`, skillLevelDescription);

                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div id={`divChkLor${jobSkillProfile.id}${jobSkillProfile.skillCode}${jobSkillProfile.level}`} style={{ float: "left" }}>
                                                                    <CustomInput
                                                                        type="checkbox"
                                                                        className={showPopUpAsProposeAction || showPopUpAsViewProposedAction ? "edit-propose-action-checkbox" : "edit-action-260-checkbox"}
                                                                        name={`chkLor${jobSkillProfile.id}${jobSkillProfile.skillCode}${jobSkillProfile.level}`}
                                                                        id={`chkLor${jobSkillProfile.id}${jobSkillProfile.skillCode}${jobSkillProfile.level}`}
                                                                        checked={!!selectedSkills.find((it) => it.skillCode == jobSkillProfile.skillCode && it.skillLevel == jobSkillProfile.level && !it.removed)}
                                                                        label={
                                                                            <div style={{ paddingTop: "0px", marginTop: "-2px" }}>
                                                                                {jobSkillProfile.skillCode}
                                                                                {jobSkillProfile.level}
                                                                                {" - "}
                                                                                {this.contentTranslator(this.props.contentTranslations, "Skills", jobSkillProfile.skill, jobSkillProfile.skill)} {jobSkillProfile.level}
                                                                                {showPopUpAsProposeAction && currentCapability ? ` - ${currentCapability}` : ""}
                                                                            </div>
                                                                        }
                                                                        onChange={() => this.handleSkillCheckedChange(jobSkillProfile.skill, jobSkillProfile.skillCode, jobSkillProfile.level, "Skill")}
                                                                        disabled={showPopUpAsPromoteAction || showPopUpAsViewProposedAction || isReadOnly || (editedAction && editedAction.interventionId)}
                                                                        inline
                                                                    />
                                                                </div>
                                                                <UncontrolledTooltip
                                                                    target={`divChkLor${jobSkillProfile.id}${jobSkillProfile.skillCode}${jobSkillProfile.level}`}
                                                                    placement="bottom"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: skillLevelDescription,
                                                                    }}
                                                                ></UncontrolledTooltip>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {editedAction &&
                                                editedAction.updateProfileEvidenceProposalSkills &&
                                                editedAction.updateProfileEvidenceProposalSkills.map((proposedSkill, index) => {
                                                    let tooltipDescription = "";
                                                    if (proposedSkill.type == "Skill") {
                                                        const category = categories.find((it) => it.subCategories.some((subCategory) => subCategory.skills.some((it) => it.name == proposedSkill.name && it.skillsCode == proposedSkill.code)));
                                                        const subCategory = category && category.subCategories.find((it) => it.skills.some((it) => it.name == proposedSkill.name && it.skillsCode == proposedSkill.code));
                                                        const skill = subCategory && subCategory.skills.find((it) => it.name == proposedSkill.name && it.skillsCode == proposedSkill.code);
                                                        const skillLevel = skill && skill.levels.find((it) => it.level == proposedSkill.level);

                                                        let skillLevelDescription = skillLevel ? skillLevel.description : "";
                                                        tooltipDescription = this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${proposedSkill.code} ${proposedSkill.level} Description`, skillLevelDescription);
                                                    } else {
                                                        const lor = lors.find((it) => it.lorCode == proposedSkill.code);
                                                        const lorLevel = lor ? lor.levels.find((x) => x.levelNumber == proposedSkill.level) : null;

                                                        if (lorLevel) {
                                                            tooltipDescription = this.contentTranslator(this.props.contentTranslations, "LorLevels", `${lor.name} ${lorLevel.levelName} Description`, lorLevel.description);
                                                        }
                                                    }

                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div id={`divChkProposedSkill${proposedSkill.id}`} style={{ float: "left" }}>
                                                                <CustomInput
                                                                    type="checkbox"
                                                                    className={"edit-propose-action-checkbox"}
                                                                    name={`chkProposedSkill${proposedSkill.id}`}
                                                                    id={`chkProposedSkill${proposedSkill.id}`}
                                                                    checked={true}
                                                                    label={
                                                                        <div style={{ paddingTop: "0px", marginTop: "-2px" }}>
                                                                            {proposedSkill.type == "Skill"
                                                                                ? `${proposedSkill.code}${proposedSkill.level} - ${this.contentTranslator(this.props.contentTranslations, "Skills", proposedSkill.name, proposedSkill.name)} ${proposedSkill.level}${
                                                                                      proposedSkill.currentCapability ? ` - ${proposedSkill.currentCapability}` : ""
                                                                                  }`
                                                                                : `${proposedSkill.code}${proposedSkill.level} - ${this.contentTranslator(this.props.contentTranslations, "Lors", proposedSkill.name, proposedSkill.name)} ${proposedSkill.level}${
                                                                                      proposedSkill.currentCapability ? ` - ${proposedSkill.currentCapability}` : ""
                                                                                  }`}
                                                                        </div>
                                                                    }
                                                                    onChange={() => this.handleSkillCheckedChange(jobSkillProfile.skill, jobSkillProfile.skillCode, jobSkillProfile.level, "Skill")}
                                                                    disabled={showPopUpAsPromoteAction || showPopUpAsViewProposedAction}
                                                                    inline
                                                                />
                                                            </div>
                                                            <UncontrolledTooltip
                                                                target={`divChkProposedSkill${proposedSkill.id}`}
                                                                placement="bottom"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: tooltipDescription,
                                                                }}
                                                            ></UncontrolledTooltip>
                                                        </React.Fragment>
                                                    );
                                                })}
                                        </React.Fragment>
                                    )}
                                </Col>
                            </FormGroup>
                            {!showPopUpAsPromoteAction && !showPopUpAsProposeAction && !showPopUpAsViewProposedAction && !isReadOnly && !(editedAction && editedAction.interventionId) && (
                                <FormGroup row>
                                    <Label sm={3} style={{ textAlign: "right" }}>
                                        <ContentTranslator page="SfiaPlan" name="SfiaActionOtherSkills" contentText="Other skills" />
                                    </Label>
                                    <Col sm={9}>
                                        <Row>
                                            {pickedOtherSkills && pickedOtherSkills.length > 0 && (
                                                <Col sm={12}>
                                                    {pickedOtherSkills.map((pickedSkill, index) => {
                                                        let tooltipDescription = "";

                                                        if (pickedSkill.skillType == "Skill") {
                                                            const category = categories.find((it) => it.subCategories.some((subCategory) => subCategory.skills.some((it) => it.name == pickedSkill.skillName && it.skillsCode == pickedSkill.skillCode)));
                                                            const subCategory = category && category.subCategories.find((it) => it.skills.some((it) => it.name == pickedSkill.skillName && it.skillsCode == pickedSkill.skillCode));
                                                            const skill = subCategory && subCategory.skills.find((it) => it.name == pickedSkill.skillName && it.skillsCode == pickedSkill.skillCode);
                                                            const skillLevel = skill && skill.levels.find((it) => it.level == pickedSkill.skillLevel);

                                                            let skillLevelDescription = skillLevel ? skillLevel.description : "";
                                                            tooltipDescription = this.contentTranslator(this.props.contentTranslations, "SkillLevels", `${pickedSkill.skillCode} ${pickedSkill.skillLevel} Description`, skillLevelDescription);
                                                        } else {
                                                            const lor = lors.find((it) => it.lorCode == pickedSkill.skillCode);
                                                            const lorLevel = lor ? lor.levels.find((x) => x.levelNumber == pickedSkill.skillLevel) : null;

                                                            if (lorLevel) {
                                                                tooltipDescription = this.contentTranslator(this.props.contentTranslations, "LorLevels", `${lor.name} ${lorLevel.levelName} Description`, lorLevel.description);
                                                            }
                                                        }

                                                        return (
                                                            <React.Fragment key={index}>
                                                                <div id={`divChkOtherSkill${pickedSkill.skillCode}${pickedSkill.skillLevel}`} style={{ float: "left" }}>
                                                                    <CustomInput
                                                                        type="checkbox"
                                                                        className="edit-action-260-checkbox"
                                                                        name={`chkOtherSkill${pickedSkill.skillCode}${pickedSkill.skillLevel}`}
                                                                        id={`chkOtherSkill${pickedSkill.skillCode}${pickedSkill.skillLevel}`}
                                                                        checked={!!selectedOtherSkills.find((it) => it.skillCode == pickedSkill.skillCode && it.skillLevel == pickedSkill.skillLevel)}
                                                                        label={
                                                                            <div style={{ paddingTop: "0px", marginTop: "-2px" }}>
                                                                                {pickedSkill.skillCode}
                                                                                {pickedSkill.skillLevel}
                                                                                {" - "}
                                                                                {pickedSkill.skillType == "Skill"
                                                                                    ? this.contentTranslator(this.props.contentTranslations, "Skills", pickedSkill.skillName, pickedSkill.skillName)
                                                                                    : this.contentTranslator(this.props.contentTranslations, "Lors", pickedSkill.skillName, pickedSkill.skillName)}{" "}
                                                                                {pickedSkill.skillLevel}
                                                                            </div>
                                                                        }
                                                                        onChange={() => this.handleOtherSkillCheckedChange(pickedSkill.skillName, pickedSkill.skillCode, pickedSkill.skillLevel, pickedSkill.skillType)}
                                                                        inline
                                                                    />
                                                                </div>
                                                                <UncontrolledTooltip
                                                                    target={`divChkOtherSkill${pickedSkill.skillCode}${pickedSkill.skillLevel}`}
                                                                    placement="bottom"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: tooltipDescription,
                                                                    }}
                                                                ></UncontrolledTooltip>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                    <div style={{ clear: "both", paddingTop: "15px" }}></div>
                                                </Col>
                                            )}
                                            <Col sm={9}>
                                                <Combobox
                                                    data={spreadedActionSkills.sort((a, b) => a.groupIndex - b.groupIndex)}
                                                    onSelect={(value) =>
                                                        this.handleChangeOtherSkill({
                                                            target: {
                                                                value: value.skillCode + "-" + value.skillLevel,
                                                            },
                                                        })
                                                    }
                                                    onChange={(value) => {
                                                        value == "" &&
                                                            this.handleChangeOtherSkill({
                                                                target: {
                                                                    value: "",
                                                                },
                                                            });
                                                    }}
                                                    textField="skillLabel"
                                                    groupBy="skillName"
                                                    filter="contains"
                                                    placeholder="Select skill"
                                                    autoSelectMatches={true}
                                                    value={spreadedActionSkills.find((value) => value.skillCode + "-" + value.skillLevel == this.state.selectedOtherSkill)}
                                                    style={{ fontSize: "14px", fontFamily: "IBMPlexSans", width: "100%" }}
                                                />
                                            </Col>
                                            <Col sm={3}>
                                                <ButtonGroup>
                                                    <Button onClick={() => this.handleAddOtherSkill()} className="btn btn-white" disabled={!(this.state.selectedOtherSkill && this.state.selectedOtherSkill != "")}>
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </Button>
                                                </ButtonGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </FormGroup>
                            )}
                            {!showPopUpAsPromoteAction && (showPopUpAsProposeAction || showPopUpAsViewProposedAction) && (
                                <React.Fragment>
                                    <FormGroup row>
                                        <Label sm={3} style={{ textAlign: "right" }}>
                                            <ContentTranslator page="SfiaPlan" name="SfiaActionProposedCapability" contentText="Select capability to be applied" />
                                        </Label>
                                        <Col sm={9}>
                                            <Combobox
                                                onSelect={(value) =>
                                                    this.handleChange({
                                                        target: {
                                                            name: "proposeCapability",
                                                            value: value.name,
                                                        },
                                                    })
                                                }
                                                value={optionProposeCapabilities.find((it) => it.name == form.proposeCapability.value)}
                                                data={optionProposeCapabilities}
                                                textField="name"
                                                dataKey="name"
                                                defaultValue={optionProposeCapabilities.find((it) => it.name == form.proposeCapability.value)}
                                                style={{ fontSize: "14px", fontFamily: "IBMPlexSans" }}
                                                readOnly={showPopUpAsPromoteAction || showPopUpAsViewProposedAction}
                                            />
                                        </Col>
                                    </FormGroup>
                                    {showPopUpAsProposeAction && form.proposeCapability.value && (
                                        <FormGroup row>
                                            <Label sm={3} style={{ textAlign: "right" }}>
                                                &nbsp;
                                            </Label>
                                            <Label sm={9}>{`Based on your selection ${updatedProposedSkills} skill(s) will be updated or added`}</Label>
                                        </FormGroup>
                                    )}
                                </React.Fragment>
                            )}
                            {!showPopUpAsPromoteAction && !showPopUpAsProposeAction && !showPopUpAsViewProposedAction && !isReadOnly && (
                                <FormGroup row>
                                    <Col sm={3}></Col>
                                    <Col sm={9}>
                                        <div style={{ float: "left" }}>
                                            <Button color="danger" onClick={this.handleSubmitDelete}>
                                                <FontAwesomeIcon icon={faTrash} /> <ContentTranslator page="SfiaPlan" name="SfiaPlanEditButtonDelete" contentText="Delete" />
                                            </Button>
                                            {showIssueBadge && (
                                                <React.Fragment>
                                                    {editedAction && editedAction.issueBadgeRejected == false && (
                                                        <React.Fragment>
                                                            {"   "}
                                                            <Button color="primary" onClick={this.handleSubmitIssueBadge}>
                                                                <ContentTranslator page="SfiaPlan" name="SfiaPlanEditButtonIssueBadge" contentText="Issue Badge" />
                                                            </Button>
                                                            {"   "}
                                                            <Button color="primary" onClick={this.handleRejectIssueBadge}>
                                                                <ContentTranslator page="SfiaPlan" name="SfiaPlanEditButtonRejectBadge" contentText="Reject Badge" />
                                                            </Button>
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </div>
                                        {showIssueBadge && editedAction && editedAction.issueBadgeRejected == true && (
                                            <div style={{ float: "left", paddingLeft: "10px" }}>
                                                <div>
                                                    <div style={{ color: "#721c24", backgroundColor: "#f8d7da", borderColor: "#f5c6cb", padding: "7px 15px", borderRadius: "0.25rem" }}>
                                                        <ContentTranslator page="SfiaPlan" name="SfiaPlanIssueBadgeRejected" contentText="Issue badge rejected" />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Col>
                                </FormGroup>
                            )}
                            {showPopUpAsPromoteAction && !showPopUpAsProposeAction && !showPopUpAsViewProposedAction && !isReadOnly && (
                                <FormGroup row>
                                    <Col
                                        sm={12}
                                        dangerouslySetInnerHTML={{
                                            __html: this.contentTranslator(
                                                this.props.contentTranslations,
                                                "SfiaPlan",
                                                "SfiaPlanPromoteFooterNote",
                                                "This is to request that your action is promoted to the Action List. It will firstly be assessed and approved before it appears.<br />Check your awards for progress"
                                            ),
                                        }}
                                    ></Col>
                                </FormGroup>
                            )}
                        </Form>
                    )}
                </ModalBody>
                <ModalFooter>
                    {!showPopUpAsPromoteAction && !showPopUpAsProposeAction && !showPopUpAsViewProposedAction && !isReadOnly && (
                        <Button color="primary" onClick={this.handleSubmitEdit} disabled={!this.state.isFormValid}>
                            <ContentTranslator page="SfiaPlan" name="SfiaPlanEditButtonSave" contentText="Save" />
                        </Button>
                    )}
                    {showPopUpAsPromoteAction && !showPopUpAsProposeAction && !showPopUpAsViewProposedAction && (
                        <React.Fragment>
                            <Button id="btnEditPromote" color="primary" onClick={this.handleSubmitPromote} disabled={!(this.state.isFormValid && (selectedSkills.filter((it) => !it.id || (it.id && !it.removed)).length > 0 || selectedOtherSkills.length > 0))}>
                                <ContentTranslator page="SfiaPlan" name="SfiaPlanEditButtonPromote" contentText="Promote" />
                            </Button>
                            <UncontrolledTooltip target={`btnEditPromote`} placement="bottom">
                                <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanEditButtonPromoteTooltip" contentText="Process this Action Promotion" />
                            </UncontrolledTooltip>
                        </React.Fragment>
                    )}
                    {showPopUpAsProposeAction && !showPopUpAsPromoteAction && !showPopUpAsViewProposedAction && (
                        <React.Fragment>
                            <Button id="btnEditPropose" color="primary" onClick={this.handleSubmitPropose} disabled={!(this.state.isFormValid && updatedProposedSkills > 0 && (selectedSkills.filter((it) => !it.id || (it.id && !it.removed)).length > 0 || selectedOtherSkills.length > 0))}>
                                <ContentTranslator page="SfiaPlan" name="SfiaPlanEditButtonProcess" contentText="Process" />
                            </Button>
                            <UncontrolledTooltip target={`btnEditPropose`} placement="bottom">
                                <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanEditButtonProcessTooltip" contentText="By processing this request the person will be notified of your proposed updates to their profile" />
                            </UncontrolledTooltip>
                        </React.Fragment>
                    )}
                    <Button color="secondary" onClick={this.togleActionPlanEditModal}>
                        <ContentTranslator page="SfiaPlan" name="SfiaPlanEditButtonClose" contentText="Close" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    handleSubmitPromote = () => {
        this.setState({ showActionPlanEdit: false, showActionPromotionConfirmationDialog: true });
    };

    handleCancelSubmitPromote = () => {
        this.setState({ showActionPlanEdit: true, showActionPromotionConfirmationDialog: false });
    };

    handleConfirmSubmitPromote = () => {
        const { editedAction } = this.state;

        this.setState({ actionPlanUpdating: true });
        this.props.onActionPlan.submitActionPromotion(editedAction.id, this.state.form.description.value).then(() => {
            if (!this.props.actionPlanUpdating) {
                if (this.props.actionPlanUpdatingError) {
                    this.props.generateAlert("danger", this.props.actionPlanUpdatingError.errData.Message);
                } else {
                    this.setState({ showActionPromotionConfirmationDialog: false, selectedSkills: [] });
                    this.props.generateAlert("success", "Action promotion request submitted");
                }
            }
            this.setState({ actionPlanUpdating: this.props.actionPlanUpdating });
        });
    };

    renderActionPromotionConfirmation = () => {
        return (
            <Modal isOpen={this.state.showActionPromotionConfirmationDialog}>
                <ModalHeader>
                    <ContentTranslator page="SfiaPlan" name="SfiaPlanPromoteActionConfirmationTitle" contentText="Promote Action Confirmation" />
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        <ContentTranslator page="SfiaPlan" name="SfiaPlanPromoteActionConfirmationNotes" contentText="Your action will be sent for approval before appearing in the action list." />
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmSubmitPromote();
                        }}
                        disabled={this.state.actionPlanUpdating}
                    >
                        <ContentTranslator page="SfiaPlan" name="SfiaPlanPromoteActionConfirmationButtonOk" contentText="OK" />
                    </Button>
                    <Button color="secondary" onClick={this.handleCancelSubmitPromote} disabled={this.state.actionPlanUpdating}>
                        <ContentTranslator page="SfiaPlan" name="SfiaPlanPromoteActionConfirmationButtonCancel" contentText="Cancel" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleSubmitPropose = () => {
        this.setState({ showActionPlanEdit: false, showProposeActionConfirmationDialog: true });
    };

    handleCancelSubmitPropose = () => {
        this.setState({ showActionPlanEdit: true, showProposeActionConfirmationDialog: false });
    };

    handleConfirmSubmitPropose = () => {
        const { editedAction, selectedSkills, selectedOtherSkills, form } = this.state;
        const { lorProfiles, skillProfiles } = this.props;

        const updateProfileEvidenceProposalSkills = [...selectedSkills.filter((it) => (!filterOutLoROnProposeAction || (filterOutLoROnProposeAction && it.skillType != "Lor")) && !it.removed), ...selectedOtherSkills.filter((it) => !it.removed)].map((skill) => {
            let currentCapability;
            if (skill.skillType == "Skill") {
                const skillProfile = skillProfiles.find((it) => it.skillCode == skill.skillCode && it.level == skill.skillLevel && it.numericVal > 0);
                if (skillProfile) {
                    currentCapability = skillProfile.stringVal == "M" ? "Competent" : skillProfile.stringVal == "P" ? "Proficient" : skillProfile.stringVal == "NA" ? "Knowledge" : "Missing";
                } else {
                    currentCapability = "Missing";
                }
            } else {
                if (lorProfiles.find((it) => it.lorCode == skill.skillCode)) {
                    const lorProfile = lorProfiles.filter((it) => it.lorCode == skill.skillCode).sort((a, b) => b.level - a.level)[0];
                    if (lorProfile.level >= skill.skillLevel) {
                        currentCapability = "Competent";
                    } else {
                        currentCapability = "Missing";
                    }
                }
            }

            return {
                name: skill.skillName,
                code: skill.skillCode,
                level: skill.skillLevel,
                type: skill.skillType,
                capability: form.proposeCapability.value,
                currentCapability: currentCapability,
            };
        });

        this.setState({ actionPlanUpdating: true });
        this.props.onActionPlan.submitProposeAction(editedAction.id, this.props.managedPeople && this.props.managedPeople.personB.email, updateProfileEvidenceProposalSkills).then(() => {
            if (!this.props.actionPlanUpdating) {
                if (this.props.actionPlanUpdatingError) {
                    this.props.generateAlert("danger", this.props.actionPlanUpdatingError.errData.Message);
                } else {
                    this.setState({ showProposeActionConfirmationDialog: false, selectedSkills: [], proposeActionMode: false });
                    this.props.generateAlert("success", "Update profile and evidence from action proposal submitted");
                }
            }
            this.setState({ actionPlanUpdating: this.props.actionPlanUpdating });
        });
    };

    renderProposeActionConfirmation = () => {
        return (
            <Modal isOpen={this.state.showProposeActionConfirmationDialog}>
                <ModalHeader>
                    <ContentTranslator page="SfiaPlan" name="SfiaPlanProposeActionConfirmationTitle" contentText="Propose Action Confirmation" />
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>
                                        <ContentTranslator page="SfiaPlan" name="SfiaPlanProposeActionConfirmationNotes" contentText="By processing these proposed profile updates the person will be notified via email and will be able to accept or reject the changes.<br />Are you Sure?" />
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmSubmitPropose();
                        }}
                        disabled={this.state.actionPlanUpdating}
                    >
                        <ContentTranslator page="SfiaPlan" name="SfiaPlanProposeActionConfirmationButtonOk" contentText="OK" />
                    </Button>
                    <Button color="secondary" onClick={this.handleCancelSubmitPropose} disabled={this.state.actionPlanUpdating}>
                        <ContentTranslator page="SfiaPlan" name="SfiaPlanProposeActionConfirmationButtonCancel" contentText="Cancel" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleChangeSortOption(event) {
        const { name, value } = event.target;
        const { optionSorts } = this.state;
        optionSorts.forEach((option) => {
            if (option.field == value) {
                option.selected = true;
            } else {
                option.selected = false;
            }
        });

        this.setState({
            selectedSortOption: value,
            optionSorts,
        });
    }

    handleChangeSortDirection() {
        const { selectedSortOptionDirection } = this.state;

        this.setState({
            selectedSortOptionDirection: selectedSortOptionDirection == "asc" ? "desc" : "asc",
        });
    }

    sortActions = (actions) => {
        const { selectedSortOption, selectedSortOptionDirection } = this.state;

        if (selectedSortOption == "targetCompletionOn") {
            if (selectedSortOptionDirection == "asc") {
                actions = actions.sort((a, b) => new Date(a.targetCompletionOn) - new Date(b.targetCompletionOn));
            } else {
                actions = actions.sort((b, a) => new Date(a.targetCompletionOn) - new Date(b.targetCompletionOn));
            }
        } else {
            actions = _.orderBy(actions, selectedSortOption, selectedSortOptionDirection);
        }

        return actions;
    };

    handleUpdateActionHidden = (action) => {
        this.setState({ actionPlanUpdating: true });
        if (action.hidden) {
            this.props.onActionPlan.updateUnHideAction(action.id).then(() => {
                if (!this.props.actionPlanUpdating) {
                    if (this.props.actionPlanUpdatingError) {
                        this.props.generateAlert("danger", this.props.actionPlanUpdatingError.errData.Message);
                    } else {
                        this.props.handleActionPlanHidden(this.props.updatedAction);
                    }
                }
                this.setState({ actionPlanUpdating: this.props.actionPlanUpdating });
            });
        } else {
            this.props.onActionPlan.updateHideAction(action.id).then(() => {
                if (!this.props.actionPlanUpdating) {
                    if (this.props.actionPlanUpdatingError) {
                        this.props.generateAlert("danger", this.props.actionPlanUpdatingError.errData.Message);
                    } else {
                        this.props.handleActionPlanHidden(this.props.updatedAction);
                    }
                }
                this.setState({ actionPlanUpdating: this.props.actionPlanUpdating });
            });
        }
    };

    renderTodos = (actions, showDone, showHidden, proposeMode, viewProposedMode) => {
        let isAdmin = false;

        const authMode = this.props.authMode;
        let authModes = authMode.split(",").map((item) => item.trim());
        if (authModes.filter((it) => it != "Admin").length == 0) {
            isAdmin = true;
        }

        const rows = actions
            .filter((it) =>
                proposeMode
                    ? it.status.toLowerCase().includes("done") && !it.updateProfileEvidenceProposed
                    : viewProposedMode
                    ? it.status.toLowerCase().includes("done") && it.updateProfileEvidenceProposed && it.updateProfileEvidenceProposalStatus == "Requested"
                    : (showDone ? it.id : !it.status.toLowerCase().includes("done") && it.status != "IssueBadge") && (showHidden ? it.id : !it.hidden)
            )
            .map((action, index) => {
                const row = action;
                const skills = action.skills;
                const updateProfileEvidenceProposalSkills = action.updateProfileEvidenceProposalSkills;

                return (
                    <React.Fragment key={index}>
                        <ContentWrapperLg>
                            <Container style={{ fontSize: "13px", fontFamily: "IBMPlexSans" }}>
                                <Row xs="1" sm="1" md="2">
                                    <Col className="p-0" style={{ color: "#000000" }}>
                                        <Row className="align-items-center">
                                            <Col xs="2" sm="3" md="3" style={{ fontFamily: "IBMPlexSans-Bold", fontSize: "13px" }}>
                                                {row.status &&
                                                    (row.status == "ToDo"
                                                        ? this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanStatusTodo", "Todo")
                                                        : row.status == "InProgress"
                                                        ? this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanStatusInProgress", "In Progress")
                                                        : row.status.toLowerCase().includes("done")
                                                        ? this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanStatusDone", "Done")
                                                        : row.status == "IssueBadge"
                                                        ? this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanStatusIssueBadge", "Issue Badge")
                                                        : row.status)}
                                            </Col>
                                            <Col>
                                                <p className="m-0">
                                                    {row.link ? (
                                                        <Link to={{ pathname: row.link }} target="_blank">
                                                            {row.name}
                                                        </Link>
                                                    ) : (
                                                        row.name
                                                    )}
                                                    <br />
                                                    {viewProposedMode
                                                        ? updateProfileEvidenceProposalSkills && updateProfileEvidenceProposalSkills.length > 0
                                                            ? updateProfileEvidenceProposalSkills.map((it, index) => {
                                                                  return (
                                                                      <React.Fragment key={index}>
                                                                          <span style={{ whiteSpace: "nowrap" }}>
                                                                              {`${it.code}-${it.level}`}
                                                                              {index < updateProfileEvidenceProposalSkills.length - 1 && ","}
                                                                          </span>{" "}
                                                                      </React.Fragment>
                                                                  );
                                                              })
                                                            : row.description
                                                        : skills && skills.length > 0
                                                        ? skills.map((it, index) => {
                                                              return (
                                                                  <React.Fragment key={index}>
                                                                      <span style={{ whiteSpace: "nowrap" }}>
                                                                          {`${it.code}-${it.level}`}
                                                                          {index < skills.length - 1 && ","}
                                                                      </span>{" "}
                                                                  </React.Fragment>
                                                              );
                                                          })
                                                        : row.description}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="p-0 align-self-center">
                                        <Row className=" align-items-center">
                                            <Col xs="3">
                                                <span style={{ color: "#000000" }}>{row.actionType}</span>
                                            </Col>
                                            <Col xs="2">
                                                <span style={{ color: "#777777" }}>
                                                    <ContentTranslator page="SfiaPlan" name="SfiaPlanListDuration" contentText="Duration:" />{" "}
                                                </span>
                                                <span style={{ color: "#000000" }}>
                                                    {row.duration || row.durationHours ? (
                                                        <React.Fragment>
                                                            {row.duration ? <div>{this.parameterizedString(this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanDuration", "{s1} Days"), row.duration)}</div> : ""}
                                                            {row.durationHours ? <div>{this.parameterizedString(this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanDurationHours", "{s1} Hours"), row.durationHours)}</div> : ""}
                                                        </React.Fragment>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </span>
                                            </Col>
                                            <Col xs="4">
                                                {row.createdOn && (
                                                    <React.Fragment>
                                                        <span style={{ color: "#777777" }}>
                                                            <ContentTranslator page="SfiaPlan" name="SfiaPlanListCreated" contentText="Created:" />{" "}
                                                        </span>
                                                        <span style={{ color: "#000000" }}>
                                                            {new Intl.DateTimeFormat("en-GB", {
                                                                year: "2-digit",
                                                                month: "short",
                                                                day: "numeric",
                                                            }).format(new Date(row.createdOn))}
                                                        </span>
                                                        <br />
                                                    </React.Fragment>
                                                )}
                                                {row.targetCompletionOn && (
                                                    <React.Fragment>
                                                        <span style={{ color: "#777777" }}>
                                                            <ContentTranslator page="SfiaPlan" name="SfiaPlanListDue" contentText="Due:" />{" "}
                                                        </span>
                                                        <span style={{ color: "#000000" }}>
                                                            {new Intl.DateTimeFormat("en-GB", {
                                                                year: "2-digit",
                                                                month: "short",
                                                                day: "numeric",
                                                            }).format(new Date(row.targetCompletionOn))}
                                                        </span>
                                                    </React.Fragment>
                                                )}
                                            </Col>
                                            <Col xs="3" style={{ textAlign: "center" }}>
                                                {proposeMode ? (
                                                    <React.Fragment>
                                                        <button id={`btnProposeAction${row.id}`} style={{ height: "32px", minWidth: "85px" }} className="btn btn-light btnSecondary-Style" onClick={() => this.openActionPlanEditModal(row, false, true, false)}>
                                                            <ContentTranslator page="SfiaPlan" name="SfiaPlanListButtonProcess" contentText="Process" />
                                                        </button>
                                                        <UncontrolledTooltip target={`btnProposeAction${row.id}`} placement="bottom">
                                                            <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanListButtonProcessTooltip" contentText="This will propose updates to the users Skill Profile based on the skills addressed by the Action and if/when accepted create Evidence." />
                                                        </UncontrolledTooltip>
                                                    </React.Fragment>
                                                ) : viewProposedMode ? (
                                                    <React.Fragment>
                                                        <button id={`btnViewProposedAction${row.id}`} style={{ height: "32px", minWidth: "85px" }} className="btn btn-light btnSecondary-Style" onClick={() => this.openActionPlanEditModal(row, false, false, true)}>
                                                            <ContentTranslator page="SfiaPlan" name="SfiaPlanListButtonView" contentText="View" />
                                                        </button>
                                                        <UncontrolledTooltip target={`btnViewProposedAction${row.id}`} placement="bottom">
                                                            <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanListButtonViewTooltip" contentText="View proposed action" />
                                                        </UncontrolledTooltip>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        {isAdmin || row.status == "IssueBadge" ? (
                                                            <React.Fragment>
                                                                <button id={`btnEditAction${row.id}`} style={{ height: "32px", minWidth: "85px" }} className="btn btn-light btnSecondary-Style" onClick={() => this.openActionPlanEditModal(row, false, false, false)}>
                                                                    <ContentTranslator page="SfiaPlan" name="SfiaPlanListButtonShow" contentText="Show" />
                                                                </button>
                                                                <UncontrolledTooltip target={`btnEditAction${row.id}`} placement="bottom">
                                                                    <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanListButtonShowTooltip" contentText="Show this Action detail" />
                                                                </UncontrolledTooltip>
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                <button id={`btnEditAction${row.id}`} style={{ height: "32px", minWidth: "85px" }} className="btn btn-light btnSecondary-Style" onClick={() => this.openActionPlanEditModal(row, false, false, false)}>
                                                                    <ContentTranslator page="SfiaPlan" name="SfiaPlanListButtonEdit" contentText="Edit" />
                                                                </button>
                                                                <UncontrolledTooltip target={`btnEditAction${row.id}`} placement="bottom">
                                                                    <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanListButtonEditTooltip" contentText="Edit this Action" />
                                                                </UncontrolledTooltip>
                                                            </React.Fragment>
                                                        )}

                                                        {!this.props.managedPeople && (
                                                            <React.Fragment>
                                                                <br />
                                                                <button id={`btnHideAction${row.id}`} style={{ height: "32px", minWidth: "85px", marginTop: "3px" }} className="btn btn-light btnSecondary-Style" onClick={() => this.handleUpdateActionHidden(row)}>
                                                                    {row.hidden ? <ContentTranslator page="SfiaPlan" name="SfiaPlanListButtonUnhide" contentText="Unhide" /> : <ContentTranslator page="SfiaPlan" name="SfiaPlanListButtonHide" contentText="Hide" />}
                                                                </button>
                                                                <UncontrolledTooltip target={`btnHideAction${row.id}`} placement="bottom">
                                                                    {row.hidden ? (
                                                                        <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanListButtonUnhideTooltip" contentText="Make this Action UNHIDDEN in your Action list" />
                                                                    ) : (
                                                                        <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanListButtonHideTooltip" contentText="Make this Action HIDDEN in your Action list" />
                                                                    )}
                                                                </UncontrolledTooltip>
                                                            </React.Fragment>
                                                        )}
                                                        {!isAdmin && this.props.containerConfig && this.props.containerConfig.enablePromoteAction == true && !row.interventionId && (
                                                            <React.Fragment>
                                                                <br />
                                                                <button
                                                                    id={`btnPromote${row.id}`}
                                                                    style={{ height: "32px", minWidth: "85px", marginTop: "3px", color: row.promoted ? "#FFFFFF" : "#000000" }}
                                                                    className="btn btn-light btnSecondary-Style"
                                                                    onClick={() => this.openActionPlanEditModal(row, true, false, false)}
                                                                    disabled={row.promoted ? true : false}
                                                                >
                                                                    <ContentTranslator page="SfiaPlan" name="SfiaPlanListButtonPromote" contentText="Promote" />
                                                                </button>
                                                                <UncontrolledTooltip target={`btnPromote${row.id}`} placement="bottom">
                                                                    <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanListButtonPromoteTooltip" contentText="Open the promote pop-up for this Action to start the promotion process" />
                                                                </UncontrolledTooltip>
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </ContentWrapperLg>
                    </React.Fragment>
                );
            });

        return rows;
    };

    handleChangeFilterDone = () => {
        const showDone = this.state.showDone;
        this.setState({ showDone: !showDone });
    };

    handleChangeFilterHidden = () => {
        const showHidden = this.state.showHidden;
        this.setState({ showHidden: !showHidden });
    };

    handleStartProposeActionMode = () => {
        this.setState({ proposeActionMode: true });
        this.props.handleProposeActionModeStarted(true);
    };

    togleProposeActionMode = () => {
        const proposeActionMode = this.state.proposeActionMode;
        this.setState({ proposeActionMode: !proposeActionMode });
        this.props.handleProposeActionModeStarted(!proposeActionMode);
    };

    handleStartViewProposedActionMode = () => {
        this.setState({ viewProposedActionMode: true });
    };

    togleViewProposedActionMode = () => {
        const viewProposedActionMode = this.state.viewProposedActionMode;
        this.setState({ viewProposedActionMode: !viewProposedActionMode });
    };

    render() {
        let isAdmin = false;

        const authMode = this.props.authMode;
        let authModes = authMode.split(",").map((item) => item.trim());
        if (authModes.filter((it) => it != "Admin").length == 0) {
            isAdmin = true;
        }

        const optionSorts = [
            {
                label: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanSortByCompletionDate", "Target Completion Date"),
                field: "targetCompletionOn",
                selected: true,
            },
            {
                label: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanSortByCreatedDate", "Created Date"),
                field: "createdOn",
            },
            {
                label: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanSortByStatus", "Status"),
                field: "status",
            },
            {
                label: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanSortByType", "Type"),
                field: "actionType",
            },
        ];

        let { actions } = this.props;
        actions = this.sortActions(actions);

        const { showDone, showHidden } = this.state;

        if (this.props.loadingAction) return <p></p>;
        return (
            <React.Fragment>
                {this.modalActionPlanDetail()}
                {this.renderEditActionPlan()}
                {this.renderActionPromotionConfirmation()}
                {this.renderProposeActionConfirmation()}
                {this.renderIssueBadgeConfirmation()}
                {this.renderRejectBadgeConfirmation()}
                <div style={{ clear: "both" }}></div>
                <Container>
                    <Row style={{ padding: "10px 0px" }}>
                        <Col xs="12" md="6">
                            {actions.filter((it) => it.status.toLowerCase().includes("done") || it.status == "IssueBadge").length > 0 && (
                                <React.Fragment>
                                    <button id="btnShowCompleted" className="btn btn-light btnSecondary-Style" style={{ marginLeft: "10px", marginBottom: "5px" }} onClick={this.handleChangeFilterDone} disabled={this.state.proposeActionMode || this.state.viewProposedActionMode}>
                                        {showDone ? (
                                            <ContentTranslator page="SfiaPlan" name="SfiaPlanButtonHideCompleted" contentText="Showing All Actions – Hide Completed?" />
                                        ) : (
                                            <ContentTranslator page="SfiaPlan" name="SfiaPlanButtonShowCompleted" contentText="You have completed Actions – Show All?" />
                                        )}
                                    </button>
                                    <UncontrolledTooltip target={`btnShowCompleted`} placement="bottom">
                                        {showDone ? (
                                            <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanButtonHideCompletedTooltip" contentText="Exclude and hide your Completed Actions from the list below" />
                                        ) : (
                                            <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanButtonShowCompletedTooltip" contentText="Include and show your Completed Actions in the list below" />
                                        )}
                                    </UncontrolledTooltip>
                                </React.Fragment>
                            )}
                            {actions.filter((it) => it.hidden).length > 0 && (
                                <React.Fragment>
                                    <button id="btnShowHidden" className="btn btn-light btnSecondary-Style" style={{ marginLeft: "10px", marginBottom: "5px" }} onClick={this.handleChangeFilterHidden} disabled={this.state.proposeActionMode || this.state.viewProposedActionMode}>
                                        {showHidden ? <ContentTranslator page="SfiaPlan" name="SfiaPlanButtonHideHidden" contentText="Showing All Actions – Hide hidden?" /> : <ContentTranslator page="SfiaPlan" name="SfiaPlanButtonShowHidden" contentText="You have hidden Actions – Show All?" />}
                                    </button>
                                    <UncontrolledTooltip target={`btnShowHidden`} placement="bottom">
                                        {showHidden ? (
                                            <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanButtonHideHiddenTooltip" contentText="Exclude your Hidden Actions from the list below" />
                                        ) : (
                                            <ContentTranslator page="SfiaPlanTooltip" name="SfiaPlanButtonShowHiddenTooltip" contentText="Include your Hidden Actions in the list below" />
                                        )}
                                    </UncontrolledTooltip>
                                </React.Fragment>
                            )}
                            {this.props.managedPeople && this.props.actionPlanUpdated && (
                                <React.Fragment>
                                    <button className="btn btn-light btnSecondary-Style" style={{ marginLeft: "10px", marginBottom: "5px" }} onClick={this.props.handleSendUpdatedActionPlanNotification}>
                                        <ContentTranslator page="SfiaPlan" name="SfiaPlanButtonSendNotificationPlanUpdated" contentText="Action Plan Updated – Send Notification?" />
                                    </button>
                                </React.Fragment>
                            )}
                            {this.props.managedPeople &&
                                !isAdmin &&
                                this.props.containerConfig &&
                                this.props.containerConfig.allowUpdateProfileAndEvidenceFromAction &&
                                actions.filter((it) => it.status.toLowerCase().includes("done") && it.updateProfileEvidenceProposed == true && it.updateProfileEvidenceProposalStatus == "Requested").length == 0 && (
                                    <React.Fragment>
                                        <button className="btn btn-light btnSecondary-Style" style={{ marginLeft: "10px", marginBottom: "5px" }} disabled={this.state.proposeActionMode} onClick={this.handleStartProposeActionMode}>
                                            <ContentTranslator page="SfiaPlan" name="SfiaPlanButtonProposeMode" contentText="Propose Profile update and add Evidence from Actions" />
                                        </button>
                                        {this.state.proposeActionMode && (
                                            <button className="btn btn-light btnSecondary-Style" style={{ marginLeft: "10px", marginBottom: "5px" }} onClick={this.togleProposeActionMode}>
                                                <ContentTranslator page="SfiaPlan" name="SfiaPlanButtonCancelProposeMode" contentText="Cancel" />
                                            </button>
                                        )}
                                    </React.Fragment>
                                )}
                            {this.props.managedPeople &&
                                this.props.containerConfig &&
                                this.props.containerConfig.allowUpdateProfileAndEvidenceFromAction &&
                                actions.filter((it) => it.status.toLowerCase().includes("done") && it.updateProfileEvidenceProposed == true && it.updateProfileEvidenceProposalStatus == "Requested").length > 0 && (
                                    <React.Fragment>
                                        <button className="btn btn-light btnSecondary-Style" style={{ marginLeft: "10px", marginBottom: "5px" }} onClick={this.handleStartViewProposedActionMode} disabled={this.state.viewProposedActionMode}>
                                            <ContentTranslator page="SfiaPlan" name="SfiaPlanButtonViewProposedMode" contentText="Show current active proposed profile update" />
                                        </button>
                                        {this.state.viewProposedActionMode && (
                                            <button className="btn btn-light btnSecondary-Style" style={{ marginLeft: "10px", marginBottom: "5px" }} onClick={this.togleViewProposedActionMode}>
                                                <ContentTranslator page="SfiaPlan" name="SfiaPlanButtonCancelViewProposedMode" contentText="Cancel" />
                                            </button>
                                        )}
                                    </React.Fragment>
                                )}
                        </Col>
                        <Col xs="12" md="6">
                            <Row style={{ background: "#FFFFFF", padding: "10px", borderRadius: "10px" }}>
                                <Col xs="3">
                                    <ContentTranslator page="SfiaPlan" name="SfiaPlanSortBy" contentText="Sort by :" />
                                </Col>
                                <Col xs="7">
                                    <Input type="select" onChange={this.handleChangeSortOption} value={this.state.selectedSortOption}>
                                        {optionSorts.map((optionSort, index) => (
                                            <option key={index} value={`${optionSort.field}`}>
                                                {optionSort.label}
                                            </option>
                                        ))}
                                    </Input>
                                </Col>
                                <Col xs="2">
                                    <Button onClick={() => this.handleChangeSortDirection()} className="btn btn-primary">
                                        <FontAwesomeIcon icon={this.state.selectedSortOptionDirection == "asc" ? faArrowDown : faArrowUp} />
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6"></Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                {this.renderTodos(actions, showDone, showHidden, this.state.proposeActionMode, this.state.viewProposedActionMode)}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        actionPlanUpdating: state.actionPlan.actionPlanUpdating,
        actionPlanUpdatingError: state.actionPlan.actionPlanUpdatingError,
        updatedAction: state.actionPlan.addedAction,

        managedPeople: state.auth.managedPeople,
        authMode: state.auth.authMode,
        contentTranslations: state.content.contentTranslations,
        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onActionPlan: bindActionCreators(actionPlanActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Todo);
