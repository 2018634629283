import * as actionPlanActions from "../../store/actions/actionPlanAction";
import * as authActions from "../../store/actions/authAction";
import * as categoryActions from "../../store/actions/categoryAction";
import * as companyActions from "../../store/actions/companyAction";
import * as configActions from "../../store/actions/configAction";
import * as contentActions from "../../store/actions/contentAction";
import * as jobActions from "../../store/actions/jobAction";
import * as lorActions from "../../store/actions/lorAction";
import * as personActions from "../../store/actions/personAction";
import * as skillProfileActions from "../../store/actions/skillProfileAction";
import { dismisAlert, generateAlert } from "../../utils/alertUtils";
import { parameterizedString } from "../../utils/stringUtils";
import { contentTranslator } from "../../utils/translatorUtils";
import StepProfile from "../profile/StepProfile";
import ContentTranslator from "../translator/ContentTranslator";
import ManageActionPlan from "./ManageActionPlan";
import Todo from "./Todo";
import React from "react";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Button, Col, Container, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

const step1 = process.env.REACT_APP_ACTIONPLAN_STEP_1;
const step2 = process.env.REACT_APP_ACTIONPLAN_STEP_2;
const step3 = process.env.REACT_APP_ACTIONPLAN_STEP_3;

const step1Hover = process.env.REACT_APP_ACTIONPLAN_STEP_1_HOVER;
const step2Hover = process.env.REACT_APP_ACTIONPLAN_STEP_2_HOVER;
const step3Hover = process.env.REACT_APP_ACTIONPLAN_STEP_3_HOVER;

class ActionPlan extends React.Component {
    static displayName = ActionPlan.name;

    constructor(props) {
        super(props);
        this.state = {
            stepActionClicked: false,
            stepData: {
                step1: {
                    desc: step1,
                    hover: step1Hover,
                },
                step2: {
                    desc: step2,
                    hover: step2Hover,
                },
                step3: {
                    desc: step3,
                    hover: step3Hover,
                },
                currentStep: null,
            },
            userResponsibilityData: null,
            skillActionPlanData: null,
            actionPlanDetailData: null,
            optionsProfile: [],
            categories: [],
            lors: [],
            extraFrameworkCapabilities: [],
            lorProfiles: [],
            skillProfiles: [],
            optionsJob: [],
            selectedprofileType: null,
            jobLorProfiles: [],
            jobSkillProfiles: [],
            selectedJob: null,
            loading: false,
            contentLoading: true,

            actionLoading: true,
            actions: [],
            optionsActionSkills: this.props.actionSkills,
            optionsActionTypes: this.props.actionTypes,
            alerts: [],
            providers: [],

            loadingAdd: true,
            loadingProfile: true,

            actionPlanUpdated: false,
            listRelationshipType: ["Line Manager", "Mentor"],
            showHiddenSkills: false,
            proposeActionStarted: false,
        };

        this.initData = this.initData.bind(this);
        this.handleActionPlanAdded = this.handleActionPlanAdded.bind(this);
        this.handleActionPlanUpdated = this.handleActionPlanUpdated.bind(this);
        this.handleActionPlanDeleted = this.handleActionPlanDeleted.bind(this);

        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
        this.parameterizedString = parameterizedString.bind(this);
    }

    componentDidMount() {
        if (this.props.language) {
            this.props.onContent.getContentTranslations(this.props.language.id, "SfiaAll-Menu-SfiaProfileMenu-SfiaPlan-SfiaPlanTooltip-Lors-Skills").then(() => {
                this.setState({ contentLoading: this.props.contentLoading });

                const stepData = {
                    step1: {
                        desc: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanStep1Description", step1),
                        hover: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanStep1Hover", step1Hover),
                    },
                    step2: {
                        desc: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanStep2Description", step2),
                        hover: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanStep2Hover", step2Hover),
                    },
                    step3: {
                        desc: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanStep3Description", step3),
                        hover: this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanStep3Hover", step3Hover),
                    },
                    currentStep: null,
                };

                this.setState({ stepData });

                this.props.onAuth.authCheckState().then(() => {
                    if (!this.props.currAssessment) {
                        this.props.onConfig.getCurrAssessment().then(() => {});
                    }

                    if (window.location.pathname.includes("/Manage")) {
                        const query = new URLSearchParams(this.props.location.search);
                        const email = query.get("email");

                        if (!this.props.managedPeople || !(this.props.managedPeople && this.props.managedPeople.personB.email.toLowerCase() == email.toLowerCase())) {
                            this.props.onPerson.getPersonMyPeopleByEmail(email).then(() => {
                                if (!this.props.personLoading && this.props.personManagedPeople) {
                                    this.props.onAuth.myPeopleSetManagedRelationship(this.props.personManagedPeople).then(() => {
                                        if (!this.props.personLoading && this.props.personManagedPeople) {
                                            this.initData();
                                        }
                                    });
                                }
                            });
                        } else {
                            this.initData();
                        }
                    } else {
                        this.initData();
                    }
                });
            });
        } else {
            this.props.onAuth.authCheckState().then(() => {
                if (window.location.pathname.includes("/Manage")) {
                    const query = new URLSearchParams(this.props.location.search);
                    const email = query.get("email");

                    if (!this.props.managedPeople || !(this.props.managedPeople && this.props.managedPeople.personB.email.toLowerCase() == email.toLowerCase())) {
                        this.props.onPerson.getPersonMyPeopleByEmail(email).then(() => {
                            if (!this.props.personLoading && this.props.personManagedPeople) {
                                this.props.onAuth.myPeopleSetManagedRelationship(this.props.personManagedPeople).then(() => {
                                    if (!this.props.personLoading && this.props.personManagedPeople) {
                                        this.initData();
                                    }
                                });
                            }
                        });
                    } else {
                        this.initData();
                    }
                } else {
                    this.initData();
                }
            });
        }
    }

    initData() {
        this.setState({ loading: true });
        Promise.resolve(this.props.managedPeople ? this.props.onSkillProfile.getManagedPeopleProfileTypes(this.props.managedPeople.personB.email) : this.props.onSkillProfile.getMyProfileTypes()).then(() => {
            if (this.props.errorProfile) {
                this.setState({ loading: false });
            } else {
                let profileType = null;

                if (this.props.profileTypes.length > 0) {
                    profileType = this.props.profileTypes[0];

                    this.setState({
                        optionsProfile: this.props.profileTypes.map((it) => {
                            return {
                                value: it,
                                label: this.contentTranslator(this.props.contentTranslations, "SfiaAll", it.replace(" ", ""), it),
                            };
                        }),
                        selectedprofileType: this.contentTranslator(this.props.contentTranslations, "SfiaAll", profileType.replace(" ", ""), profileType),
                    });
                }

                this.props.onPerson.getPersonSkillCoins().then(() => {});

                Promise.all([
                    this.props.managedPeople ? this.props.onJob.getManagedPeopleJobMatches(this.props.managedPeople.personB.email) : this.props.onJob.getMyJobMatches(),
                    this.props.onCategory.getExtraFrameworkCapabilities(),
                    this.props.onCategory.getCategories(),
                    this.props.onLor.getLors(),
                ]).then(() => {
                    if (!this.props.loading && !this.props.categoryLoading) {
                        let optionsJob = [];
                        let selectedJob = null;

                        const jobMatches = this.props.jobMatches;
                        const currentJob = jobMatches.find((it) => it.jobType === "Current");
                        const futureJob = jobMatches.find((it) => it.jobType === "Future");

                        if (currentJob) {
                            optionsJob.push({
                                value: currentJob.jobType + " - " + currentJob.jobTitle,
                                label: this.contentTranslator(this.props.contentTranslations, "SfiaAll", currentJob.jobType, currentJob.jobType) + " - " + currentJob.jobTitle,
                                type: currentJob.jobType,
                                id: currentJob.jobId,
                            });
                        }

                        if (futureJob) {
                            optionsJob.push({
                                value: futureJob.jobType + " - " + futureJob.jobTitle,
                                label: this.contentTranslator(this.props.contentTranslations, "SfiaAll", futureJob.jobType, futureJob.jobType) + " - " + futureJob.jobTitle,
                                type: futureJob.jobType,
                                id: futureJob.jobId,
                            });
                        }

                        if (optionsJob.length > 0) {
                            selectedJob = optionsJob[0];
                        }

                        this.setState({
                            optionsJob,
                            jobMatches,
                            currentJob,
                            futureJob,
                            selectedJob,
                            categories: this.props.categories,
                            lors: this.props.lors,
                            extraFrameworkCapabilities: this.props.extraFrameworkCapabilities,
                        });

                        Promise.all([
                            this.props.managedPeople ? this.props.onJob.getManagedPeopleJobLorProfiles(this.props.managedPeople.personB.email) : this.props.onJob.getJobLorProfiles(null),
                            this.props.managedPeople ? this.props.onJob.getManagedPeopleJobSkillProfiles(this.props.managedPeople.personB.email) : this.props.onJob.getJobSkillProfiles(null),
                            this.props.managedPeople ? this.props.onSkillProfile.getManagedPeopleLorProfile(this.props.managedPeople.personB.email) : this.props.onSkillProfile.getMyLorProfile(null, null, null),
                            this.props.managedPeople ? this.props.onSkillProfile.getManagedPeopleSkillProfile(this.props.managedPeople.personB.email) : this.props.onSkillProfile.getMySkillProfile(null, null, null),
                        ]).then(() => {
                            if (!this.props.jobLoading && !this.props.loadingProfile && !this.props.errorProfile && !this.props.loadingLorProfile && !this.props.errorLorProfile) {
                                this.setState({
                                    loadingProfile: this.props.jobLoading || this.props.loadingProfile || this.props.loadingLorProfile,
                                    jobLorProfiles: this.props.jobLorProfiles,
                                    jobSkillProfiles: this.props.jobSkillProfiles.filter((it) => it.stringVal != "N"),
                                    lorProfiles: this.props.lorProfiles,
                                    skillProfiles: this.props.skillProfiles,
                                });
                            }
                        });
                    }

                    this.setState({
                        loading: this.props.loading || this.props.categoryLoading,
                    });
                });

                Promise.all([this.props.managedPeople ? this.props.onActionPlan.getManagedPeopleActions(this.props.managedPeople.personB.email) : this.props.onActionPlan.getMyActions()]).then(() => {
                    this.setState({
                        actions: this.props.actions,
                        actionLoading: this.props.actionPlanloading,
                    });
                });

                Promise.all([this.props.onActionPlan.getActionTypes(), this.props.onActionPlan.getActionSkills(), this.props.onCompany.getSelectedProviderCompanies()]).then(() => {
                    if (!this.props.companyLoading && !this.props.actionPlanloading) {
                        this.setState({
                            optionsActionTypes: this.props.actionTypes,
                            optionsActionSkills: this.props.actionSkills,
                            providers: this.props.selectedProviders,
                        });
                    }

                    this.setState({
                        loadingAdd: this.props.companyLoading || this.props.actionPlanloading,
                    });
                });
            }
        });
    }

    handleActionPlanAdded(addedAction) {
        const { actions, categories } = this.state;

        addedAction.skills.forEach((actionSkill) => {
            const category = categories.find((it) => it.subCategories.some((subCategory) => subCategory.skills.some((skill) => skill.name == actionSkill.name)));

            if (category) {
                const level = category.subCategories
                    .find((subCategory) => subCategory.skills.some((skill) => skill.name == actionSkill.name))
                    .skills.find((skill) => skill.name == actionSkill.name && skill.skillsCode == actionSkill.code)
                    .levels.find((it) => it.level == actionSkill.level);

                if (level) {
                    level.action = addedAction;
                }
            }
        });

        this.setState({ actionPlanUpdated: true, actions: [...actions, addedAction] }, () => {
            this.generateAlert("success", "Action plan added");
        });
    }

    handleActionPlanUpdated(updatedAction) {
        const { actions, categories } = this.state;

        const editedAction = actions.find((it) => it.id == updatedAction.id);

        if (editedAction) {
            const updatedActionIndex = actions.indexOf(editedAction);
            const removedActionSkills = editedAction.skills.filter((it) => !updatedAction.skills.some((skill) => it.code == skill.code && it.level == skill.level));

            removedActionSkills.forEach((removedActionSkill) => {
                const category = categories.find((it) => it.subCategories.some((subCategory) => subCategory.skills.some((skill) => skill.name == removedActionSkill.name)));

                if (category) {
                    const level = category.subCategories
                        .find((subCategory) => subCategory.skills.some((skill) => skill.name == removedActionSkill.name))
                        .skills.find((skill) => skill.name == removedActionSkill.name && skill.skillsCode == removedActionSkill.code)
                        .levels.find((it) => it.level == removedActionSkill.level && it.action);

                    if (level) {
                        level.action = null;
                    }
                }
            });

            updatedAction.skills.forEach((actionSkill) => {
                const category = categories.find((it) => it.subCategories.some((subCategory) => subCategory.skills.some((skill) => skill.name == actionSkill.name)));

                if (category) {
                    const level = category.subCategories
                        .find((subCategory) => subCategory.skills.some((skill) => skill.name == actionSkill.name))
                        .skills.find((skill) => skill.name == actionSkill.name && skill.skillsCode == actionSkill.code)
                        .levels.find((it) => it.level == actionSkill.level);

                    if (level) {
                        level.action = updatedAction;
                    }
                }
            });

            actions[updatedActionIndex] = updatedAction;

            this.setState({
                actionPlanUpdated: true,
                actions: [...actions],
                categories: [...categories],
            });
        }
    }

    handleActionPlanHidden = (updatedAction) => {
        const { actions } = this.state;

        const editedAction = actions.find((it) => it.id == updatedAction.id);

        if (editedAction) {
            const updatedActionIndex = actions.indexOf(editedAction);
            actions[updatedActionIndex] = updatedAction;

            this.setState({
                actions: [...actions],
            });
        }
    };

    handleActionPlanDeleted(id) {
        const { actions, jobLorProfiles, jobSkillProfiles } = this.state;
        const deletedAction = actions.find((it) => it.id == id);

        if (deletedAction) {
            deletedAction.skills.forEach((actionSkill) => {
                jobLorProfiles.forEach((it) => {
                    if (it.actioned && it.lorCode == actionSkill.code) {
                        it.actioned = null;
                    }
                });

                jobSkillProfiles.forEach((it) => {
                    if (it.actioned && it.skillCode == actionSkill.code && it.level == actionSkill.level) {
                        it.actioned = null;
                    }
                });
            });

            this.setState(
                {
                    actionPlanUpdated: true,
                    jobLorProfiles,
                    jobSkillProfiles,
                    actions: [...actions.filter((it) => it.id != id)],
                },
                () => {
                    this.generateAlert("success", "Action plan deleted");
                }
            );
        }
    }

    handleChangeProfileType = (value) => {
        this.setState({
            loadingProfile: true,
            selectedprofileType: this.contentTranslator(this.props.contentTranslations, "SfiaAll", value.replace(" ", ""), value),
        });

        Promise.all([
            this.props.managedPeople ? this.props.onSkillProfile.getManagedPeopleLorProfile(this.props.managedPeople.personB.email, null, null, value) : this.props.onSkillProfile.getMyLorProfile(null, null, value),
            this.props.managedPeople ? this.props.onSkillProfile.getManagedPeopleSkillProfile(this.props.managedPeople.personB.email, null, null, value) : this.props.onSkillProfile.getMySkillProfile(null, null, value),
        ]).then(() => {
            this.setState({
                loadingProfile: this.props.loadingProfile || this.props.loadingLorProfile,
                lorProfiles: this.props.lorProfiles,
                skillProfiles: this.props.skillProfiles,
            });
        });
    };

    handleChangeJob = (job) => {
        this.setState({ selectedJob: job });
    };

    step1Clicked = () => {
        this.setState({ stepActionClicked: new Date() });
    };

    step2Clicked = () => {
        this.setState({ stepActionClicked: new Date() });
    };

    step3Clicked = () => {};

    setActionPlanUpdated = (actionPlanUpdated) => {
        this.setState({ actionPlanUpdated: actionPlanUpdated });
    };

    handleSendUpdatedActionPlanNotification = () => {
        this.setState({ showActionPlanUpdatedNotificationConfirmation: true });
    };

    handleConfirmSendActionPlanUpdatedNotification = () => {
        this.setState({ actionPlanUpdating: true });
        Promise.resolve(this.props.onActionPlan.sendUpdatedActionPlanNotification(this.props.managedPeople.personB.email)).then(() => {
            if (!this.props.actionPlanUpdating) {
                if (this.props.actionPlanUpdatingError) {
                    this.generateAlert("danger", this.props.actionPlanUpdatingError);
                } else {
                    this.generateAlert("success", "Notification email sent");
                    this.setState({
                        actionPlanUpdated: false,
                        showActionPlanUpdatedNotificationConfirmation: false,
                    });
                }
            }
            this.setState({ actionPlanUpdating: this.props.actionPlanUpdating });
        });
    };

    toggleSendActionPlanUpdatedNotificationConfirmation = () => {
        const showActionPlanUpdatedNotificationConfirmation = this.state.showActionPlanUpdatedNotificationConfirmation;

        this.setState({
            showActionPlanUpdatedNotificationConfirmation: !showActionPlanUpdatedNotificationConfirmation,
        });
    };

    renderSendActionPlanUpdatedNotificationConfirmation = () => {
        return (
            <Modal isOpen={this.state.showActionPlanUpdatedNotificationConfirmation} toggle={!this.props.actionPlanUpdating && this.toggleSendActionPlanUpdatedNotificationConfirmation}>
                <ModalHeader toggle={!this.props.actionPlanUpdating && this.toggleSendActionPlanUpdatedNotificationConfirmation}>Send Notification Confirmation</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col md={12} sm={12}>
                                <FormGroup row>
                                    <Label sm={12}>Are you sure you want to send a notification email?</Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.handleConfirmSendActionPlanUpdatedNotification();
                        }}
                        disabled={this.props.actionPlanUpdating}
                    >
                        OK
                    </Button>
                    <Button color="secondary" onClick={this.toggleSendActionPlanUpdatedNotificationConfirmation} disabled={this.props.actionPlanUpdating}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    handleChageShowHiddenSkills = (showHiddenSkills) => {
        this.setState({ showHiddenSkills: showHiddenSkills });
    };

    handleProposeActionModeStarted = (proposeActionStarted) => {
        this.setState({ showHiddenSkills: false, proposeActionStarted: proposeActionStarted });
    };

    render() {
        let isAdmin = false;

        const authMode = this.props.authMode;
        let authModes = authMode.split(",").map((item) => item.trim());
        if (authModes.filter((it) => it != "Admin").length == 0) {
            isAdmin = true;
        }

        let { jobLorProfiles, jobSkillProfiles, selectedJob } = this.state;
        jobLorProfiles = jobLorProfiles && selectedJob ? jobLorProfiles.filter((x) => x.jobId == selectedJob.id) : [];
        jobSkillProfiles = jobSkillProfiles && selectedJob ? jobSkillProfiles.filter((x) => x.jobId == selectedJob.id) : [];

        const { managedPeople } = this.props;

        return (
            <Container className="contentMain">
                {this.state.loading || this.state.contentLoading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        {(!managedPeople || (managedPeople && isAdmin) || (managedPeople && this.state.listRelationshipType.some((item) => item.toLocaleLowerCase().trim() === managedPeople.relationshipType?.toLocaleLowerCase().trim()))) && (
                            <div style={{ clear: "both" }}>
                                <div style={{ float: "left", paddingRight: "30px" }}>
                                    <p className={"page-title"}>
                                        {managedPeople
                                            ? this.parameterizedString(this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanManagedTitle", "Action Plan for {s1}"), `${managedPeople.personB.firstName} ${managedPeople.personB.lastName}`)
                                            : this.contentTranslator(this.props.contentTranslations, "SfiaPlan", "SfiaPlanTitle", "Your Action Plan")}
                                    </p>
                                </div>
                                {this.props.containerConfig && this.props.containerConfig.enablePromoteAction == true && this.props.skillCoins && this.props.skillCoins.length > 0 && this.props.skillCoins.find((it) => it.type == "Actions Promoted and Accepted") && (
                                    <div style={{ float: "left", paddingRight: "20px" }}>
                                        <div
                                            style={{
                                                float: "left",
                                                color: "#4dcaa4",
                                                backgroundColor: "#001c3d",
                                                padding: "1px 7px",
                                                borderRadius: "5px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {this.props.skillCoins.find((it) => it.type == "Actions Promoted and Accepted").coins}
                                        </div>
                                        <div
                                            style={{
                                                paddingTop: "5px",
                                                paddingLeft: "5px",
                                                float: "left",
                                                fontSize: "14px",
                                            }}
                                        >
                                            <ContentTranslator page="SfiaPlan" name="SfiaPlanActionPromotedCoins" contentText="Skillcoins for Actions Promoted and Accepted" />
                                        </div>
                                    </div>
                                )}
                                {this.props.containerConfig && this.props.containerConfig.enablePromoteAction == true && this.props.skillCoins && this.props.skillCoins.length > 0 && this.props.skillCoins.find((it) => it.type == "Promoted Action Usage") && (
                                    <div style={{ float: "left" }}>
                                        <div
                                            style={{
                                                float: "left",
                                                color: "#4dcaa4",
                                                backgroundColor: "#001c3d",
                                                padding: "1px 7px",
                                                borderRadius: "5px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {this.props.skillCoins.find((it) => it.type == "Promoted Action Usage").coins}
                                        </div>
                                        <div
                                            style={{
                                                paddingTop: "5px",
                                                paddingLeft: "5px",
                                                float: "left",
                                                fontSize: "14px",
                                            }}
                                        >
                                            <ContentTranslator page="SfiaPlan" name="SfiaPlanActionPromotedUsageCoins" contentText="Skillcoins for Promoted Action Usage" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        <br />
                        <br />
                        {!managedPeople && <StepProfile stepData={this.state.stepData} step1Clicked={this.step1Clicked} step2Clicked={this.step2Clicked} step3Clicked={this.step3Clicked}></StepProfile>}
                        <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                        {this.renderSendActionPlanUpdatedNotificationConfirmation()}
                        {(!managedPeople || (managedPeople && isAdmin) || (managedPeople && this.state.listRelationshipType.some((item) => item.toLocaleLowerCase().trim() === managedPeople.relationshipType?.toLocaleLowerCase().trim()))) && (
                            <Todo
                                loadingAction={this.state.actionLoading}
                                actions={this.state.actions}
                                optionsActionTypes={this.state.optionsActionTypes}
                                optionsActionSkills={this.state.optionsActionSkills}
                                handleActionPlanUpdated={this.handleActionPlanUpdated}
                                handleActionPlanDeleted={this.handleActionPlanDeleted}
                                handleActionPlanHidden={this.handleActionPlanHidden}
                                generateAlert={this.generateAlert}
                                jobLorProfiles={jobLorProfiles}
                                jobSkillProfiles={jobSkillProfiles}
                                loadingAdd={this.state.loadingAdd}
                                loadingProfile={this.state.loadingProfile}
                                selectedJob={this.state.selectedJob}
                                categories={this.state.categories}
                                lors={this.state.lors}
                                lorProfiles={this.state.lorProfiles}
                                skillProfiles={this.state.skillProfiles}
                                actionPlanUpdated={this.state.actionPlanUpdated}
                                handleSendUpdatedActionPlanNotification={this.handleSendUpdatedActionPlanNotification}
                                showHiddenSkills={this.state.showHiddenSkills}
                                handleProposeActionModeStarted={this.handleProposeActionModeStarted}
                            ></Todo>
                        )}
                        <ManageActionPlan
                            stepActionClicked={this.state.stepActionClicked}
                            initialLoading={this.state.loading}
                            optionsProfile={this.state.optionsProfile}
                            optionsJob={this.state.optionsJob}
                            selectedOptionProfile={this.state.selectedprofileType ? this.state.selectedprofileType : "Self Assessed"}
                            selectedJob={this.state.selectedJob}
                            currentJob={this.state.currentJob}
                            futureJob={this.state.futureJob}
                            responsibilityData={this.state.userResponsibilityData}
                            lors={this.state.lors}
                            categories={this.state.categories}
                            extraFrameworkCapabilities={this.state.extraFrameworkCapabilities}
                            lorProfiles={this.state.lorProfiles}
                            skillProfiles={this.state.skillProfiles}
                            actions={this.state.actions}
                            jobLorProfiles={jobLorProfiles}
                            jobSkillProfiles={jobSkillProfiles}
                            optionsActionTypes={this.state.optionsActionTypes}
                            optionsActionSkills={this.state.optionsActionSkills}
                            handleChangeProfileType={this.handleChangeProfileType}
                            handleChangeJob={this.handleChangeJob}
                            handleActionPlanAdded={this.handleActionPlanAdded}
                            providers={this.state.providers}
                            loadingAction={this.state.actionLoading}
                            loadingAdd={this.state.loadingAdd}
                            loadingProfile={this.state.loadingProfile}
                            actionPlanUpdated={this.state.actionPlanUpdated}
                            handleChageShowHiddenSkills={this.handleChageShowHiddenSkills}
                            proposeActionStarted={this.state.proposeActionStarted}
                        />
                    </React.Fragment>
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.job.jobMatchesLoading,
        error: state.job.error,
        jobMatches: state.job.jobMatches,
        actionPlanloading: state.actionPlan.loading,
        actionPlanerror: state.actionPlan.error,
        actionPlanUpdating: state.actionPlan.actionPlanUpdating,
        actionPlanUpdatingError: state.actionPlan.actionPlanUpdatingError,
        actions: state.actionPlan.actions,
        actionTypes: state.actionPlan.actionTypes,
        actionSkills: state.actionPlan.actionSkills,

        jobLoading: state.job.loading,
        jobLorProfiles: state.job.jobLorProfiles,
        jobSkillProfiles: state.job.jobSkillProfiles,

        loadingProfile: state.skillProfile.loading,
        errorProfile: state.skillProfile.error,
        skillProfiles: state.skillProfile.skillProfiles,

        profileTypes: state.skillProfile.profileTypes,

        loadingLorProfile: state.skillProfile.lorLoading,
        errorLorProfile: state.skillProfile.lorError,
        lorProfiles: state.skillProfile.lorProfiles,

        categories: state.category.categories,
        extraFrameworkCapabilities: state.category.extraFrameworkCapabilities,
        categoryLoading: state.category.loading,
        categoryError: state.category.error,

        selectedProviders: state.company.selectedProviders,
        companyLoading: state.category.loading,
        companyError: state.category.error,

        lorLoading: state.lor.loading,
        lorError: state.lor.error,
        lors: state.lor.lors,

        personLoading: state.person.loading,
        personManagedPeople: state.person.managedPeople,
        managedPeople: state.auth.managedPeople,
        authMode: state.auth.authMode,
        skillCoins: state.person.skillCoins,

        language: state.language.language,
        contentTranslations: state.content.contentTranslations,
        contentLoading: state.content.loading,
        contentError: state.content.error,
        currAssessment: state.config.currAssessment,
        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSkillProfile: bindActionCreators(skillProfileActions, dispatch),
        onAuth: bindActionCreators(authActions, dispatch),
        onJob: bindActionCreators(jobActions, dispatch),
        onActionPlan: bindActionCreators(actionPlanActions, dispatch),
        onCategory: bindActionCreators(categoryActions, dispatch),
        onCompany: bindActionCreators(companyActions, dispatch),
        onLor: bindActionCreators(lorActions, dispatch),
        onPerson: bindActionCreators(personActions, dispatch),
        onContent: bindActionCreators(contentActions, dispatch),
        onConfig: bindActionCreators(configActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionPlan);
